import _ from "lodash";

import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import { combineReducers } from "redux";
import apiUrl, { customerApiUrl } from "../../api-url";
import { alfBy } from "../../components/selectors";
import moment from "moment";

// Exported URLs
export const currentPositionCodesUrl = (solutionId) => {
  return apiUrl(
    `/entity-search/solution/${solutionId}/list?currentPositionCodes=1`,
  );
};

export const productTypesUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?productType=1`);
};

export const lastMilestoneUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?lastMilestone=1`);
};

export const soldToUrl = (solutionId) => {
  return apiUrl(
    `/entity-search/solution/${solutionId}/list?ref:soldToDealer=1`,
  );
};

export const finCodeUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?ref:FinCode=1`);
};

export const endUserFinCodeUrl = (solutionId) => {
  return apiUrl(
    `/entity-search/solution/${solutionId}/list?ref:EndUserFinCode=1`,
  );
};

export const dealerRegionUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?dealerRegion=1`);
};

export const dealerZoneUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?dealerZone=1`);
};

export const dealerDistrictUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?dealerDistrict=1`);
};

export const originCountryUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?originCountry=1`);
};

export const destinationCountryUrl = (solutionId) => {
  return apiUrl(
    `/entity-search/solution/${solutionId}/list?destinationCountry=1`,
  );
};

export const partnersUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?partner=1`);
};

export const exteriorColorUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/exteriorColor`,
  );
};

export const interiorColorUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/interiorColor`,
  );
};

export const doorsUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/doorType`,
  );
};

export const powertrainUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/powerTrain`,
  );
};

export const drivetrainUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/driveTrain`,
  );
};

export const bodyStyleUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/bodyStyle`,
  );
};

export const fuelTypeUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/fuelType`,
  );
};

export const yearUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/year`,
  );
};

export const makeUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/make`,
  );
};

export const modelUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/model`,
  );
};

export const trimUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/trim`,
  );
};

export const originUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/origin`,
  );
};

export const destinationUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/destination`,
  );
};

export const carrierUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/carrier`,
  );
};

export const productTypeUrl = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/productType`,
  );
};

export const lastMilestoneUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/lastMilestone`,
  );
};

export const soldToUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/soldTo`,
  );
};

export const finCodeUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/finCode`,
  );
};

export const endUserFinCodeUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/endUserFinCode`,
  );
};

export const partnerUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/partner`,
  );
};

export const shipmentStatusUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/shipmentStatus`,
  );
};

export const itssIdUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/itssId`,
  );
};

export const spotBuyAuthUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/spotBuyAuth`,
  );
};

export const originCountryUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/originCountry`,
  );
};

export const destinationCountryUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/destinationCountry`,
  );
};

export const dealerRegionUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/dealerRegion`,
  );
};
export const dealerZoneUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/dealerZone`,
  );
};
export const dealerDistrictUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/dealerDistrict`,
  );
};

export const currentLocationUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/currentLocation`,
  );
};

export const routeIdUrlOpenSearch = (solutionId) => {
  return customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/routeId`,
  );
};

const STORE_MOUNT_POINT = "fvDomainData";
const finVehicleDomainDuck = buildFetchDuck("finVehicleDomainDuck");

const finVehicleProductTypeDuck = buildFetchDuck("finVehicleProductTypeDuck");
const finVehicleLocationDuck = buildFetchDuck("finVehicleLocationDuck");
const finVehicleOrderTypeDuck = buildFetchDuck("finVehicleOrderTypeDuck");
const finVehicleOrderNumberDuck = buildFetchDuck("finVehicleOrderNumberDuck");
const finVehicleCategoriesDuck = buildFetchDuck("finVehicleCategoriesDuck");
const finVehicleShipmentExceptionsDuck = buildFetchDuck(
  "finVehicleShipmentExceptionsDuck",
);
const finVehicleCarrierDuck = buildFetchDuck("finVehicleCarrierDuck");
const finVehicleCarrierOpenSearchDuck = buildFetchDuck(
  "finVehicleCarrierOpenSearchDuck",
);
const finVehicleItssIdOpenSearchDuckFilter = buildFetchDuck(
  "finVehicleItssIdOpenSearchDuckFilter",
);
const finVehicleSpotBuyOpenSearchDuck = buildFetchDuck(
  "finVehicleSpotBuyOpenSearchDuck",
);
const finVehicleShipmentStatusOpenSearchDuck = buildFetchDuck(
  "finVehicleShipmentStatusOpenSearchDuck",
);
const finVehicleRouteIdOpenSearchDuckFilter = buildFetchDuck(
  "finVehicleRouteIdOpenSearchDuckFilter",
);
const finVehicleOrderTypeOpenSearchDuckFilter = buildFetchDuck(
  "finVehicleOrderTypeOpenSearchDuckFilter",
);
const finVehicleExceptionTypeDuck = buildFetchDuck(
  "finVehicleExceptionTypeDuck",
);
const finVehiclePositionTypesDuck = buildFetchDuck(
  "finVehiclePositionTypesDuck",
);
const finVehicleRouteIdDuck = buildFetchDuck("finVehicleRouteIdDuck");

const finVehicleVinNumberOpenSearchDuck = buildFetchDuck(
  "finVehicleVinNumberOpenSearchDuck",
);
const finVehicleShipementIdOpenSearchDuck = buildFetchDuck(
  "finVehicleShipementIdOpenSearchDuck",
);
const finVehicleOrderNumberOpenSearchDuck = buildFetchDuck(
  "finVehicleOrderNumberOpenSearchDuck",
);
const finVehicleProductTypeOpenSearchDuck = buildFetchDuck(
  "finVehicleProductTypeOpenSearchDuck",
);
const finVehicleLocationOpenSearchDuck = buildFetchDuck(
  "finVehicleLocationOpenSearchDuck",
);
const finVehicleLastMilestoneOpenSearchDuck = buildFetchDuck(
  "finVehicleLastMilestoneOpenSearchDuck",
);
const finVehicleItssIdOpenSearchDuck = buildFetchDuck(
  "finVehicleItssIdOpenSearchDuck",
);
const finVehicleRouteIdOpenSearchDuck = buildFetchDuck(
  "finVehicleRouteIdOpenSearchDuck",
);

export const SHIPMENT_STATUS_FILTER_OPTIONS = [
  {
    value: "in_transit",
    label: "Active",
  },
  {
    value: "arrived",
    label: "Arrived",
  },
  {
    value: "canceled",
    label: "Canceled",
  },
  {
    value: "asset_pending",
    label: "Pending: Asset ID",
  },
  {
    value: "released",
    label: "Released",
  },
  {
    value: "available_for_unload",
    label: "Available For Unload",
  },
];

export function fetchDomainData(solutionId, isOpenSearchEnabled = false) {
  const fieldsToGetFromEndpoint = [
    // H1-1549: for some reason they use the parameter here as lifecycleState
    // but return this as lifeCycleState, with a capital c, so, this is the only
    // place where whe have this written in this way
    "lifecycleState",
  ];
  const queryString = fieldsToGetFromEndpoint
    .map((item) => `${item}=1`)
    .join("&");
  const url = apiUrl(
    `/entity-search/solution/${solutionId}/list?${queryString}`,
  );

  // Category Search Typeahead
  const urlVinNumberOpenSearch = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/vinNumber`,
  );
  const urlShipmentIdOpenSearch = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/shipmentId`,
  );
  const urlOrderNumberOpenSearch = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/orderNumber`,
  );
  const urlProductTypeOpenSearch = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/productType`,
  );
  const urlLocationOpenSearch = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/location`,
  );
  const urlLastMilestoneOpenSearch = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/lastMilestone`,
  );
  const urlItssIdOpenSearch = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/itssId`,
  );
  const urlRouteIdOpenSearch = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/routeId`,
  );

  const urlProductType = apiUrl(
    `/entity-search/solution/${solutionId}/list?productType=1`,
  );
  const urlLocation = apiUrl(
    `/entity-search/solution/${solutionId}/list?location=1`,
  );
  const urlOrderType = apiUrl(
    `/entity-search/solution/${solutionId}/list?ref:OrderType=1`,
  );
  const urlOrderNumber = apiUrl(
    `/entity-search/solution/${solutionId}/list?ref:OrderNumber=1`,
  );
  const urlCategories = apiUrl(
    `/entity-search/solution/${solutionId}/list?categories=1`,
  );
  const urlShipmentExceptions = apiUrl(
    `/entity-search/solution/${solutionId}/list?shipmentExceptions=1`,
  );
  const urlCarrier = apiUrl(
    `/entity-search/solution/${solutionId}/list?carrier=1`,
  );
  const urlCarrierOS = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/carrier`,
  );
  const urlItssIdOS = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/itssId`,
  );
  const urlSpotBuyOS = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/spotBuyAuth`,
  );
  const urlShipmentStatusOS = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/shipmentStatus`,
  );
  const urlRouteIdOS = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/routeId`,
  );
  const urlOrderTypeOS = customerApiUrl(
    `/finished-product/finished-product/${solutionId}/typeahead/orderType`,
  );
  const urlExceptionType = apiUrl(
    `/entity-search/solution/${solutionId}/list?exceptionType=1`,
  );
  const urlPositionTypes = apiUrl(
    `/entity-search/solution/${solutionId}/list?currentPositionTypes=1`,
  );
  const urlRouteId = apiUrl(
    `/entity-search/solution/${solutionId}/list?routeNumber=1`,
  );

  const axiosConfiguration = {
    method: "POST",
    url,
    data: {},
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json",
    },
  };

  const transformData = (key) => (data) => {
    return {
      [key]: data.data.map((item) => item.value),
      meta: {},
    };
  };

  const vinNumberOpenSearchTransformData = transformData("vinNumber");
  const shipmentIdOpenSearchTransformData = transformData("shipmentId");
  const orderNumberOpenSearchTransformData = transformData("orderNumber");
  const productTypesOpenSearchTransformData = transformData("productTypes");
  const locationOpenSearchTransformData = transformData("location");
  const lastMilestoneOpenSearchTransformData = transformData("lastMilestone");
  const itssIdOpenSearchTransformData = transformData("itssId");
  const routeIdOpenSearchTransformData = transformData("routeId");

  return (dispatch) => {
    dispatch(finVehicleDomainDuck.fetch(url));
    dispatch(finVehiclePositionTypesDuck.fetch(urlPositionTypes));
    dispatch(finVehicleExceptionTypeDuck.fetch(urlExceptionType));

    if (!isOpenSearchEnabled) {
      dispatch(finVehicleProductTypeDuck.fetch(urlProductType));
      dispatch(finVehicleLocationDuck.fetch(urlLocation));
      dispatch(finVehicleOrderTypeDuck.fetch(urlOrderType));
      dispatch(finVehicleOrderNumberDuck.fetch(urlOrderNumber));
      dispatch(finVehicleCategoriesDuck.fetch(urlCategories));
      dispatch(finVehicleShipmentExceptionsDuck.fetch(urlShipmentExceptions));
      dispatch(finVehicleCarrierDuck.fetch(urlCarrier));
      dispatch(finVehicleRouteIdDuck.fetch(urlRouteId));
    } else {
      dispatch(
        finVehicleCarrierOpenSearchDuck.fetch(urlCarrierOS, axiosConfiguration),
      );
      dispatch(
        finVehicleItssIdOpenSearchDuckFilter.fetch(
          urlItssIdOS,
          axiosConfiguration,
        ),
      );
      dispatch(
        finVehicleSpotBuyOpenSearchDuck.fetch(urlSpotBuyOS, axiosConfiguration),
      );
      dispatch(
        finVehicleShipmentStatusOpenSearchDuck.fetch(
          urlShipmentStatusOS,
          axiosConfiguration,
        ),
      );
      dispatch(
        finVehicleRouteIdOpenSearchDuckFilter.fetch(
          urlRouteIdOS,
          axiosConfiguration,
        ),
      );
      dispatch(
        finVehicleOrderTypeOpenSearchDuckFilter.fetch(
          urlOrderTypeOS,
          axiosConfiguration,
        ),
      );

      dispatch(
        finVehicleVinNumberOpenSearchDuck.fetch(
          urlVinNumberOpenSearch,
          axiosConfiguration,
          vinNumberOpenSearchTransformData,
        ),
      );
      dispatch(
        finVehicleShipementIdOpenSearchDuck.fetch(
          urlShipmentIdOpenSearch,
          axiosConfiguration,
          shipmentIdOpenSearchTransformData,
        ),
      );
      dispatch(
        finVehicleOrderNumberOpenSearchDuck.fetch(
          urlOrderNumberOpenSearch,
          axiosConfiguration,
          orderNumberOpenSearchTransformData,
        ),
      );
      dispatch(
        finVehicleProductTypeOpenSearchDuck.fetch(
          urlProductTypeOpenSearch,
          axiosConfiguration,
          productTypesOpenSearchTransformData,
        ),
      );
      dispatch(
        finVehicleLocationOpenSearchDuck.fetch(
          urlLocationOpenSearch,
          axiosConfiguration,
          locationOpenSearchTransformData,
        ),
      );
      dispatch(
        finVehicleLastMilestoneOpenSearchDuck.fetch(
          urlLastMilestoneOpenSearch,
          axiosConfiguration,
          lastMilestoneOpenSearchTransformData,
        ),
      );
      dispatch(
        finVehicleItssIdOpenSearchDuck.fetch(
          urlItssIdOpenSearch,
          axiosConfiguration,
          itssIdOpenSearchTransformData,
        ),
      );
      dispatch(
        finVehicleRouteIdOpenSearchDuck.fetch(
          urlRouteIdOpenSearch,
          axiosConfiguration,
          routeIdOpenSearchTransformData,
        ),
      );
    }
  };
}

/**
 * Given a property name, this function knows how to get its related data from
 * the fv domain mount point in the store.
 *
 * @param string property: property you want to get from the state.
 * @param string sort: key you want to sort on the return.
 * @param boolean sort: if there is no key, a true value will mean: sort it for
 * me.
 *
 * It avoids code duplication to create selectors based on the same data
 * source.
 */
const createSelectorFromProperty = (property, parent, sort = false) => {
  return (state) => {
    let dataFromState;
    if (property) {
      dataFromState = state[STORE_MOUNT_POINT][parent]?.data[property];
    } else {
      dataFromState = state[STORE_MOUNT_POINT][parent];
    }

    if (dataFromState) {
      if (sort) {
        // Sorting
        if (_.isString(sort)) {
          return _.sortBy(dataFromState, sort);
        }

        // Check if the data is prone to sort. In this case, the only possible
        // type which comes from the backend and is not sortable is object
        if (_.isObject(dataFromState[0])) {
          throw Error(
            `Property ${property} contains objects that can not be sorted`,
          );
        }

        return dataFromState.sort();
      }
      return dataFromState;
    }
    return [];
  };
};
const getLocations = createSelectorFromProperty("locations", "locations");

const getExceptionTypes = createSelectorFromProperty(
  "exceptionTypes",
  "exceptionTypes",
);
const selectExceptionTypesAlphabetically = alfBy(getExceptionTypes, "name");

// H2-366: Remove VINs from typeahead
const getVINs = (state) => [];

const getShipments = (state) => [];

const getProductTypes = createSelectorFromProperty(
  "productTypes",
  "productTypes",
);

const getVinNumberOpenSearch = createSelectorFromProperty(
  "vinNumber",
  "vinNumberOpenSearch",
);

const getShipmentIdOpenSearch = createSelectorFromProperty(
  "shipmentId",
  "shipmentIdOpenSearch",
);

const getOrderNumberOpenSearch = createSelectorFromProperty(
  "orderNumber",
  "orderNumberOpenSearch",
);

const getProductTypesOpenSearch = createSelectorFromProperty(
  "productTypes",
  "productTypesOpenSearch",
);

const getLocationOpenSearch = createSelectorFromProperty(
  "location",
  "locationOpenSearch",
);

const getLastMilestoneOpenSearch = createSelectorFromProperty(
  "lastMilestone",
  "lastMilestoneOpenSearch",
);

const getItssIdOpenSearch = createSelectorFromProperty(
  "itssId",
  "itssIdOpenSearch",
);

const getRouteIdOpenSearch = createSelectorFromProperty(
  "routeId",
  "routeIdOpenSearch",
);

const getLifeCycleStates = createSelectorFromProperty(
  "lifecycleState",
  "domainData",
);

const getCarriers = createSelectorFromProperty(
  "carriers",
  "carriers",
  "carrier_name",
);

const getCarriersOS = createSelectorFromProperty(
  "data",
  "carriersOS",
  "carriersOS",
);

const getItssIdOS = createSelectorFromProperty("data", "itssIdOS", "itssIdOS");

const getSpotBuyAuthOS = createSelectorFromProperty(
  "data",
  "spotbuyOS",
  "spotbuyOS",
);

const getShipmentStatusOS = createSelectorFromProperty(
  "data",
  "shipmentStatusOS",
  "shipmentStatusOS",
);

const getRouteIdOS = createSelectorFromProperty(
  "data",
  "routeIdOS",
  "routeIdOS",
);
const getOrderTypeOS = createSelectorFromProperty(
  "data",
  "orderTypeOS",
  "orderTypeOS",
);

const getOrderTypes = createSelectorFromProperty(
  "ref:OrderType",
  "orderType",
  true,
);
const getOrderNumbers = createSelectorFromProperty(
  "ref:OrderNumber",
  "orderNumber",
  true,
);

const getCategories = createSelectorFromProperty("categories", "categories");

const getItssIds = (state) =>
  getCategories(state)?.filter((category) => category.groupType === "ITSS");

const getSpotBuyAuthCodes = (state) =>
  getCategories(state)?.filter((category) => category.groupType === "SB");

const getShipmentExeptions = createSelectorFromProperty(
  "shipmentExceptions",
  "shipmentExceptions",
);

const getCurrentPositionTypes = createSelectorFromProperty(
  "currentPositionTypes",
  "currentPositionTypes",
);

const getRouteIds = createSelectorFromProperty("routeNumber", "routeIds");

const getDomainDataLoading = (state) =>
  state[STORE_MOUNT_POINT].domainData.isLoading || false;

const finVehicleDomainData = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getLocations,
    getExceptionTypes,
    selectExceptionTypesAlphabetically,
    getProductTypes,
    getVinNumberOpenSearch,
    getShipmentIdOpenSearch,
    getOrderNumberOpenSearch,
    getProductTypesOpenSearch,
    getLocationOpenSearch,
    getLastMilestoneOpenSearch,
    getItssIdOpenSearch,
    getRouteIdOpenSearch,
    getShipmentExeptions,
    getItssIds,
    getSpotBuyAuthCodes,
    getLifeCycleStates,
    getVINs,
    getShipments,
    getCarriers,
    getCarriersOS,
    getItssIdOS,
    getSpotBuyAuthOS,
    getShipmentStatusOS,
    getRouteIdOS,
    getOrderTypeOS,
    getOrderTypes,
    getOrderNumbers,
    getDomainDataLoading,
    getCurrentPositionTypes,
    getRouteIds,
  },
  reducer: combineReducers({
    domainData: finVehicleDomainDuck.reducer,
    productTypes: finVehicleProductTypeDuck.reducer,
    locations: finVehicleLocationDuck.reducer,
    orderType: finVehicleOrderTypeDuck.reducer,
    orderNumber: finVehicleOrderNumberDuck.reducer,
    categories: finVehicleCategoriesDuck.reducer,
    shipmentExceptions: finVehicleShipmentExceptionsDuck.reducer,
    carriers: finVehicleCarrierDuck.reducer,
    carriersOS: finVehicleCarrierOpenSearchDuck.reducer,
    itssIdOS: finVehicleItssIdOpenSearchDuckFilter.reducer,
    spotbuyOS: finVehicleSpotBuyOpenSearchDuck.reducer,
    shipmentStatusOS: finVehicleShipmentStatusOpenSearchDuck.reducer,
    routeIdOS: finVehicleRouteIdOpenSearchDuckFilter.reducer,
    orderTypeOS: finVehicleOrderTypeOpenSearchDuckFilter.reducer,
    exceptionTypes: finVehicleExceptionTypeDuck.reducer,
    currentPositionTypes: finVehiclePositionTypesDuck.reducer,
    routeIds: finVehicleRouteIdDuck.reducer,
    vinNumberOpenSearch: finVehicleVinNumberOpenSearchDuck.reducer,
    shipmentIdOpenSearch: finVehicleShipementIdOpenSearchDuck.reducer,
    orderNumberOpenSearch: finVehicleOrderNumberOpenSearchDuck.reducer,
    productTypesOpenSearch: finVehicleProductTypeOpenSearchDuck.reducer,
    locationOpenSearch: finVehicleLocationOpenSearchDuck.reducer,
    lastMilestoneOpenSearch: finVehicleLastMilestoneOpenSearchDuck.reducer,
    itssIdOpenSearch: finVehicleItssIdOpenSearchDuck.reducer,
    routeIdOpenSearch: finVehicleRouteIdOpenSearchDuck.reducer,
  }),
};

export default finVehicleDomainData;
