/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { ShowMoreList } from "components/molecules/ShowMoreList.molecule";

type Parts = {
  externalId?: string;
  partNumber?: string;
  name: string;
};

type PartsCellInfo = {
  value: {
    partList: Parts[];
    trackingNumber: string;
  };
};

export const PartsCell = (props: PartsCellInfo) => {
  const { t } = useTranslation("partview-dashboard");

  const { partList, trackingNumber } = props.value;

  const partDetails = partList.map(
    (parts) =>
      `${parts.externalId ? parts.externalId : parts.partNumber} (${
        parts.name
      })`,
  );

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {!_.isEmpty(partDetails) ? (
        <ShowMoreList
          title={t("partview-dashboard:Parts in Package [[[trackingNumber]]]", {
            trackingNumber,
          })}
          list={partDetails}
          visibleItemCount={3}
        />
      ) : null}
    </div>
  );
};
