import moment from "moment";
import "moment-timezone";
import momentDurationSetup from "moment-duration-format";
import { BrowserStorage } from "./browser-storage.utils";

momentDurationSetup(moment);

// DEV-767 Add user timezone to time display
const zone = moment.tz.guess();

export const DATE_FORMAT = {
  DAY_MONTH_YEAR: "DD/MM/YYYY",
  MONTH_DAY_YEAR: "MM/DD/YYYY", //default date format
  YEAR_MONTH_DAY: "YYYY/MM/DD",
};

export const TIME_FORMAT = {
  TWENTY_FOUR_HOUR: "HH:mm", //default time format
  TWELVE_HOUR_AM_PM: "hh:mm A",
  TWENTY_FOUR_HOUR_SECONDS: "HH:mm:ss",
  TWELVE_HOUR_SECONDS_AM_PM: "hh:mm:ss A",
};

export const DATE_FORMAT_NAMES = {
  DAY_MONTH_YEAR: "DAY_MONTH_YEAR",
  MONTH_DAY_YEAR: "MONTH_DAY_YEAR",
  YEAR_MONTH_DAY: "YEAR_MONTH_DAY",
};

export const TIME_FORMAT_NAMES = {
  TWENTY_FOUR_HOUR: "TWENTY_FOUR_HOUR",
  TWELVE_HOUR_AM_PM: "TWELVE_HOUR_AM_PM",
  TWENTY_FOUR_HOUR_SECONDS: "TWENTY_FOUR_HOUR_SECONDS",
  TWELVE_HOUR_SECONDS_AM_PM: "TWELVE_HOUR_SECONDS_AM_PM",
};

export const getDateFormatFromUserPreferences = () => {
  return BrowserStorage.dateFormat ?? DATE_FORMAT.MONTH_DAY_YEAR;
};

export const setDateFormat = (date_format) => {
  BrowserStorage.dateFormat = date_format;
};

export const getTimeFormatFromUserPreferences = () => {
  return BrowserStorage.timeFormat ?? TIME_FORMAT.TWENTY_FOUR_HOUR;
};

export const setTimeFormat = (format) => {
  BrowserStorage.timeFormat = format;
};

export const parseDateTime = (
  dateTimeInUtc,
  localize = false,
  timezonePreference,
) => {
  // Passing undefined to moment.utc defaults to the current time.
  // Check if we have a value and if not, return invalid.
  if (!dateTimeInUtc) {
    return { isValid: false };
  }

  let dateTime = moment.utc(dateTimeInUtc);

  // After parsing the datetime, check if it is valid.
  const isValid = dateTime.isValid();
  if (!isValid) {
    return { isValid };
  }

  if (localize) {
    dateTime = dateTime.local();
  }

  let timezone = dateTime;
  if (localize && !timezonePreference) {
    timezone = moment().tz(zone);
  }

  if (timezonePreference) {
    dateTime = moment.tz(dateTime, timezonePreference);
    timezone = moment().tz(timezonePreference);
  }

  const dateFormat = getDateFormatFromUserPreferences();
  const timeFormat = getTimeFormatFromUserPreferences();

  return {
    date: dateTime.format(dateFormat),
    time: dateTime.format(timeFormat),
    timezone: timezone.format("z"),
    a11yTs: dateTime.format(moment.DATETIME_LOCAL_MS),
    isValid,
  };
};

export function localizedTimeFormatter(utc) {
  const timeFormat = getTimeFormatFromUserPreferences();
  const locTime = moment.utc(utc).local();
  const userTz = moment().tz(zone);

  return `${locTime.format(timeFormat)} ${userTz.format("z")}`;
}

export function localizedTimeFormatterWithSeconds(utc) {
  let timeFormat = TIME_FORMAT.TWENTY_FOUR_HOUR_SECONDS;
  const locTime = moment.utc(utc).local();
  const userTz = moment().tz(zone);
  if (BrowserStorage.timeFormat === TIME_FORMAT.TWELVE_HOUR_AM_PM) {
    timeFormat = TIME_FORMAT.TWELVE_HOUR_SECONDS_AM_PM;
  }

  return `${locTime.format(timeFormat)} ${userTz.format("z")}`;
}

export function timeFormatterNonUtc(time, timezone) {
  const timeFormat = getTimeFormatFromUserPreferences();
  const timeObj = moment.tz(time, timezone);
  return `${timeObj.format(timeFormat)} ${timeObj.format("z")}`;
}

export function localizedDateFormatter(utc) {
  const locTime = moment.utc(utc).local();
  const dateFormat = getDateFormatFromUserPreferences(); //l

  return locTime.format(dateFormat);
}

export function dateFormatter(utc) {
  const utcDate = moment.utc(utc);
  const dateFormat = getDateFormatFromUserPreferences(); //l

  return utcDate.format(dateFormat);
}

export function dateFormatterNonUtc(date, timezone) {
  const dateFormat = getDateFormatFromUserPreferences();
  const dateObj = moment(date).tz(timezone);

  return dateObj.format(dateFormat);
}

export function getNowInUtc(format) {
  let utcTime = moment().utc();
  return `${utcTime.format(format)}`;
}

export function stringToMoment(str) {
  return str ? moment(str) : null;
}

export function utcStringToMoment(str) {
  return str ? moment.utc(str) : null;
}

export const getRelativeDuration = (ts) => {
  const tsZ = ts.slice(-1) === "Z" ? ts : ts + " Z";
  const now = moment();
  const tsMoment = moment(tsZ);
  return moment.duration(tsMoment.diff(now));
};

export const humanizeTimeString = (ts, suffix) => {
  if (!ts) {
    return "";
  }

  // Add a Z to the end of the string, so moment will parse
  // it into the proper time zone
  const duration = getRelativeDuration(ts);

  return duration.locale(getLocalTimezone().locale()).humanize(suffix);
};

/**
 * Special date formatter when showing rail date time.
 *
 * NOTE: Customers deserve a format totally different from the other ones used
 * in the system.
 */
export function localizedRailDateTimeFormatter(ts) {
  if (!ts) {
    return "";
  }

  const tsZ = ts.slice(-1) === "Z" ? ts : ts + " Z";
  const locTime = moment(tsZ).local();
  const userTz = moment().tz(zone);
  const dateFormat = getDateFormatFromUserPreferences();
  const timeFormat = getTimeFormatFromUserPreferences();
  const dateTimeFormat = dateFormat + " " + timeFormat;

  return `${locTime.format(dateTimeFormat)} ${userTz.format("z")}`;
}

/**
 * Formats seconds as duration string.
 * Labels are single chars to avoid needing localization.
 *
 * Years and monthes are converted to days.
 *      (If you need years/months displayed, this will need
 *       to be labels will need to be rethought, then we can
 *       add in some optional params)
 *
 * Zero values will be empty and have no label.
 * If all values are empty, it will return "0m"
 */
export function formatDuration(seconds) {
  return moment.duration(seconds, "seconds").format("d[d] h[h] m[m]");
}

export function getCurrentDateForInput() {
  return moment.utc().local().format(DATE_FORMAT.YEAR_MONTH_DAY);
}

export function getLocalTimezone() {
  return moment().tz(zone);
}

export function formatTimestamp(timestamp) {
  return {
    date: moment(timestamp).format(DATE_FORMAT.YEAR_MONTH_DAY),
    time: moment(timestamp).format(TIME_FORMAT.TWENTY_FOUR_HOUR),
  };
}

export function tsToDaysHrsMins(timestamp) {
  timestamp = moment.utc(timestamp).local();
  const now = moment.utc().local();
  return moment.duration(now.diff(timestamp)).format("d[d] h[h] m[m]");
}

/**
 * @param {string} ts The main timestamp
 * @param {string} tsToCompare The timestamp to compare with `ts`
 * @returns The time difference in DaysHrsMins(Ex: 6d 5h 30m) between two timestamps
 */
export function getDaysHrsMinsBetweenTimestamps(ts, tsToCompare) {
  ts = moment.utc(ts).local();
  tsToCompare = moment.utc(tsToCompare).local();

  if (!ts.isValid() || !tsToCompare.isValid()) {
    return { duration: null, formattedDuration: null };
  }

  // We always want to return a positive result.
  let duration = moment.duration(ts.diff(tsToCompare));
  if (duration.asHours() < 0) {
    // Swap the diff direction if it is negative.
    duration = moment.duration(tsToCompare.diff(ts));
  }

  return {
    // returns positive or negative values
    duration: moment.duration(ts.diff(tsToCompare)),
    // return only positive value
    formattedDuration: duration.format("d[d] h[h] m[m]"),
  };
}

/**
 *
 * @param {string} ts The main timestamp
 * @param {string} tsToCompare The timestamp to compare with `ts`
 * @param {moment.unitOfTime.Diff} unitOfTime The unit of time for the result.
 */
export function getDifferenceBetweenTimestamps(
  ts,
  tsToCompare,
  unitOfTime = "days",
) {
  if (!ts || !tsToCompare) {
    return null;
  }
  return moment(ts).diff(moment(tsToCompare), unitOfTime, true);
}

export const convertToMMDDYYYY = (yyyymmdd, delimiter = "/") => {
  if (typeof yyyymmdd !== "string") {
    return "invalid date";
  }

  const parts = yyyymmdd.split(delimiter);
  if (parts.length !== 3) {
    return "invalid date";
  }

  const [year, month, day] = parts;
  const isValidFormat =
    /^\d{4}$/.test(year) && /^\d{2}$/.test(month) && /^\d{2}$/.test(day);

  return isValidFormat
    ? `${month}${delimiter}${day}${delimiter}${year}`
    : "invalid date";
};
