import moment from "moment-timezone";
import {
  RequiredParameterError,
  InvalidValueError,
} from "utils/function.utils";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";
import AuthenticationUtils from "modules/auth/authentication";

// Types
import { ThunkAction } from "redux-thunk";
import { AnyAction, Dispatch, Reducer } from "redux";
import { AxiosRequestConfig } from "axios";

export const MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH = 1000;
const SAVED_SEARCH = "saved_search";

/**
 * Create a redux state for any subscription feature (e.g. Alert Me). This works
 * for any subscription service in our platform. The user subscribing will be
 * called the "subscriber" and the thing being subscribed to is the "subscribee"
 * (e.g. VIN, Shipment, Package, etc).
 *
 * @example
 * const subscriptionDuck = buildSubscriptionState({
 *   systemType: "shipment",
 *   getUrl: (id, state) => {
 *     const solutionId = getSolutionId(state);
 *     return apiUrl(`/url-for-subscriptions-with/${solutionId}/for-the-object/${id}`);
 *   },
 *   getAdditionalUrlParams: () => {
 *     return { extraParam: 1234 };
 *   }
 * });
 *
 * @param {SubscriptionStateBuilderConfig} config The configuration object.
 * @returns
 */
export function buildSubscriptionState({
  topic,
  systemType,
  subscriptionType = "update",
  getSubscribeeId,
  getUrl,
  getAdditionalRequestConfig = () => ({}),
  onChange,
  updateUrl = true,
  fetchSubscriptionOnSuccess = true,
}: SubscriptionStateBuilderConfig) {
  // #region Validate required config parameters

  if (!systemType) {
    throw new RequiredParameterError("systemType");
  }

  if (!getUrl) {
    throw new RequiredParameterError("getUrl");
  }

  if (!ALLOWED_SUBSCRIPTION_TYPES.includes(subscriptionType)) {
    throw new InvalidValueError("subscriptionType", subscriptionType, [
      ...ALLOWED_SUBSCRIPTION_TYPES,
    ]);
  }

  // #endregion

  const STORE_MOUNT_POINT = `subscription/${topic}`;

  const userSubscriptionsDuck = buildFetchDuck(
    STORE_MOUNT_POINT,
    "userSubscriptions",
  );
  const subscriptionsDuck = buildFetchDuck(STORE_MOUNT_POINT, "subscriptions");
  const subscriptionsCountDuck = buildFetchDuck(
    STORE_MOUNT_POINT,
    "subscriptionsCount",
  );
  const refreshSubscriptionDuck = buildFetchDuck(
    STORE_MOUNT_POINT,
    "refreshSubscription",
  );
  const modifySubscriptionDuck = buildFetchDuck(
    STORE_MOUNT_POINT,
    "modifySubscription",
  );

  const buildRequestConfig = (
    base: AxiosRequestConfig,
    extra: ReturnType<typeof getAdditionalRequestConfig>,
  ) => {
    return {
      ...base,
      headers: {
        ...(extra.headers ?? {}),
        ...(base.headers ?? {}),
      },
      params: {
        ...(extra.params ?? {}),
        ...(base.params ?? {}),
      },
      data: {
        ...(extra.data ?? {}),
        ...(base.data ?? {}),
      },
    };
  };

  // #region Action Creators

  const SET_LAST_REQUESTED_ID = `${STORE_MOUNT_POINT}/SET_LAST_REQUESTED_ID`;
  /**
   * Internal use only:
   * Sets the last requested subscribee ID in redux state, used in `fetchSubscription`.
   *
   * @param id The ID of the subcribee.
   * @returns A plain redux action.
   */
  const setLastRequestedId = (id: string | number) => ({
    type: SET_LAST_REQUESTED_ID,
    payload: { id },
  });

  /**
   * Requests the user's active subscription when dispatched.
   *
   * @returns A redux thunk action creator
   */
  const fetchUserSubscriptions = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
  > => {
    return async (dispatch, getState) => {
      const state = getState();
      const url = getUrl("", state) + "subscription";

      const config = {
        params: {
          email: AuthenticationUtils.userEmail,
          sourceService: systemType,
          type: subscriptionType,
        },
      };

      dispatch(userSubscriptionsDuck.fetch(url, config));
    };
  };

  /**
   * Requests the user's active subscription Count and New Counts which needs to be subscribed when dispatched.
   *
   * @param subscribee The thing being subscribed to.
   * @returns A redux thunk action creator
   */
  const fetchUserSubscriptionsCount = (
    subscribee: Subscribee,
  ): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
      const state = getState();
      const id = getSubscribeeId(subscribee);
      const url = getUrl(id, state) + "/count";

      const config = buildRequestConfig(
        {
          method: "post",
          data: {
            type: subscriptionType,
            source_service: systemType,
            email: AuthenticationUtils.userEmail,
            timezone: moment.tz.guess(),
          },
        },
        await getAdditionalRequestConfig(
          "SUBSCRIBE", // Get addtional request config same as subscribe api
          subscribee,
          state,
          dispatch,
        ),
      );

      return dispatch(subscriptionsCountDuck.fetch(url, config));
    };
  };

  /**
   * Requests refreshing a subscription for the user when dispatched.
   *
   * @param subscribee The thing being subscribed to.
   * @param details The susbcription details.
   * @returns A redux thunk action creator
   */
  const refreshSubscription = (
    subscribee: Subscribee,
    details: SubscriptionDetails,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
      const state = getState();
      const id = getSubscribeeId(subscribee);
      const url = getUrl(id, state) + "/refresh";

      const config = buildRequestConfig(
        {
          method: "patch",
          data: {
            type: subscriptionType,
            source_service: systemType,
            email: AuthenticationUtils.userEmail,
            enable_email: details.enable_email,
            recipient_email: details.enable_email
              ? details.recipient_email
              : "",
            enable_sms: details.enable_sms,
            mobile_number: details.enable_sms ? details.mobile_number : "",
            enable_platform: details.enable_platform,
            timezone: moment.tz.guess(),
            context: details.context,
          },
        },
        await getAdditionalRequestConfig(
          "SUBSCRIBE", // Get addtional request config same as subscribe api
          subscribee,
          state,
          dispatch,
        ),
      );

      return dispatch(refreshSubscriptionDuck.fetch(url, config));
    };
  };

  /**
   * Clear the refresh subscription data for the user when dispatched.
   *
   * @returns A redux thunk action creator
   */
  const clearRefreshSubscription = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
  > => {
    return async (dispatch) => {
      dispatch(refreshSubscriptionDuck.clear());
    };
  };

  /**
   * Requests the user's active subscription when dispatched.
   *
   * @param subscribee The thing being subscribed to.
   * @returns A redux thunk action creator
   */
  const fetchSubscription = (
    subscribee: Subscribee,
  ): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
      const state = getState();
      const id = getSubscribeeId(subscribee);
      const url = getUrl(id, state) + "/subscription";

      if (state[STORE_MOUNT_POINT].lastRequestedId !== id) {
        dispatch(modifySubscriptionDuck.clear());
        dispatch(setLastRequestedId(id));
      }

      const config = buildRequestConfig(
        {
          params: {
            email: AuthenticationUtils.userEmail,
            sourceService: systemType,
            type: subscriptionType,
          },
        },
        await getAdditionalRequestConfig(
          "FETCH_SUBSCRIPTION",
          subscribee,
          state,
          dispatch,
        ),
      );

      dispatch(subscriptionsDuck.fetch(url, config));
    };
  };

  /**
   * Requests creating a subscription for the user when dispatched.
   *
   * @param subscribee The thing being subscribed to.
   * @param details The susbcription details from the Alert Me modal.
   * @returns A redux thunk action creator
   */
  const subscribe = (
    subscribee: Subscribee,
    details: SubscriptionDetails,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
      const state = getState();
      const id = getSubscribeeId(subscribee);
      const url = updateUrl
        ? getUrl(id, state) + "/subscribe"
        : getUrl(id, state);

      const config = buildRequestConfig(
        {
          method: "post",
          data: {
            type: subscriptionType,
            source_service: systemType,
            email: AuthenticationUtils.userEmail,
            enable_email: details.enable_email,
            recipient_email: details.enable_email
              ? details.recipient_email
              : "",
            enable_sms: details.enable_sms,
            mobile_number: details.enable_sms ? details.mobile_number : "",
            enable_platform: details.enable_platform,
            timezone: moment.tz.guess(),
            context: details.context,
            requires_approval: details.requires_approval,
          },
        },
        await getAdditionalRequestConfig(
          "SUBSCRIBE",
          subscribee,
          state,
          dispatch,
        ),
      );

      const onSuccess = () => {
        if (fetchSubscriptionOnSuccess) {
          dispatch(fetchSubscription(subscribee));
        }

        if (onChange) {
          dispatch(onChange("SUBSCRIBED", config.data, state));
        }
      };

      return dispatch(
        modifySubscriptionDuck.fetch(url, config, undefined, onSuccess),
      );
    };
  };

  /**
   * Requests updating the user's subscription when dispatched.
   *
   * @param subscribee The thing being subscribed to.
   * @param details The susbcription details from the Alert Me modal.
   * @returns A redux thunk action creator
   */
  const updateSubscription = (
    subscribee: Subscribee,
    details: SubscriptionDetails,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
      const state = getState();
      const id = getSubscribeeId(subscribee);
      let url = getUrl(id, state);
      if (url.includes(SAVED_SEARCH)) {
        // For saved search, the api end point is base URl + /update
        url += "/update";
      } else {
        url += "/subscription/" + details.id;
      }

      const config = buildRequestConfig(
        {
          method: "patch",
          data: {
            enable_email: details.enable_email,
            recipient_email: details.enable_email
              ? details.recipient_email
              : "",
            enable_sms: details.enable_sms,
            mobile_number: details.enable_sms ? details.mobile_number : "",
            enable_platform: details.enable_platform,
            context: details.context,
            requires_approval: details.requires_approval,
          },
        },
        await getAdditionalRequestConfig(
          "UPDATE_SUBSCRIPTION",
          subscribee,
          state,
          dispatch,
        ),
      );

      const onSuccess = () => {
        if (fetchSubscriptionOnSuccess) {
          dispatch(fetchSubscription(subscribee));
        }

        if (onChange) {
          dispatch(onChange("UPDATED_SUBSCRIPTION", config.data, state));
        }
      };

      return dispatch(
        modifySubscriptionDuck.fetch(url, config, undefined, onSuccess),
      );
    };
  };

  /**
   * Requests removing the user's subscription when dispatched.
   *
   * @param subscribee The thing being subscribed to.
   * @returns A redux thunk action creator
   */
  const unsubscribe = (
    subscribee: Subscribee,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
      const state = getState();
      const id = getSubscribeeId(subscribee);
      const url = getUrl(id, state) + "/unsubscribe";

      const config = buildRequestConfig(
        {
          method: "post",
          data: {
            email: AuthenticationUtils.userEmail,
            source_service: systemType,
            type: subscriptionType,
          },
        },
        await getAdditionalRequestConfig(
          "UNSUBSCRIBE",
          subscribee,
          state,
          dispatch,
        ),
      );

      const onSuccess = () => {
        if (fetchSubscriptionOnSuccess) {
          dispatch(fetchSubscription(subscribee));
        }

        if (onChange) {
          dispatch(onChange("UNSUBSCRIBED", config.data, state));
        }
      };

      return dispatch(
        modifySubscriptionDuck.fetch(url, config, undefined, onSuccess),
      );
    };
  };

  /**
   * Requests deleting the user's subscription when dispatched.
   *
   * @param subscribee The thing being subscribed to.
   * @returns A redux thunk action creator
   */
  const deleteSubscription = (
    subscribee: Subscribee,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
      const state = getState();
      const id = getSubscribeeId(subscribee);
      const url = getUrl(id, state) + "/delete";

      const config = buildRequestConfig(
        {
          method: "delete",
          data: {
            email: AuthenticationUtils.userEmail,
            source_service: systemType,
            type: subscriptionType,
          },
        },
        await getAdditionalRequestConfig("DELETE", subscribee, state, dispatch),
      );

      const onSuccess = () => {
        if (fetchSubscriptionOnSuccess) {
          dispatch(fetchSubscription(subscribee));
        }

        if (onChange) {
          dispatch(onChange("DELETED", config.data, state));
        }
      };

      return dispatch(
        modifySubscriptionDuck.fetch(url, config, undefined, onSuccess),
      );
    };
  };

  // #endregion

  // #region Selectors

  /**
   * Get the user's active subscriptions.
   *
   * @param state The entire redux state
   * @returns
   */
  const getUserSubscriptions = (state: RootState): SubscriptionDetails => {
    const { data } = userSubscriptionsDuck.selectors.getData(state);
    return data ?? null;
  };

  /**
   * Get the loading status for the user's active subscriptions.
   *
   * @param state The entire redux state
   * @returns
   */
  const getIsUserSubscriptionsLoading = (state: RootState): boolean => {
    const { isLoading } = userSubscriptionsDuck.selectors.getData(state);
    return isLoading ?? false;
  };

  /**
   * Get the request error status for the user's active subscriptions.
   *
   * @param state The entire redux state
   * @returns
   */
  const getUserSubscriptionsRequestError = (state: RootState): boolean => {
    const { isLoadingError } = userSubscriptionsDuck.selectors.getData(state);
    return isLoadingError ?? false;
  };

  /**
   * Get the user's active subscription Count data
   *
   * @param state The entire redux state
   * @returns
   */
  const getSubscriptionCountData = (state: RootState): SubscriptionDetails => {
    return subscriptionsCountDuck.selectors.getData(state) ?? null;
  };

  /**
   * Get the refresh loading status for the user's subscription.
   *
   * @param state The entire redux state
   * @returns
   */
  const getIsSubscriptionRefreshing = (state: RootState): boolean => {
    const { isLoading } = refreshSubscriptionDuck.selectors.getData(state);
    return isLoading ?? false;
  };

  /**
   * Get the success status of the last refresh request.
   *
   * @param state The entire redux state
   * @returns
   */
  const getSubscriptionRefreshSuccess = (state: RootState): boolean => {
    const requestState = refreshSubscriptionDuck.selectors.getData(state);
    return (
      !requestState.isLoading && [200, 201, 204].includes(requestState.status)
    );
  };

  /**
   * Get the in-progress status of the last refresh request.
   *
   * @param state The entire redux state
   * @returns
   */
  const getSubscriptionRefreshInProgress = (state: RootState): boolean => {
    const requestState = refreshSubscriptionDuck.selectors.getData(state);
    return (
      !requestState.isLoading &&
      [408].includes(requestState.status) &&
      requestState.isLoadingError
    );
  };

  /**
   * Get the error status of the last refresh request.
   *
   * @param state The entire redux state
   * @returns
   */
  const getSubscriptionRefreshError = (state: RootState): boolean => {
    const requestState = refreshSubscriptionDuck.selectors.getData(state);
    return !requestState.isLoading && requestState.isLoadingError;
  };

  /**
   * Get the user's active subscription.
   *
   * @param state The entire redux state
   * @returns
   */
  const getSubscription = (state: RootState): SubscriptionDetails => {
    const { data } = subscriptionsDuck.selectors.getData(state);
    return data?.[0] ?? null;
  };

  /**
   * Get the loading status for the user's active subscription.
   *
   * @param state The entire redux state
   * @returns
   */
  const getIsSubscriptionLoading = (state: RootState): boolean => {
    const { isLoading } = subscriptionsDuck.selectors.getData(state);
    return isLoading ?? false;
  };

  /**
   * Get the request error status for the user's active subscription.
   *
   * @param state The entire redux state
   * @returns
   */
  const getSubscriptionRequestError = (state: RootState): boolean => {
    const { isLoadingError } = subscriptionsDuck.selectors.getData(state);
    return isLoadingError ?? false;
  };

  /**
   * Get the update loading status for the user's subscription.
   *
   * @param state The entire redux state
   * @returns
   */
  const getIsSubscriptionUpdating = (state: RootState): boolean => {
    const { isLoading } = modifySubscriptionDuck.selectors.getData(state);
    return isLoading ?? false;
  };

  /**
   * Get the success status of the last update request.
   *
   * @param state The entire redux state
   * @returns
   */
  const getSubscriptionUpdateSuccess = (state: RootState): boolean => {
    const requestState = modifySubscriptionDuck.selectors.getData(state);
    return (
      !requestState.isLoading &&
      [200, 201, 202, 204].includes(requestState.status)
    );
  };

  /**
   * Get the in-progress status of the last update request.
   *
   * @param state The entire redux state
   * @returns
   */
  const getSubscriptionUpdateInProgress = (state: RootState): boolean => {
    const requestState = modifySubscriptionDuck.selectors.getData(state);
    return (
      !requestState.isLoading &&
      [408].includes(requestState.status) &&
      requestState.isLoadingError
    );
  };

  /**
   * Get the error status of the last update request.
   *
   * @param state The entire redux state
   * @returns
   */
  const getSubscriptionUpdateError = (state: RootState): boolean => {
    const requestState = modifySubscriptionDuck.selectors.getData(state);
    return !requestState.isLoading && requestState.isLoadingError;
  };

  // #endregion

  // Reducer

  const initialState = { lastRequestedId: null };
  const reducer: Reducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_LAST_REQUESTED_ID:
        return { ...state, lastRequestedId: action.payload.id };
      default:
        return state;
    }
  };

  return {
    mountPoint: STORE_MOUNT_POINT,
    actionCreators: {
      fetchUserSubscriptions,
      fetchUserSubscriptionsCount,
      refreshSubscription,
      clearRefreshSubscription,
      fetchSubscription,
      subscribe,
      updateSubscription,
      unsubscribe,
      deleteSubscription,
    },
    selectors: {
      getUserSubscriptions,
      getIsUserSubscriptionsLoading,
      getUserSubscriptionsRequestError,
      getSubscriptionCountData,
      getIsSubscriptionRefreshing,
      getSubscriptionRefreshSuccess,
      getSubscriptionRefreshInProgress,
      getSubscriptionRefreshError,
      getSubscription,
      getIsSubscriptionLoading,
      getSubscriptionRequestError,
      getIsSubscriptionUpdating,
      getSubscriptionUpdateSuccess,
      getSubscriptionUpdateInProgress,
      getSubscriptionUpdateError,
    },
    reducer: chainReducers(
      [
        reducer,
        userSubscriptionsDuck.reducer,
        subscriptionsCountDuck.reducer,
        refreshSubscriptionDuck.reducer,
        subscriptionsDuck.reducer,
        modifySubscriptionDuck.reducer,
      ],
      {
        ...initialState,
        ...userSubscriptionsDuck.initialState,
        ...subscriptionsCountDuck.initialState,
        ...refreshSubscriptionDuck.initialState,
        ...subscriptionsDuck.initialState,
        ...modifySubscriptionDuck.initialState,
      },
    ),
  };
}

/**
 * Type of the entire redux store.
 */
type RootState = {
  [key: string]: any;
};

type Subscribee = {
  [key: string]: any;
};

/**
 * List of allowed subscription types.
 */
const ALLOWED_SUBSCRIPTION_TYPES = ["update"] as const;

/**
 * Config object for `buildSubscriptionState`.
 */
interface SubscriptionStateBuilderConfig {
  /**
   * Uniquely identify this subscription state.
   * This state will be mounted at `subscription/${topic}`.
   */
  topic: string;

  /**
   * The subscription product type (e.g. "partview", "entity", "shipments").
   */
  systemType: string;

  /**
   * The type of subscription to use.
   */
  subscriptionType: (typeof ALLOWED_SUBSCRIPTION_TYPES)[number];

  /**
   * Get the base URL for every subscription request.
   *
   * @param id The subscribee's ID.
   * @param state The entire redux state.
   * @returns The base URL.
   */
  getUrl: (id: string | number, state: object) => string;

  /**
   * Get the ID of the subscribee from its data.
   *
   * @example
   * (shipment) => shipment.creator_shipment_id
   *
   * @param subscribee The thing being subscribed to.
   * @returns
   */
  getSubscribeeId: (subscribee: Subscribee) => string | number;

  /**
   * Add extra headers or params to any request.
   *
   * @param requestType The the request that will be sent.
   * @param id The ID of the subscribee.
   * @param state The entire redux state.
   * @param dispatch The redux dispatch function.
   * @returns
   */
  getAdditionalRequestConfig?: (
    requestType:
      | "FETCH_USER_SUBSCRIPTIONS"
      | "FETCH_SUBSCRIPTION"
      | "SUBSCRIBE"
      | "UPDATE_SUBSCRIPTION"
      | "UNSUBSCRIBE"
      | "DELETE",
    subscribee: Subscribee,
    state: RootState,
    dispatch: Dispatch,
  ) => Pick<AxiosRequestConfig, "headers" | "params" | "data">;

  /**
   * Action to dispatch when a subscrption changes.
   *
   * @param changeType The type of the last request sent.
   * @returns
   */
  onChange?: (
    changeType:
      | "SUBSCRIBED"
      | "UPDATED_SUBSCRIPTION"
      | "UNSUBSCRIBED"
      | "DELETED",
    data: Subscribee,
    state: RootState,
  ) => ThunkAction<void, RootState, unknown, AnyAction>;

  /**
   * Update url with /subscribe or /unsubscribe
   */
  updateUrl?: boolean;

  /**
   * Fetch subscription api after sucess of subscribe / update / unsubscribe / delete api's
   */
  fetchSubscriptionOnSuccess?: boolean;
}

/**
 * The subscription details from the Alert Me modal.
 */
interface SubscriptionDetails {
  /**
   * The subscribee ID.
   */
  id: string | number;

  /**
   * Enable email alerts.
   */
  enable_email: boolean;

  /**
   * The email to receive alerts.
   */
  recipient_email: string;

  /**
   * Enable text message alerts.
   */
  enable_sms: boolean;

  /**
   * The mobile number to receive alerts via SMS.
   */
  mobile_number: string;

  /**
   * Enable platform notifications.
   */
  enable_platform: boolean;

  /**
   * Additional data to send with the subscribe or update subscription requests.
   */
  context?: object;

  /**
   * Is pending subscription
   */
  requires_approval?: boolean;
}
