import {
  getBasicQueryString,
  getDateRangeQueryString,
  getMultiSelectQueryString,
  getLocationQueryString,
  getEmptyQueryString,
} from "components/search-bar/search-filter-query-strings";
import {
  DateRangeFilterButton,
  AsyncSelectFilterButton,
  BatchFilterButton,
} from "components/search-bar/FilterButtons";
import { isDateRangeValueValid } from "components/search-bar/search-filter-validators";
import {
  originStateOptionsState,
  destinationStateOptionsState,
  carrierOptionsState,
  partNumberOptionsState,
  packageStatusOptionsState,
  activeExceptionOptionsState,
  orderPriorityOptionsState,
} from "pages/partview/redux/PartViewSearchFilterLoaders";
import {
  batchCsvPartViewPartNumberExample,
  batchCsvBillofLadingExample,
  batchCsvOrderNumberExample,
  batchCsvPackageNumberExample,
  batchCsvPartViewShipmentIDExample,
  batchCsvEquipmentTrailerNumberExample,
} from "components/search-bar/batch-comment-csv-data";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "partNumber:contains",
    label: (t) => t("partview-search:Part #"),
    placeholder: (t) => t("partview-search:Search Part #"),
    queryBuilder: getBasicQueryString,
    loaderProperty: "fetchPartNumberOptions",
    property: "partNumberOptions",
    loadingProperty: "isPartNumberOptionsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
  {
    queryKey: "orderNumber:contains",
    label: (t) => t("partview-search:Order #"),
    placeholder: (t) => t("partview-search:Search Order #"),
    queryBuilder: getBasicQueryString,
    loaderProperty: "fetchOrderNumberOptions",
    property: "orderNumberOptions",
    loadingProperty: "isOrderNumberOptionsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
  {
    queryKey: "shipmentId:contains",
    label: (t) => t("partview-search:Shipment ID"),
    placeholder: (t) => t("partview-search:Search Shipment ID"),
    queryBuilder: getBasicQueryString,
    loaderProperty: "fetchShipmentIdOptions",
    property: "shipmentIdOptions",
    loadingProperty: "isShipmentIdOptionsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
  {
    queryKey: "trackingNumber:contains",
    label: (t) => t("partview-search:Package #"),
    placeholder: (t) => t("partview-search:Search Package #"),
    queryBuilder: getBasicQueryString,
    loaderProperty: "fetchPackageNumberOptions",
    property: "packageNumberOptions",
    loadingProperty: "isPackageNumberOptionsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
  {
    queryKey: "billOfLading:contains",
    label: (t) => t("partview-search:Bill of Lading"),
    placeholder: (t) => t("partview-search:Search Bill of Lading"),
    queryBuilder: getBasicQueryString,
    loaderProperty: "fetchBillOfLadingOptions",
    property: "billOfLadingOptions",
    loadingProperty: "isBillOfLadingOptionsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value",
  },
];

export const FILTERS = [
  {
    queryKey: "origin",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("partview-search:Origin"),
    optionsState: originStateOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryString("originCode", filterValue, {
        transformValue: (code) => `${code}`,
      }),
    hideFuzzySearch: true,
  },
  {
    queryKey: "destination",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("partview-search:Destination"),
    optionsState: destinationStateOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryString("destinationCode", filterValue, {
        transformValue: (code) => `${code}`,
      }),
    hideFuzzySearch: true,
  },
  {
    queryKey: "carrier",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("partview-search:Carrier"),
    optionsState: carrierOptionsState,
    queryBuilder: getMultiSelectQueryString,
    hideSelectEmpty: true,
  },
  {
    queryKey: "partNumber",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("partview-search:Parts"),
    optionsState: partNumberOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: "lifecycleState",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("partview-search:Package Status"),
    optionsState: packageStatusOptionsState,
    queryBuilder: getMultiSelectQueryString,
    hideFuzzySearch: true,
  },
  {
    queryKey: "deliveryDate",
    label: (t) => t("partview-search:Delivery Date"),
    Component: DateRangeFilterButton,
    queryBuilder: (queryParameter, filterValue) => {
      return getDateRangeQueryString(queryParameter, filterValue, {
        convertToUtc: true,
      });
    },
    dateTypeOptions: (t) => {
      let dateOptions = [
        {
          label: t("partview-search:Scheduled"),
          value: "scheduled",
        },
        { label: t("partview-search:Actual"), value: "actual" },
        { label: t("partview-search:ETA"), value: "eta" },
      ];

      return dateOptions;
    },
    isValueValid: isDateRangeValueValid,
  },
  {
    queryKey: "exception",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("partview-search:Active Exception"),
    optionsState: activeExceptionOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: "orderDate",
    label: (t) => t("partview-search:Order Date"),
    Component: DateRangeFilterButton,
    queryBuilder: getDateRangeQueryString,
  },
  {
    queryKey: "orderPriority",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("partview-search:Order Priority"),
    optionsState: orderPriorityOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: "batch",
    label: (t) => t("partview-search:Batch Search"),
    Component: BatchFilterButton,
    optionsGetter: (props, t) => {
      return [
        {
          label: t("partview-search:Part #"),
          value: "partNumber",
          batchCsvExample: batchCsvPartViewPartNumberExample,
        },
        {
          label: t("partview-search:Bill of Lading"),
          value: "billOfLading",
          batchCsvExample: batchCsvBillofLadingExample,
        },
        {
          label: t("partview-search:Shipment ID"),
          value: "shipmentId",
          batchCsvExample: batchCsvPartViewShipmentIDExample,
        },
        {
          label: t("partview-search:Package #"),
          value: "trackingNumber",
          batchCsvExample: batchCsvPackageNumberExample,
        },
        {
          label: t("partview-search:Order #"),
          value: "orderNumber",
          batchCsvExample: batchCsvOrderNumberExample,
        },
        {
          label: t("partview-search:Equipment/Trailer #"),
          value: "trailerEquipmentNumber",
          batchCsvExample: batchCsvEquipmentTrailerNumberExample,
        },
      ];
    },
    queryBuilder: getEmptyQueryString,
  },
  // CAT-4688 - Removed Final Mile Origin filter
  // Removed commented code for Back Order filter
];
