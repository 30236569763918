import axios from "axios";
import apiUrl from "api-url";

// URLS
const SUBSCRIBE_URL = apiUrl("/subscription/user");
const ORGANIZATIONS_URL = apiUrl("/iam/organizations");

// Redux mount point
const STORE_MOUNT_POINT = "fvSubscribe";

// Actions
const SUBSCRIBE_RESET = `${STORE_MOUNT_POINT}/SUBSCRIBE_RESET`;
const FETCH_SUBSCRIPTION_STARTED = `${STORE_MOUNT_POINT}/FETCH_SUBSCRIPTION_STARTED`;
const FETCH_SUBSCRIPTION_SUCCESS = `${STORE_MOUNT_POINT}/FETCH_SUBSCRIPTION_SUCCESS`;
const FETCH_SUBSCRIPTION_FAILED = `${STORE_MOUNT_POINT}/FETCH_SUBSCRIPTION_FAILED`;
const SUBSCRIBE_STARTED = `${STORE_MOUNT_POINT}/SUBSCRIBE_STARTED`;
const SUBSCRIBE_SUCCESS = `${STORE_MOUNT_POINT}/SUBSCRIBE_SUCCESS`;
const SUBSCRIBE_FAILED = `${STORE_MOUNT_POINT}/SUBSCRIBE_FAILED`;
const FETCH_ORGANIZATION_IMAGES = `${STORE_MOUNT_POINT}/FETCH_ORGANIZATION_IMAGES`;

function resetSubscribe() {
  return (dispatch) => {
    dispatch({ type: SUBSCRIBE_RESET });
  };
}

function fetchSubscription(guid) {
  return (dispatch) => {
    dispatch({ type: FETCH_SUBSCRIPTION_STARTED });

    const url = `${SUBSCRIBE_URL}?subscriptionId=${guid}`;

    return axios
      .get(url)
      .then((response) => {
        dispatch({ type: FETCH_SUBSCRIPTION_SUCCESS, response: response.data });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SUBSCRIPTION_FAILED,
          errorMessage: error?.message,
        });
      });
  };
}

function fetchOrganizationImages(orgId) {
  const url = `${ORGANIZATIONS_URL}/${orgId}/images`;

  return (dispatch) => {
    return axios
      .get(`${url}`)
      .then((response) => {
        let payload = {
          logoPath: response.data?.logoUrl,
        };
        dispatch({
          type: FETCH_ORGANIZATION_IMAGES,
          payload: payload,
        });
      })
      .catch((err) => {});
  };
}

function subscribe(guid, category) {
  return (dispatch) => {
    const payload = {
      subscription_id: guid,
      category,
    };

    dispatch({ type: SUBSCRIBE_STARTED });

    const url = `${SUBSCRIBE_URL}/activate`;

    return axios
      .post(url, payload)
      .then((response) => {
        dispatch({ type: SUBSCRIBE_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: SUBSCRIBE_FAILED, errorMessage: error?.message });
      });
  };
}

// Selectors
const getSubscription = (state) =>
  state[STORE_MOUNT_POINT].subscription || null;
const getIsFetchingSubscription = (state) =>
  state[STORE_MOUNT_POINT].isFetchingSubscription || false;
const getIsFetchSubscriptionError = (state) =>
  state[STORE_MOUNT_POINT].isFetchSubscriptionError || false;
const getFetchSubscriptionErrorMessage = (state) =>
  state[STORE_MOUNT_POINT].fetchSubscriptionErrorMessage || null;
const getOrganizationImageConfig = (state) =>
  state[STORE_MOUNT_POINT].organizationImageConfig || {};

const getIsSubscribeComplete = (state) =>
  state[STORE_MOUNT_POINT].isSubscribeComplete || false;
const getIsSubscribing = (state) =>
  state[STORE_MOUNT_POINT].isSubscribing || false;
const getIsSubscribeError = (state) =>
  state[STORE_MOUNT_POINT].isSubscribeError || false;
const getSubscribeErrorMessage = (state) =>
  state[STORE_MOUNT_POINT].subscribeErrorMessage || null;

// Initial state
const initialState = {
  subscription: null,
  isFetchingSubscription: false,
  isFetchSubscriptionError: false,
  fetchSubscriptionErrorMessage: null,
  isSubscribeComplete: false,
  isSubscribing: false,
  isSubscribeError: false,
  subscribeErrorMessage: null,
};

const SubscribeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIBE_RESET:
      return {
        ...state,
        ...initialState,
      };
    case FETCH_SUBSCRIPTION_STARTED:
      return {
        ...state,
        subscription: null,
        isFetchingSubscription: true,
        isFetchSubscriptionError: false,
        fetchSubscriptionErrorMessage: false,
      };
    case FETCH_SUBSCRIPTION_SUCCESS:
      return {
        subscription: action.response,
        isFetchingSubscription: false,
        isFetchSubscriptionError: false,
        fetchSubscriptionErrorMessage: false,
      };
    case FETCH_SUBSCRIPTION_FAILED:
      return {
        isFetchingSubscription: false,
        isFetchSubscriptionError: true,
        fetchSubscriptionErrorMessage: action.errorMessage,
      };
    case FETCH_ORGANIZATION_IMAGES:
      return {
        ...state,
        organizationImageConfig: action.payload,
      };
    case SUBSCRIBE_STARTED:
      return {
        ...state,
        isSubscribing: true,
        isSubscribeComplete: false,
        isSubscribeError: false,
        subscribeErrorMessage: null,
      };
    case SUBSCRIBE_SUCCESS:
      return {
        ...state,
        isSubscribing: false,
        isSubscribeComplete: true,
        isSubscribeError: false,
        subscribeErrorMessage: null,
      };
    case SUBSCRIBE_FAILED:
      return {
        ...state,
        isSubscribing: false,
        isSubscribeComplete: false,
        isSubscribeError: true,
        subscribeErrorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

// interface
const SubscribeState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    resetSubscribe,
    subscribe,
    fetchSubscription,
    fetchOrganizationImages,
  },
  selectors: {
    getSubscription,
    getIsFetchingSubscription,
    getIsFetchSubscriptionError,
    getFetchSubscriptionErrorMessage,
    getIsSubscribeComplete,
    getIsSubscribing,
    getIsSubscribeError,
    getSubscribeErrorMessage,
    getOrganizationImageConfig,
  },
  reducer: SubscribeReducer,
};
export default SubscribeState;
