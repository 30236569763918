import _ from "lodash";
import moment from "moment";
import {
  FUZZY_PREFIX,
  SELECT_ALL_OPTION_VALUE,
  SELECT_EMPTY_OPTION_VALUE,
} from "components/molecules/SearchableSelectList.molecule";

// used in FinVehicleSearchCategoryDefsOpenSearch.js file
export const getCategorySearchRequestBody = (queryParameter, filterValue) => {
  if (!filterValue) {
    return null;
  }

  const filterValues = Array.isArray(filterValue) ? filterValue : [filterValue];

  if (!filterValues) {
    return null;
  }

  return { values: filterValues };
};

// Helper
function hasRequestBody(requestBody) {
  return (
    !_.isEmpty(requestBody.values) ||
    _.isBoolean(requestBody.isNull) ||
    !_.isEmpty(requestBody.contains)
  );
}

// Helper function to check and set isNull value
const setStaticValues = (value, requestBody) => {
  if (value === SELECT_EMPTY_OPTION_VALUE) {
    requestBody.isNull = true;
  } else if (value === SELECT_ALL_OPTION_VALUE) {
    requestBody.isNull = false;
  } else if (value?.toString().startsWith(FUZZY_PREFIX)) {
    const fuzzyValue = value.substring(FUZZY_PREFIX.length);
    requestBody.contains = fuzzyValue;
  } else {
    return requestBody;
  }
};
// query parameter builders
export const getBasicQueryString = (queryParameter, filterValue) => {
  let url = "";
  if (isValidBasicFilter(filterValue)) {
    url += `&${queryParameter}=${encodeURIComponent(filterValue)}`;
  }
  return url;
};

/**
 * This method returns whether or not the `value` from an option is "static".
 *
 * @param {string} value The value from an option.
 * @returns
 */
const getIsStaticOptionValue = (value) => {
  return (
    value === SELECT_EMPTY_OPTION_VALUE ||
    value === SELECT_ALL_OPTION_VALUE ||
    value?.toString().startsWith(FUZZY_PREFIX)
  );
};

/**
 * This method returns the filterValues that are not "static".
 * `filterValues` can be an array of options objects or strings
 * and it must return the same type that is passed in.
 *
 * @param {*} filterValues
 * @returns
 */
const getNonStaticFilterValues = (filterValues) => {
  return (
    // `filterValues` can be an array of option objects or strings.
    filterValues.filter((optionOrValue) => {
      let value;

      if (typeof optionOrValue === "object") {
        // Sometimes the "value" sent to the API is not what is in the value field.
        // But since static options always use the `value` property,
        // we only need to check that field.
        value = optionOrValue.value;
      } else {
        value = optionOrValue;
      }

      return !getIsStaticOptionValue(value);
    })
  );
};

const transformCurrentLocationKeys = (requestBody) => {
  const keyMapping = {
    currentPositionTypes: "positionTypes",
    currentPositionCodes: "atLocation",
  };

  const transformedRequestBody = {};

  Object.keys(keyMapping).forEach((key) => {
    if (requestBody[key]) {
      if (!transformedRequestBody.currentLocation) {
        transformedRequestBody.currentLocation = {};
      }
      if (key === "currentPositionTypes") {
        // Directly assign the array for positionTypes
        transformedRequestBody.currentLocation[keyMapping[key]] =
          requestBody[key].values;
      } else {
        // Assign the values array for other keys
        transformedRequestBody.currentLocation[keyMapping[key]] =
          requestBody[key];
      }
      delete requestBody[key];
    }
  });

  return transformedRequestBody;
};

/**
 * This method returns the filterValues that are "static".
 * These can be array of option objects or strings.  Only
 * the strings are required to be returned here.
 *
 * @param {*} filterValues
 * @returns
 */
const getStaticFilterValues = (filterValues) => {
  return (
    // `filterValues` can be an array of option objects or strings.
    filterValues.filter((optionOrValue) => {
      let value;

      if (typeof optionOrValue === "object") {
        // Sometimes the "value" sent to the API is not what is in the value field.
        // But since static options always use the `value` property,
        // we only need to check that field.
        value = optionOrValue.value;
      } else {
        value = optionOrValue;
      }

      return getIsStaticOptionValue(value);
    })
  );
};

// is a filterValue a valid array with elements or non-blank string search?
const isValidBasicFilter = (filterValue) => {
  if (
    filterValue != null &&
    ((Array.isArray(filterValue) && filterValue.length > 0) ||
      (filterValue && !/^\s*$/.test(filterValue)))
  ) {
    return true;
  }
  return false;
};

/**
 * This method builds a piece of the overall query string
 * based on the queryParameter and filterValue.
 *
 * It also handles special cases for "Select all", "Select empty values",
 * and "Fuzzy" searching.
 *
 * @param {*} queryParameter
 * @param {*} filterValue single value OR array of filter values
 * @param {*} queryParameterPrefix
 * @param {*} queryParameterSuffix
 * @returns {string}
 */
// Carrier, exception, orderType, origin region, destination region, routeId
export const getBasicWithStaticOptionsRequestBody = (
  queryParameter,
  filterValue,
) => {
  let requestBody = { values: [] };

  if (!filterValue) {
    return null;
  }

  // some old saved searches may have a single value, convert to array
  const filterValues = !Array.isArray(filterValue)
    ? [filterValue]
    : filterValue;

  if (filterValues.includes(SELECT_ALL_OPTION_VALUE)) {
    requestBody.isNull = false;
    return requestBody;
  }
  if (filterValues.includes(SELECT_EMPTY_OPTION_VALUE)) {
    requestBody.isNull = true;
  }
  if (filterValues?.toString().startsWith(FUZZY_PREFIX)) {
    if (filterValues) {
      const fuzzyValue = filterValues[0].substring(FUZZY_PREFIX.length);
      requestBody.contains = fuzzyValue;
    }
  }

  // these are all non-static filter values, which excludes
  // select empty, select all, and fuzzy search values
  const nonStaticFilterValues = getNonStaticFilterValues(filterValues);

  requestBody.values.push(...nonStaticFilterValues);

  if (hasRequestBody(requestBody)) {
    return requestBody;
  }
  return null;
};

export const getNRequestBodyFilterValuePriority = (
  queryParameters, // For NFilterButton, this is the array of queryKeys.
  filterValue,
) => {
  let requestBody = {};
  if (!filterValue.currentPositionTypes) {
    return null;
  }

  queryParameters.forEach((key) => {
    if (_.isNil(filterValue[key])) {
      requestBody[key] = { isNull: null, values: [] };
    } else if (Array.isArray(filterValue[key])) {
      requestBody[key] = getMultiSelectRequestBody(key, filterValue[key]);
    } else {
      requestBody[key] = getBasicWithStaticOptionsRequestBody(key, [
        filterValue[key],
      ]);
    }
  });

  return transformCurrentLocationKeys(requestBody);
};

export const getRequestBodyForVinStatus = (
  queryParameters,
  filterValues,
  transformKey,
) => {
  const requestValue = {
    values: [],
  };

  if (filterValues?.lifeCycleState) {
    requestValue.values = [...filterValues.lifeCycleState];
  }

  Object.keys(filterValues).forEach((item) => {
    if (item === "activeSubStatus") {
      if (filterValues.activeSubStatus === "prebuilt") {
        requestValue.values = ["Prebuilt"];
      } else if (filterValues.activeSubStatus === "all_active") {
        requestValue.values = [...requestValue.values, "Prebuilt"];
      } else if (filterValues.activeSubStatus === "active") {
        requestValue.values = ["Active"];
      }
    }
  });

  if (!_.isEmpty(requestValue.values)) {
    return {
      [transformKey]: requestValue,
    };
  }
};

// pickupDate, deliveryDate, completeDate
export const getDateRangeRequestBody = (queryParameter, filterValue) => {
  if (!isValidDateRangeFilter(filterValue)) {
    return "";
  }
  let requestBody = {};
  if (filterValue) {
    requestBody = {
      from: moment(filterValue.from).format("YYYY-MM-DD HH:mm:ss"),
      to: moment(filterValue.to).format("YYYY-MM-DD HH:mm:ss"),
      fieldType: filterValue.dateType.map((dateType) => dateType.toUpperCase()),
    };
    return requestBody;
  }
  return null;
};

// Shippability
export const getShippabilityRequestBody = (queryParameter, filterValue) => {
  let requestBody = {};
  if (!filterValue) {
    return null;
  }
  if (filterValue) {
    requestBody.exists = filterValue === "shippable";
  }
  return requestBody;
};

// is a filterValue a valid date range object?
const isValidDateRangeFilter = (filterValue) => {
  if (
    filterValue != null &&
    typeof filterValue === "object" &&
    ("from" in filterValue || "to" in filterValue)
  ) {
    return true;
  }
  return false;
};

export const getMultiSelectRequestBody = (queryParameter, filterValue) => {
  let requestBody = { isNull: null, values: [] };

  if (Array.isArray(filterValue)) {
    const nonStaticFilterOptions = getNonStaticFilterValues(filterValue);
    const staticFilterOptions = getStaticFilterValues(filterValue);

    // Extract the value properties if present (for simple types like integers, fall back to the raw value)
    const nonStaticValues = nonStaticFilterOptions.map((val) =>
      _.get(val, "value", val),
    );
    const staticValues = staticFilterOptions.map((val) =>
      _.get(val, "value", val),
    );

    requestBody.values.push(...nonStaticValues);
    staticValues.forEach((value) => setStaticValues(value, requestBody));
    if (hasRequestBody(requestBody)) {
      return requestBody;
    }
    return null;
  }

  if (isValidBasicFilter(filterValue)) {
    requestBody.values = [filterValue];
  }

  if (hasRequestBody(requestBody)) {
    return requestBody;
  }
  return null;
};

/**
 * Query builder for location filter lists. Usually async multiselects.
 *
 * @example
 * getLocationQueryString(
 *   "originCode",
 *   [
 *     {label: "Loc1", value: 123, id: 123, code: "LOC-123" },
 *     {label: "Loc2", value: 456, id: 123, code: "LOC-456" },
 *   ],
 *   {
 *     valueKey: "code",
 *     transformValue: code => `"${code}"`
 *   }
 * );
 * // returns '&originCode="LOC-123","LOC-456"'
 *
 * @param {string} queryParameter
 * @param {object[]} filterValue
 * @param {object} config Config for how to handle the resulting query param names and values
 * @param {string} [config.valueKey=value] The property key/field to use as the value for the URL param
 * @param {(value: any) => any} [config.transformValue] Transforms each value before setting as URL param value
 * @returns {string}
 */
export const getLocationRequestBody = (
  queryParameter,
  filterValue,
  config = {},
) => {
  let requestBody = { values: [] };

  if (!Array.isArray(filterValue)) {
    if (isValidBasicFilter(filterValue)) {
      requestBody.values = [filterValue];
      return requestBody;
    }
  }

  const { valueKey = "value", transformValue = (value) => value } = config;

  // Values sent to the API configured by "valueKey"
  // e.g. Sometimes we want to use the code instead of the id.
  requestBody.values = getNonStaticFilterValues(filterValue).map(
    (optionOrValue) => {
      const value = _.get(optionOrValue, valueKey, optionOrValue);
      // Optional transform function. e.g. wrap each value with double quotes.
      return transformValue(value);
    },
  );

  const staticFilterOptions = getStaticFilterValues(filterValue);

  if (!_.isEmpty(staticFilterOptions)) {
    staticFilterOptions.forEach((option) => {
      const value = option.value;
      if (value.startsWith(FUZZY_PREFIX)) {
        const fuzzyValue = value.substring(FUZZY_PREFIX.length);
        requestBody.contains = fuzzyValue;
      } else if (value === SELECT_EMPTY_OPTION_VALUE) {
        requestBody.isNull = true;
      } else if (value === SELECT_ALL_OPTION_VALUE) {
        requestBody.isNull = false;
      }
    });
  }
  if (hasRequestBody(requestBody)) {
    return requestBody;
  }
  return null;
};
