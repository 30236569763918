/** @jsxImportSource @emotion/react */
import { Fragment, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";

import { ShippabilityStatusText } from "shared/components/molecules/ShippabilityStatusText.molecule";
import { FontSize, Text } from "components/atoms/Text.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Icon } from "components/atoms/Icon.atom";

import Colors from "styles/colors";

import { DepartedSource } from "api/consts";

import { getDaysHrsMinsBetweenTimestamps } from "utils/date-time";
import { BrowserStorage } from "utils/browser-storage.utils";
import { translateCurrentPositionType } from "shared/utils/entity.utils";
import { getInitialEtaCellColorCode } from "../../utils/table.utils";
import {
  faCopy,
  faCheckSquare as faCheck,
} from "@fortawesome/pro-light-svg-icons";

const calculateColorBasedOnDuration = (calculatedDuration) => {
  let diffDuration = moment.duration(calculatedDuration.duration).asDays();

  if (diffDuration < 0) {
    return Colors.text.GRAY;
  }

  switch (true) {
    case diffDuration >= 10:
      return Colors.inventoryView.RED;
    case diffDuration >= 6 && diffDuration < 10:
      return Colors.inventoryView.ORANGE;
    case diffDuration >= 3 && diffDuration < 6:
      return Colors.inventoryView.ORANGE_YELLOW;
    case diffDuration >= 0 && diffDuration < 3:
      return Colors.inventoryView.GREEN;
    default:
      return Colors.text.GRAY;
  }
};

const VinCell = (props) => {
  const { t } = useTranslation("inventory-view");
  const {
    entityExternalId,
    productType,
    isShippable,
    orderType,
    estimatedProductionTs,
    releasedToCarrierTs,
  } = props.value;

  const [clipboardIcon, setClipboardIcon] = useState(faCopy);
  const [clipboardIconColor, setClipboardIconColor] = useState(
    Colors.highlight.DARK_GRAY,
  );

  const handleCopy = (e) => {
    navigator.clipboard.writeText(entityExternalId);
    setClipboardIcon(faCheck);
    setClipboardIconColor(Colors.highlight.GREEN);
    setTimeout(() => {
      setClipboardIcon(faCopy);
      setClipboardIconColor(Colors.highlight.DARK_GRAY);
    }, 1800);
    e.stopPropagation();
  };

  return (
    <div>
      <div css={{ display: "flex", alignItems: "center" }}>
        <Text bold block size={FontSize.size14} css={{ marginRight: "0.5em" }}>
          {entityExternalId}
        </Text>
        <Icon
          src={clipboardIcon}
          size={FontSize.size16}
          color={clipboardIconColor}
          onClick={handleCopy}
          css={{ cursor: "pointer" }}
        />
      </div>
      <Text block size={FontSize.size14}>
        {productType}
      </Text>
      <Text bold size={FontSize.size14}>
        {t("inventory-view:Order Type")}:{" "}
      </Text>
      {orderType ? (
        <Text>{orderType}</Text>
      ) : (
        <Text size={FontSize.size12}>{t("inventory-view:N/A")}</Text>
      )}

      <br />
      {/* Production Date */}
      <Text bold size={FontSize.size14}>
        {t("inventory-view:Production Date")}:{" "}
      </Text>
      <DateTime
        showDate
        dateTime={estimatedProductionTs}
        showTime={false}
        fallback={<Text size={FontSize.size12}>{t("inventory-view:N/A")}</Text>}
        timezonePreference={BrowserStorage.locationTimezone}
      />
      <br />
      {/* Gate release */}
      <Text bold size={FontSize.size14}>
        {t("inventory-view:Gate Release")}:{" "}
      </Text>
      <DateTime
        showDate
        dateTime={releasedToCarrierTs}
        showTime={false}
        fallback={
          <Text size={FontSize.size12}> {t("inventory-view:N/A")}</Text>
        }
        timezonePreference={BrowserStorage.locationTimezone}
      />
      <ShippabilityStatusText
        isShippable={isShippable}
      ></ShippabilityStatusText>
    </div>
  );
};

VinCell.propTypes = {
  value: PropTypes.shape({
    entityExternalId: PropTypes.string,
    productType: PropTypes.string,
    isShippable: PropTypes.bool,
    orderType: PropTypes.string,
    releasedToCarrierTs: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    estimatedProductionTs: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    initialForecastedUltimateDestinationArrivalTs: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
};

const CurrentLocationCell = (props) => {
  const { positionType, locationName } = props.value;
  return (
    <div>
      <Text block>{positionType}</Text>
      <Text block bold>
        {locationName ?? ""}
      </Text>
    </div>
  );
};

CurrentLocationCell.propTypes = {
  value: PropTypes.shape({
    positionType: PropTypes.string,
    locationName: PropTypes.string,
  }),
};

const ForecastedArrivalCell = (props) => {
  const { t } = useTranslation("inventory-view");
  return (
    <div>
      <Text bold block size={FontSize.size14}>
        {t("inventory-view:Forecasted Arrival")}
      </Text>
      <DateTime
        showDate
        dateTime={props.value}
        showTime={false}
        fallback={<Text size={FontSize.size12}>{t("inventory-view:N/A")}</Text>}
        timezonePreference={BrowserStorage.locationTimezone}
      />
    </div>
  );
};

ForecastedArrivalCell.propTypes = {
  value: PropTypes.string,
};

const InitialEtaCell = (props) => {
  const { t } = useTranslation("inventory-view");
  return (
    <DateTime
      style={{ color: getInitialEtaCellColorCode(props.value) }}
      showDate
      dateTime={props.value}
      showTime={false}
      bold={true}
      fallback={<Text size={FontSize.size12}>{t("inventory-view:N/A")}</Text>}
      timezonePreference={BrowserStorage.locationTimezone}
    />
  );
};

InitialEtaCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const TimeOnSiteCell = (props) => {
  const { t } = useTranslation("inventory-view");
  const { arrivalTs, departureTs, departureSource } = props.value;

  const locationTimezone = BrowserStorage.locationTimezone
    ? BrowserStorage.locationTimezone
    : moment.tz.guess();

  let calculatedDuration = getDaysHrsMinsBetweenTimestamps(
    moment().tz(locationTimezone),
    arrivalTs,
  );

  const setColor = calculateColorBasedOnDuration(calculatedDuration);

  const displayDuration =
    moment.duration(calculatedDuration.duration).minutes() > 0;

  const formattedDurationText = displayDuration ? (
    <Text block bold css={{ color: setColor }}>
      {calculatedDuration?.formattedDuration}
    </Text>
  ) : null;

  const showDepartedTooltip =
    departureSource === DepartedSource.DERIVED_BY_MILESTONE ||
    departureSource === DepartedSource.DERIVED_BY_DELIVERY;

  const getTooltipText = () => {
    switch (departureSource) {
      case DepartedSource.DERIVED_BY_MILESTONE:
        return t(
          "inventory-view:This date was system derived from a milestone at another location",
        );
      case DepartedSource.DERIVED_BY_DELIVERY:
        return t(
          "inventory-view:This date was system derived from the VIN being marked as Delivered",
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {arrivalTs && !departureTs ? (
        <Fragment>
          <Text bold size={FontSize.size14}>
            {t("inventory-view:Arrival")}:{" "}
          </Text>
          <DateTime
            showDate
            dateTime={arrivalTs}
            showTime={false}
            fallback={
              <Text size={FontSize.size12}>{t("inventory-view:N/A")}</Text>
            }
            timezonePreference={BrowserStorage.locationTimezone}
          />
          <Text block>{formattedDurationText}</Text>
        </Fragment>
      ) : departureTs ? (
        <Fragment>
          <Text bold size={FontSize.size14}>
            <Fragment>
              {t("inventory-view:Departed")}
              {showDepartedTooltip ? (
                <Tooltip
                  placement="top"
                  tooltipChildren={<Text>{getTooltipText()}</Text>}
                >
                  <Icon
                    src={faInfoCircle}
                    color={Colors.tables.tableHeadingText}
                    css={{ marginLeft: 4 }}
                  />
                </Tooltip>
              ) : null}
            </Fragment>
          </Text>
          <br />
          <DateTime
            showDate
            dateTime={departureTs}
            showTime={false}
            fallback={
              <Text size={FontSize.size12}>{t("inventory-view:N/A")}</Text>
            }
            timezonePreference={BrowserStorage.locationTimezone}
          />
        </Fragment>
      ) : (
        <Text bold block>
          {t("inventory-view:Not yet Arrived")}
        </Text>
      )}
    </div>
  );
};

TimeOnSiteCell.propTypes = {
  value: PropTypes.shape({
    arrivalTs: PropTypes.string,
    departureTs: PropTypes.string,
    departureSource: PropTypes.string,
  }),
};

const DestinationCell = (props) => {
  const { destination } = props.value;
  return (
    <div>
      <Text size={FontSize.size14} bold block>
        {destination?.code ?? ""}
      </Text>
      <Text size={FontSize.size14} block>
        {destination?.name ?? ""}
      </Text>
      <LocationAddressComplex
        address1={destination?.address ?? ""}
        city={destination?.city ?? ""}
        state={destination?.state ?? ""}
        country={destination?.country ?? ""}
        defaultTextColor={Colors.text.DARK_GRAY}
        addressColor={Colors.text.DARK_GRAY}
      />
    </div>
  );
};

DestinationCell.propTypes = {
  value: PropTypes.shape({
    destination: PropTypes.shape({
      code: PropTypes.string,
      state: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
};

export const useColumns = () => {
  const { t } = useTranslation("inventory-view");
  const timezoneTooltipText = t(
    "inventory-view:Dates and times are displayed in the location's timezone",
  );

  const columns = [
    {
      Header: t("inventory-view:VIN"),
      accessor: (obj) => {
        return {
          entityExternalId: obj?.entityExternalId ?? "",
          productType: obj?.productType,
          isShippable:
            obj?.shippableAtTs !== null && obj?.shippableAtTs !== undefined,
          orderType: obj?.orderType ?? "",
          estimatedProductionTs: obj?.estimatedProductionTs ?? "",
          releasedToCarrierTs: obj?.releasedToCarrierTs ?? "",
        };
      },
      width: 200,
      disableSortBy: true,
      Cell: VinCell,
      showTooltip: true,
      tooltipText: timezoneTooltipText,
    },
    {
      Header: t("inventory-view:Current Location"),
      accessor: (obj) => {
        const currentPosition = obj?.currentPosition ?? {};
        return {
          locationName: currentPosition?.location?.name ?? "",
          positionType:
            translateCurrentPositionType(t, currentPosition?.type) ?? "",
        };
      },
      width: 150,
      disableSortBy: true,
      Cell: CurrentLocationCell,
    },
    {
      Header: t("inventory-view:Forecasts"),
      id: "eta",
      accessor: (obj) => {
        return obj.forecastedArrivalTs ?? "";
      },
      width: 150,
      Cell: ForecastedArrivalCell,
      showTooltip: true,
      tooltipText: timezoneTooltipText,
    },
    {
      Header: t("inventory-view:Initial ETA"),
      id: "initialEta",
      accessor: (obj) => {
        return obj.initialForecastedUltimateDestinationArrivalTs;
      },
      width: 150,
      Cell: InitialEtaCell,
      showTooltip: true,
      tooltipText: timezoneTooltipText,
    },
    {
      Header: t("inventory-view:Destination Details"),
      accessor: (obj) => {
        return {
          destination: obj.ultimateDestinationLocation ?? {},
        };
      },
      width: 150,
      disableSortBy: true,
      Cell: DestinationCell,
    },
    {
      Header: t("inventory-view:Time on Site"),
      id: "timeOnSite",
      accessor: (obj) => {
        return {
          arrivalTs: obj.arrivalTs,
          departureTs: obj.departureTs,
          departureSource: obj.departureSource,
        };
      },
      width: 150,
      Cell: TimeOnSiteCell,
      showTooltip: true,
      tooltipText: timezoneTooltipText,
    },
  ];

  return columns;
};
