import axios from "axios";
import { customerApiUrl } from "api-url";
import _ from "lodash";
import qs from "qs";

import { SEARCH_CATEGORIES_OPEN_SEARCH } from "pages/finishedvehicle/search/FinVehicleSearchCategoryDefsOpenSearch";
import { OPEN_SEARCH_FILTERS } from "pages/finishedvehicle/search/FinVehicleSearchFilterDefsOpenSearch";

const MOUNT_POINT = "fvSavedSearchCardsOpenSearch";

const FETCH_SAVED_SEARCH_CARD_DATA = `${MOUNT_POINT}/FETCH_SAVED_SEARCH_CARD_DATA`;
const RECEIVE_SAVED_SEARCH_CARD_DATA = `${MOUNT_POINT}/RECIEVE_SAVED_SEARCH_CARD_DATA`;

const formatResponse = (response) => {
  return {
    exceptions: response.data?.finishedVehicleVins?.exceptions || [],
    deliveredCount:
      response.data?.finishedVehicleVins?.deliveredVins?.delivered || 0,
    activeCount: response.data?.finishedVehicleVins?.activeVins?.total || 0,
    completeCount:
      response.data?.finishedVehicleVins?.deliveredVins?.complete || 0,
  };
};

// Using search categories and filters, get the query strings from the transformFilterValue
const getSavedSearchRequestPayload = (savedSearch) => {
  const searchObj = savedSearch.search;

  let category = null;
  let categoryValue = null;
  for (let categoryDef of SEARCH_CATEGORIES_OPEN_SEARCH) {
    if (
      searchObj[categoryDef.queryKey] !== null &&
      typeof searchObj[categoryDef.queryKey] === "string"
    ) {
      category = categoryDef;
      categoryValue = searchObj[category.queryKey];
    }
  }

  let filterValues = searchObj;
  if (!_.isNil(category)) {
    filterValues = _.omit(filterValues, category.queryKey);
  }

  let qs = {};

  if (category && categoryValue) {
    const categoryFilterValue = category.transformFilterValue(
      category.queryKey,
      categoryValue,
    );
    qs = {
      [category.queryKey]: categoryFilterValue,
    };
  }
  const filterValueKeys = Object.keys(filterValues);
  for (let filterDef of OPEN_SEARCH_FILTERS) {
    // Handle an array of QSPs for NFilterButton.
    if (
      Array.isArray(filterDef.queryKey) &&
      filterValueKeys.some((r) => filterDef.queryKey.includes(r))
    ) {
      const filterValue = filterDef.transformFilterValue(
        filterDef.queryKey,
        filterValues,
      );
      let isNthFilter = Array.isArray(filterDef.queryKey);
      if (isNthFilter) {
        qs = {
          ...qs,
          ...filterValue,
        };
      }
    }

    // Handle usual filter value.
    if (!_.isNil(filterValues[filterDef.queryKey])) {
      const filterValue = filterDef.transformFilterValue(
        filterDef.queryKey,
        filterValues[filterDef.queryKey],
      );
      if (filterDef.queryKey === "batch") {
        qs = {
          ...qs,
          ...filterValue,
        };
      } else {
        qs = {
          ...qs,
          [filterDef.queryKey]: {
            ...filterValue,
          },
        };
      }
    }
  }

  return qs;
};

// Object that will hold cancel callbacks for the requests made in the fetch
const tokens = {};
// Allows us to know if the error is from a cancelation in the catch of the promise
const cancelMessage = "CANCELED";

const fetchSavedSearchCardData = (solutionId, savedSearch) => {
  // If we saved tokens from a previous request, cancel those requests
  if (tokens[savedSearch.id]) {
    tokens[savedSearch.id].cancelRequest(cancelMessage);
  }

  // Reset tokens for this saved search
  tokens[savedSearch.id] = {};

  return (dispatch) => {
    dispatch({ type: FETCH_SAVED_SEARCH_CARD_DATA, id: savedSearch.id });

    // Clone the saved search object (to not affect the passed in one)
    // and override the lifeCycleState parameter
    let clonedSavedSearch = _.cloneDeep(savedSearch);

    const requestBody = qs.parse(
      getSavedSearchRequestPayload(clonedSavedSearch),
    );

    // Batch searches use POST with data in the body and query string parameters
    // Regular searches use GET with query string parameters
    const request = axios({
      method: "POST",
      url: customerApiUrl(
        `/finished-product/finished-product/${solutionId}/dashboard`,
      ),
      data: { ...requestBody },
      headers: { Accept: "application/json" },
      // Create a cancel token for this request and save it in the tokens object
      cancelToken: new axios.CancelToken((cancel) => {
        tokens[savedSearch.id].cancelRequest = cancel;
      }),
    });

    return request
      .then((response) => {
        dispatch({
          type: RECEIVE_SAVED_SEARCH_CARD_DATA,
          id: savedSearch.id,
          data: formatResponse(response),
        });
      })
      .catch((error) => {
        if (error.message !== cancelMessage) {
          console.log(error);
        }
      });
  };
};

const getSavedSearchCardData = (id) => (state) => state[MOUNT_POINT][id];

const reducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SAVED_SEARCH_CARD_DATA:
      return {
        ...state,
        [action.id]: {
          data: [],
          isLoading: true,
        },
      };
    case RECEIVE_SAVED_SEARCH_CARD_DATA:
      return {
        ...state,
        [action.id]: {
          data: action.data,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

const FinVehicleSavedSearchCardsStateOpenSearch = {
  mountPoint: MOUNT_POINT,
  actionCreators: { fetchSavedSearchCardData },
  selectors: { getSavedSearchCardData },
  reducer: reducer,
};

export default FinVehicleSavedSearchCardsStateOpenSearch;
