import apiUrl from "api-url";
import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import {
  currentPositionCodesUrl,
  productTypesUrl,
  locationsUrl,
  lastMilestoneUrl,
  soldToUrl,
  finCodeUrl,
  endUserFinCodeUrl,
  originUrl,
  customerprovidedExteriorColorUrl,
  customerprovidedInteriorColorUrl,
  customerprovidedDoorTypeUrl,
  customerprovidedPowertrainUrl,
} from "modules/domain-data/VinViewDomainData";
import { OrganizationType } from "shared/constants/organization.const";
import VinViewSearchBarState from "pages/vinview/redux/VinViewSearchBarState";

export const dealerOrgOptionsState = buildAsyncFilterOptionsState({
  topic: "dealerOrgFilter",
  url: apiUrl("/iam/organizations"),
  queryParam: "everything",
  additionalParams: {
    orgType: OrganizationType.DEALER,
    paginate: true,
  },
  getResponseData: (data) => data?.response ?? [],
  transformResult: (org) => ({
    label: org.org_name,
    value: org.organization_id,
  }),
});

export const currentPositionsCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "vinViewCurrentPositionCodeFilter",
  getUrl: (solutionId, state) => {
    const dealerOrgId =
      VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
    return currentPositionCodesUrl(dealerOrgId);
  },
  getResponseData: (data) => data?.currentPositionCodes ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.id,
  }),
});

export const productTypeOptionsState = buildAsyncFilterOptionsState({
  topic: "vinViewProductTypeFilter",
  getUrl: (solutionId, state) => {
    const dealerOrgId =
      VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
    return productTypesUrl(dealerOrgId);
  },
  getResponseData: (data) => {
    return data?.productTypes ?? [];
  },
  transformResult: (option) => ({ label: option, value: option }),
});

export const vinViewLocationsOptionsState = buildAsyncFilterOptionsState({
  topic: "vinViewLocationsFilter",
  getUrl: (solutionId, state) => {
    const dealerOrgId =
      VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
    return locationsUrl(dealerOrgId);
  },
  getResponseData: (data) => {
    return data?.vinviewLocations ?? [];
  },
  additionalParams: {
    verbose: false,
  },
  additionalQueryOnlyParams: {
    everythingRestrict: "code,name",
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
  transformResult: (option) => ({
    label: `${option.name} (${option.code})`,
    value: option.code,
    code: option.code,
    name: option.name,
  }),
});

export const vinViewLastMilestoneOptionsState = buildAsyncFilterOptionsState({
  topic: "lastMilestoneVinViewFilter",
  getUrl: (solutionId, state) => {
    const dealerOrgId =
      VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
    return lastMilestoneUrl(dealerOrgId);
  },
  getResponseData: (data) => {
    return data?.lastMilestone ?? [];
  },
  transformResult: (option) => ({
    label: `${option.codeDescription} (${option.code})`,
    value: `${option.codeDescription} (${option.code})`,
  }),
});

export const vinViewSoldToOptionsState = buildAsyncFilterOptionsState({
  topic: "vinViewSoldToFilter",
  getUrl: (solutionId, state) => {
    const dealerOrgId =
      VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
    return soldToUrl(dealerOrgId);
  },
  getResponseData: (data) => {
    return data?.["ref:soldToDealer"] ?? [];
  },
  transformResult: (result) => {
    const label = `(${result.sold_to_code}) ${
      result.sold_to_name ? result.sold_to_name : ""
    }`;
    return { label: label, value: result.sold_to_code };
  },
});

// export const customerProvidedExteriorColorOptionsState = buildAsyncFilterOptionsState({
//   topic: "vinViewCustomerProvidedExteriorColorFilter",
//   getUrl: (solutionId, state) => {
//     const dealerOrgId = VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
//     return customerprovidedExteriorColorUrl(dealerOrgId);
//   },
//   getResponseData: (data) => {
//     return data?.["ref:CustomerProvided-ExteriorColor"] ?? [];
//   },
//   transformResult: (result) => {
//     return { label: result, value: result };
//   },
// });

export const vinViewFinCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "vinViewFinCodeFilter",
  getUrl: (solutionId, state) => {
    const dealerOrgId =
      VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
    return finCodeUrl(dealerOrgId);
  },
  getResponseData: (data) => {
    return data?.["ref:FinCode"] ?? [];
  },
  transformResult: (result) => {
    const label = `${result.name ? result.name : ""} (${result.code})`;
    return { label: label, value: result.code };
  },
});

export const vinViewEndUserFinCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "vinViewEndUserFinCodeFilter",
  getUrl: (solutionId, state) => {
    const dealerOrgId =
      VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
    return endUserFinCodeUrl(dealerOrgId);
  },
  getResponseData: (data) => {
    return data?.["ref:EndUserFinCode"] ?? [];
  },
  transformResult: (result) => {
    const label = `${result.name ? result.name : ""} (${result.code})`;
    return { label: label, value: result.code };
  },
});

export const vinViewOriginOptionsState = buildAsyncFilterOptionsState({
  topic: "originVinViewFilter",
  getUrl: (solutionId, state) => {
    const dealerOrgId =
      VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
    return originUrl(dealerOrgId);
  },
  getResponseData: (data) => {
    // update data after update link change
    return data?.vinviewOriginLocations ?? [];
  },
  additionalParams: {
    verbose: false,
  },
  additionalQueryOnlyParams: {
    everythingRestrict: "code,name",
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
  transformResult: (option) => ({
    label: `${option.name} (${option.code})`,
    value: `${option.name} (${option.code})`,
    code: option.code,
    name: option.name,
  }),
});
export const vinViewCustomerProvidedExteriorColorOptionsState =
  buildAsyncFilterOptionsState({
    topic: "vinViewCustomerProvidedExteriorColorFilter",
    getUrl: (solutionId, state) => {
      const dealerOrgId =
        VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
      return customerprovidedExteriorColorUrl(dealerOrgId);
    },
    getResponseData: (data) => {
      return data?.["ref:CustomerProvided-ExteriorColor"] ?? [];
    },
    transformResult: (result) => {
      const label = `${result.name ? result.name : ""} ${result.code}`;
      return { label: label, value: result.code };
    },
  });

export const vinViewCustomerProvidedInteriorColorOptionsState =
  buildAsyncFilterOptionsState({
    topic: "vinViewCustomerProvidedInteriorColorFilter",
    getUrl: (solutionId, state) => {
      const dealerOrgId =
        VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
      return customerprovidedInteriorColorUrl(dealerOrgId);
    },
    getResponseData: (data) => {
      return data?.["ref:CustomerProvided-InteriorColor"] ?? [];
    },
    transformResult: (result) => {
      const label = `${result.name ? result.name : ""} ${result.code}`;
      return { label: label, value: result.code };
    },
  });

export const vinViewCustomerProvidedDoorTypeOptionsState =
  buildAsyncFilterOptionsState({
    topic: "vinViewCustomerProvidedDoorTypeFilter",
    getUrl: (solutionId, state) => {
      const dealerOrgId =
        VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
      return customerprovidedDoorTypeUrl(dealerOrgId);
    },
    getResponseData: (data) => {
      return data?.["ref:CustomerProvided-DoorType"] ?? [];
    },
    transformResult: (result) => {
      const label = `${result.name ? result.name : ""} ${result.code}`;
      return { label: label, value: result.code };
    },
  });

export const vinViewCustomerProvidedPowertrainOptionsState =
  buildAsyncFilterOptionsState({
    topic: "vinViewCustomerProvidedPowertrainFilter",
    getUrl: (solutionId, state) => {
      const dealerOrgId =
        VinViewSearchBarState.selectors.getSelectedDealerOrgId(state);
      return customerprovidedPowertrainUrl(dealerOrgId);
    },
    getResponseData: (data) => {
      return data?.["ref:CustomerProvided-Powertrain"] ?? [];
    },
    transformResult: (result) => {
      const label = `${result.name ? result.name : ""} ${result.code}`;
      return { label: label, value: result.code };
    },
  });
