/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

import logo from "assets/logos/fv_logo.svg";
import logoPoweredBy from "assets/logos/fv_powered.svg";
import { Icon, IconType } from "components/atoms/Icon.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { TextInput } from "components/atoms/TextInput.atom";
import { Button } from "components/atoms/Button.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";

export const Subscribe = ({
  guid,
  category,
  resetSubscribe,
  fetchSubscription,
  subscription,
  isFetchingSubscription,
  isFetchSubscriptionError,
  subscriptionErrorMessage,
  subscribe,
  isSubscribeComplete,
  isSubscribing,
  isSubscribeError,
  subscribeErrorMessage,
  fetchOrganizationImages,
  organizationImageConfig,
}) => {
  debugger;
  const { t } = useTranslation("appnav");

  useSetTitleOnMount(t("appnav:Subscribe"));

  useTrackWithMixpanelOnce("Viewed Page: Subscribe");

  const [hasData, setHasData] = useState(true);

  useEffect(() => {
    // Automatically reset Subscribe values on page load
    resetSubscribe();

    // If we have a guid, retrieve subscription information
    if (guid) {
      fetchSubscription(guid);
    } else {
      setHasData(false);
    }

    if (!category) {
      setHasData(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startSubscribe = () => {
    subscribe(guid, category);
  };

  useEffect(() => {
    if (subscription) {
      // Fetch organization images:
      fetchOrganizationImages(subscription?.organization_id);

      // Ensure email/phone is present based on category
      if (category === "email" && _.isNil(subscription.recipient_email)) {
        setHasData(false);
      } else if (category === "phone" && _.isNil(subscription.mobile_number)) {
        setHasData(false);
      }

      // Ensure a valid reference ID is provided
      if (_.isNil(subscription.reference_id)) {
        setHasData(false);
      }
    }
  }, [subscription, category]);

  let isBranded = false;
  let logoSrc = logo;
  if (organizationImageConfig?.logoPath) {
    isBranded = true;
    logoSrc = organizationImageConfig.logoPath;
  }

  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f0f0f0",
      }}
    >
      <Icon
        type={IconType.LocalImage}
        src={logoSrc}
        style={{ height: "87px", width: "383px" }}
      />
      {isBranded && (
        <Icon
          type={IconType.LocalImage}
          src={logoPoweredBy}
          style={{ height: "87px", width: "383px" }}
        />
      )}
      <div
        css={{
          width: 400,
          display: "flex",
          flexDirection: "column",
          border: "1px solid #ddd",
          backgroundColor: "#fff",
          borderRadius: 10,
          padding: 20,
        }}
      >
        <Text bold size={FontSize.size28}>
          {t("appnav:Subscribe to Alerts")}
        </Text>
        {isFetchingSubscription ? (
          <div>
            <Icon src={faSpinner} spin />
            <Text css={{ marginLeft: 5 }}>
              {t("appnav:Retrieving your alert subscription...")}
            </Text>
          </div>
        ) : null}
        {!hasData ? (
          <Alert
            css={{ marginTop: 0, marginBottom: 0 }}
            variant={AlertVariant.Danger}
            show={true}
          >
            <Text bold block>
              {t("appnav:Something Went Wrong")}
            </Text>
            <Text block>
              {t("appnav:Invalid Alert Subscription options provided.")}
            </Text>
          </Alert>
        ) : null}
        {hasData && subscription && !isFetchingSubscription ? (
          !isSubscribeComplete ? (
            <React.Fragment>
              <Text css={{ marginTop: 10 }}>
                {t(
                  "appnav:Subscribe to receive [[[category]]] from FreightVerify regarding [[[service]]] [[[serviceId]]].",
                  {
                    serviceId: subscription.reference_id,
                    category:
                      category === "email"
                        ? t("appnav:emails")
                        : t("appnav:text messages"),
                    service:
                      subscription.reference_type === "vin"
                        ? t("appnav:VIN")
                        : subscription.reference_type === "Part View"
                        ? t("appnav:Package Number")
                        : t("appnav:Shipment ID"),
                  },
                )}
              </Text>
              <Text color={"#aaa"} css={{ marginTop: 15 }}>
                {category === "email"
                  ? t("appnav:Email Address")
                  : t("appnav:Phone Number")}
              </Text>
              <TextInput
                value={
                  category === "email"
                    ? subscription.recipient_email
                    : subscription.mobile_number
                }
                disabled
              />
              <Button
                css={{ marginTop: "0.5em" }}
                onClick={startSubscribe}
                disabled={isSubscribing || isSubscribeComplete}
              >
                {isSubscribing ? (
                  <Icon src={faSpinner} spin size={FontSize.size16} />
                ) : (
                  t("appnav:Submit")
                )}
              </Button>
            </React.Fragment>
          ) : (
            <Alert
              css={{ marginBottom: 0 }}
              variant={AlertVariant.Success}
              show={isSubscribeComplete}
            >
              <Text bold block>
                {t("appnav:Successfully Subscribed")}
              </Text>
              <Text block>
                {t(
                  "appnav:You have successfully subscribed to alerts from [[[service]]] [[[serviceId]]]",
                  {
                    serviceId: subscription.reference_id,
                    service:
                      subscription.reference_type === "vin"
                        ? t("appnav:VIN")
                        : subscription.reference_type === "Part View"
                        ? t("appnav:Package Number")
                        : t("appnav:Shipment ID"),
                  },
                )}
              </Text>
            </Alert>
          )
        ) : null}
        {isSubscribeError || isFetchSubscriptionError ? (
          <Alert
            css={{ marginTop: 25, marginBottom: 0 }}
            variant={AlertVariant.Danger}
            show={isSubscribeError || isFetchSubscriptionError}
          >
            <Text bold block>
              {t("appnav:Something Went Wrong")}
            </Text>
            <Text block>
              {t(
                "appnav:We could not subscribe you at this time. Please try again later. See below for more information.",
              )}
            </Text>
            <Text block italic>
              {subscribeErrorMessage}
              {subscriptionErrorMessage}
            </Text>
          </Alert>
        ) : null}
      </div>
    </div>
  );
};

Subscribe.propTypes = {
  guid: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  resetSubscribe: PropTypes.func.isRequired,
  fetchSubscription: PropTypes.func.isRequired,
  subscription: PropTypes.object,
  isFetchingSubscription: PropTypes.bool,
  isFetchSubscriptionError: PropTypes.bool,
  subscriptionErrorMessage: PropTypes.string,
  subscribe: PropTypes.func.isRequired,
  isSubscribeComplete: PropTypes.bool,
  isSubscribing: PropTypes.bool,
  isSubscribeError: PropTypes.bool,
  subscribeErrorMessage: PropTypes.string,
  fetchOrganizationImages: PropTypes.func,
  organizationImageConfig: PropTypes.shape({
    logoPath: PropTypes.string,
  }),
};
