/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { flattenShipmentStops } from "modules/shipment-detail/trip-utils";
import EndToEndTabsPanel from "./EndToEndTabsPanel";
import ShipmentTimelineContainer from "../shipment-detail-styled-components/ShipmentTimelineContainer";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import ShipmentDetailsCollapsible from "../shipment-detail-styled-components/ShipmentDetailsCollapsible";
import {
  ArrivalAndDeparturePanelGroup,
  getLocationDataFromShipments,
} from "components/organisms/ArrivalAndDeparturePanelGroup.organism";
import ShipmentWatchToggle from "../shipment-detail-styled-components/ShipmentWatchToggle";
import { CommentFeed } from "components/organisms/CommentFeed.organism";
import { batchCsvShipmentExample } from "components/search-bar/batch-comment-csv-data";
import { ActionNav } from "../ActionNav";
import { useShipmentTranslation } from "shared/hooks/useShipmentTranslation";
import { getTrailerAndEquipmentNumberFromChildShipments } from "../ShipmentUtils";

export const EndToEndTabPanel = (props) => {
  const { getTranslatedStatus } = useShipmentTranslation();

  const {
    t,
    parentShipment,
    childShipments,
    lads,
    addCoordinate,
    clearCoordinatesByType,
    selectedMapCoordinate,
    toggleWatchShipment,
    showComments,
    commentShareableWithOrgId,
    fetchComments,
    isFetchingComments,
    comments,
    addComment,
    cancelAddComment,
    updateComment,
    cancelUpdateComment,
    markCommentsRead,
    addBatchComments,
    clearBatchComments,
    isBatchCommentInProgress,
    isBatchCommentSuccessful,
    isBatchCommentFailed,
    shipperOrgId,
    fetchNotification,
    enableActionMenu,
    enableAlertMeAction,
    enableShipmentEventsAction,
    enableCancelShipmentAction,
    enableReportDelayAction,
    enableClearDelayAction,
    enableMobileTrackingAction,
    eventHandler,
    shipmentSubscription,
    isShipmentSubscriptionLoading,
    organization,
  } = props;
  const tripLegsEndToEnd = flattenShipmentStops(
    t,
    parentShipment,
    childShipments,
    lads,
  );
  const firstShipmentId = parentShipment.child_shipments[0].shipment_id;
  const firstShipment = childShipments[firstShipmentId];

  const tripProgress =
    tripLegsEndToEnd.length > 0 &&
    tripLegsEndToEnd.filter((t) => t.active === true).length > 0
      ? tripLegsEndToEnd.filter((t) => t.active === true)[0].progress
      : 0;

  const { origin, destination, frozenEta, isFvEta } =
    getLocationDataFromShipments(
      parentShipment.child_ids?.map((id) => childShipments[id]),
    );

  // H1-815: Use parent shipment destination ETA for ultimate destination ETA
  let calculatedEta = parentShipment.destination_eta;
  // H1-4822: Override the ETA if we have a frozen one.
  if (!_.isNil(frozenEta)) {
    calculatedEta = frozenEta;
  }

  // Get Trailer and Equipment numbers
  // H1-3859: Only show unique values on the UI.
  //   To see all, the user can look at the References tab.
  const trailerAndEquipmentNumbers = _.uniq(
    getTrailerAndEquipmentNumberFromChildShipments(childShipments ?? {}),
  );

  const activeLegIdx = _.findIndex(tripLegsEndToEnd, ["active", true]);

  return (
    <div css={{ overflow: "auto" }}>
      <ShipmentDetailsCollapsible
        shipmentId={parentShipment.creator_shipment_id}
        assetId={null}
        mode={parentShipment.mode_name}
        showMadChiclet={false}
        status={getTranslatedStatus(parentShipment.active_status_ng)}
        shipperName={firstShipment ? firstShipment.shipper_name : ""}
        carrierName={null}
        carrierScac={null}
        trailerAndEquipmentNumbers={trailerAndEquipmentNumbers}
        exception={parentShipment.active_exception_ng}
        isBackOrder={parentShipment?.shipment_details?.is_back_order}
        isOffRoute={parentShipment?.shipment_details?.is_off_route}
        extraHeaderContent={
          <div css={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {toggleWatchShipment ? (
              <ShipmentWatchToggle
                watch={parentShipment.watched}
                toggleWatchShipment={toggleWatchShipment}
                isShowingComments={showComments}
              />
            ) : null}

            {enableActionMenu ? (
              <ActionNav
                shipment={parentShipment}
                eventHandler={eventHandler}
                enableAlertMeAction={enableAlertMeAction}
                enableShipmentEventsAction={enableShipmentEventsAction}
                enableCancelShipmentAction={enableCancelShipmentAction}
                enableReportDelayAction={enableReportDelayAction}
                enableClearDelayAction={enableClearDelayAction}
                enableMobileTrackingAction={enableMobileTrackingAction}
                shipmentSubscription={shipmentSubscription}
                isShipmentSubscriptionLoading={isShipmentSubscriptionLoading}
              />
            ) : null}
          </div>
        }
        etaTimeCategory={parentShipment.eta_time_category}
      />
      <ArrivalAndDeparturePanelGroup
        origin={origin}
        destination={destination}
        eta={calculatedEta}
        etaMessage={
          calculatedEta === "TBD"
            ? t("shipment-details:Shipment ETA is expired")
            : null
        }
        etaWarningMessage={
          !_.isNil(frozenEta) &&
          parentShipment.active_exception_ng?.includes("Carrier Delayed")
            ? t(
                "shipment-details:Carrier has indicated a delay. This ETA is provided by the Carrier.",
              )
            : null
        }
        isFvEta={isFvEta}
        visibleFields={{
          availableForUnload: firstShipment?.mode_name === "Rail",
        }}
        availableForUnloadTs={firstShipment?.available_for_unload_ts}
        style={{ margin: "1em" }}
      >
        <ArrivalAndDeparturePanelGroup.SubHeaders>
          <ArrivalAndDeparturePanelGroup.RouteSubHeader
            routeId={firstShipment?.route_number}
            // ISS-9715: Hiding On Time for Cummins org
            onTimePercentage={
              organization?.fv_id !== "CUMMINS"
                ? firstShipment?.on_time_percentage
                : null
            }
          />
          <ArrivalAndDeparturePanelGroup.TripPlanIdSubHeader
            tripPlanId={firstShipment?.trip_plan_number}
          />
        </ArrivalAndDeparturePanelGroup.SubHeaders>
      </ArrivalAndDeparturePanelGroup>

      {showComments ? (
        <CommentFeed
          commentShareableWithOrgId={commentShareableWithOrgId}
          fetchComments={fetchComments}
          isFetchingComments={isFetchingComments}
          comments={comments}
          addComment={addComment}
          cancelAddComment={cancelAddComment}
          updateComment={updateComment}
          cancelUpdateComment={cancelUpdateComment}
          markCommentsRead={markCommentsRead}
          addBatchComments={addBatchComments}
          clearBatchComments={clearBatchComments}
          isBatchCommentInProgress={isBatchCommentInProgress}
          isBatchCommentSuccessful={isBatchCommentSuccessful}
          isBatchCommentFailed={isBatchCommentFailed}
          batchCsvExample={batchCsvShipmentExample}
          batchImportLabel={t("shipment-details:Shipment ID")}
          shipmentOrgId={parentShipment.creator_shipment_id}
          style={{ margin: "1em" }}
          fetchNotification={fetchNotification}
          shipperOrgId={shipperOrgId}
        />
      ) : null}

      <ShipmentTimelineContainer
        shipment={{
          shipment_stops: tripLegsEndToEnd,
          active_exceptions_ng: parentShipment.active_exception_ng,
          current_location: {
            distance_progress: `${tripProgress}%`,
            active_leg_idx: activeLegIdx,
          },
        }}
        t={t}
      />
      <div css={{ padding: "1em", marginTop: "4.5em" }}>
        <EndToEndTabsPanel
          parentShipment={parentShipment}
          childShipments={childShipments ?? {}}
          addCoordinate={addCoordinate}
          clearCoordinatesByType={clearCoordinatesByType}
          selectedMapCoordinate={selectedMapCoordinate}
        />
      </div>
    </div>
  );
};

EndToEndTabPanel.propTypes = {
  t: PropTypes.func,
  parentShipment: PropTypes.object,
  childShipments: PropTypes.object,
  lads: PropTypes.object,
  addCoordinate: PropTypes.func.isRequired,
  clearCoordinatesByType: PropTypes.func.isRequired,
  selectedMapCoordinate: PropTypes.object,
  toggleWatchShipment: PropTypes.func,
  showComments: PropTypes.bool,
  fetchComments: PropTypes.func,
  commentShareableWithOrgId: PropTypes.number,
  isFetchingComments: PropTypes.bool,
  comments: PropTypes.shape({
    totalPages: PropTypes.number,
    totalCount: PropTypes.number,
    totalCountUnread: PropTypes.number,
    data: PropTypes.array,
  }),
  addComment: PropTypes.func.isRequired,
  cancelAddComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  cancelUpdateComment: PropTypes.func.isRequired,
  markCommentsRead: PropTypes.func.isRequired,
  addBatchComments: PropTypes.func.isRequired,
  clearBatchComments: PropTypes.func.isRequired,
  isBatchCommentInProgress: PropTypes.bool.isRequired,
  isBatchCommentSuccessful: PropTypes.bool.isRequired,
  isBatchCommentFailed: PropTypes.bool.isRequired,
  shipperOrgId: PropTypes.number,
  fetchNotification: PropTypes.func.isRequired,
  eventHandler: PropTypes.func,
  shipmentSubscription: PropTypes.object,
  isShipmentSubscriptionLoading: PropTypes.bool,
  enableActionMenu: PropTypes.bool,
  enableAlertMeAction: PropTypes.bool,
  enableShipmentEventsAction: PropTypes.bool,
  enableCancelShipmentAction: PropTypes.bool,
  enableReportDelayAction: PropTypes.bool,
  enableClearDelayAction: PropTypes.bool,
  enableMobileTrackingAction: PropTypes.bool,
  organization: PropTypes.object,
};

export default withTranslation("shipment-detail")(EndToEndTabPanel);
