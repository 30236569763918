import axios from "axios";
import apiUrl from "api-url";
import chainReducers from "vendor/signal-utils/chain-reducers";

const STORE_MOUNT_POINT = "partViewDeliverPackage";

// Actions
const getScopedActionName = (actionName) =>
  `${STORE_MOUNT_POINT}/${actionName}`;

const SET_PACKAGE_DELIVERY_EVENT = getScopedActionName(
  "SET_PACKAGE_DELIVERY_EVENT",
);
const RECEIVE_SET_PACKAGE_DELIVERY_EVENT = getScopedActionName(
  "RECEIVE_SET_PACKAGE_DELIVERY_EVENT",
);
const SET_PACKAGE_DELIVERY_EVENT_FAILED = getScopedActionName(
  "SET_PACKAGE_DELIVERY_EVENT_FAILED",
);
const RESET_SET_PACKAGE_DELIVERY_EVENT = getScopedActionName(
  "RESET_SET_PACKAGE_DELIVERY_EVENT",
);

function setPackageDeliveryEvent(deliveryData) {
  if (deliveryData) {
    const url = apiUrl(
      `/partview/api/package-container/${deliveryData.packageContainerId}/event`,
    );

    return (dispatch) => {
      dispatch({
        type: SET_PACKAGE_DELIVERY_EVENT,
        payload: { data: deliveryData },
      });

      const requestData = {
        organizationFvId: deliveryData.organizationFvId,
        locationCode: deliveryData.locationCode,
        latitude: deliveryData.latitude,
        longitude: deliveryData.longitude,
        eventCode: deliveryData.eventCode,
        eventType: deliveryData.eventType,
        isException: deliveryData.isException,
        eventTs: deliveryData.deliveryDateTime?.toUTCString(),
      };

      return axios
        .post(url, requestData, {
          headers: { accept: "application/json;version=REQUEST_RESPONSE" },
        })
        .then((response) => {
          dispatch({
            type: RECEIVE_SET_PACKAGE_DELIVERY_EVENT,
            // Force the new comment to be marked as read
            payload: { data: response.data },
          });
        })
        .catch((err) => {
          dispatch({
            type: SET_PACKAGE_DELIVERY_EVENT_FAILED,
            payload: { data: deliveryData },
          });
        });
    };
  }
}

function resetSetPackageDeliveryEvent() {
  return (dispatch) => {
    dispatch({
      type: RESET_SET_PACKAGE_DELIVERY_EVENT,
    });
  };
}

// Selectors
const getPackageDeliveryEvent = (state) =>
  state[STORE_MOUNT_POINT].packageDeliveryEvent ?? null;

const getIsPackageDeliveryEventLoading = (state) =>
  state[STORE_MOUNT_POINT].isPackageDeliveryEventLoading ?? false;

const getPackageDeliveryEventRequestError = (state) =>
  state[STORE_MOUNT_POINT].packageDeliveryEventRequestError ?? false;

const getPackageDeliveryEventRequestSuccess = (state) =>
  state[STORE_MOUNT_POINT].packageDeliveryEventRequestSuccess ?? false;

// Reducer
const initialState = {
  packageDeliveryEvent: null,
  isPackageDeliveryEventLoading: false,
  packageDeliveryEventRequestError: false,
  packageDeliveryEventRequestSuccess: false,
};

const partViewDeliverPackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PACKAGE_DELIVERY_EVENT:
      return {
        ...state,
        isPackageDeliveryEventLoading: true,
        packageDeliveryEventRequestError: false,
        packageDeliveryEventRequestSuccess: false,
        packageDeliveryEvent: action.payload.data,
      };

    case RECEIVE_SET_PACKAGE_DELIVERY_EVENT:
      return {
        ...state,
        isPackageDeliveryEventLoading: false,
        packageDeliveryEventRequestError: false,
        packageDeliveryEventRequestSuccess: true,
        packageDeliveryEvent: action.payload.data,
      };

    case SET_PACKAGE_DELIVERY_EVENT_FAILED:
      return {
        ...state,
        isPackageDeliveryEventLoading: false,
        packageDeliveryEventRequestError: true,
        packageDeliveryEventRequestSuccess: false,
        packageDeliveryEvent: initialState.packageDeliveryEvent,
      };

    case RESET_SET_PACKAGE_DELIVERY_EVENT:
      return {
        ...state,
        isPackageDeliveryEventLoading:
          initialState.isPackageDeliveryEventLoading,
        packageDeliveryEventRequestError:
          initialState.packageDeliveryEventRequestError,
        packageDeliveryEventRequestSuccess:
          initialState.packageDeliveryEventRequestSuccess,
        packageDeliveryEvent: initialState.packageDeliveryEvent,
      };

    default:
      return state;
  }
};

const PartViewDeliverPackageState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    setPackageDeliveryEvent,
    resetSetPackageDeliveryEvent,
  },
  selectors: {
    getPackageDeliveryEvent,
    getIsPackageDeliveryEventLoading,
    getPackageDeliveryEventRequestError,
    getPackageDeliveryEventRequestSuccess,
  },
  reducer: chainReducers([partViewDeliverPackageReducer]),
};

export default PartViewDeliverPackageState;
