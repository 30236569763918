import { usePartViewExceptionLabel } from "../components/hooks/usePartViewExceptionLabel";
import { IoMdTimer } from "react-icons/io";
import {
  faArrowDown,
  faExclamationSquare,
  faBoneBreak,
  faRoute,
  faRecycle,
  faCompass,
  faSendBack,
} from "@fortawesome/pro-solid-svg-icons";
import { faTruckContainer } from "@fortawesome/pro-light-svg-icons";
import { faHandHoldingBox } from "@fortawesome/pro-regular-svg-icons";
import { IconType } from "components/atoms/enums";
import Colors from "styles/colors";

export const PartViewExceptionType = {
  BACK_ORDER: "BO",
  SHORT: "SH",
  HELD: "HE",
  LOST: "LS",
  MISROUTED: "MR",
  RECYCLED: "RE",
  MISSING: "MS",
  DAMAGED: "DM",
  BEHIND_SCHEDULE: "BS",
  DELAYED: "DELAYED",
};

export const PartViewDamagedCategory = {
  PACKAGE_CRUSHED: "C",
  PACAKGE_PUNCTURED: "P",
  PACKAGE_WET: "S",
  PART_BROKEN: "B",
  PART_BENT: "N",
  PART_SCRATCHED: "X",
  NONE: "Z",
};

export const getExceptionIconColor = (code) => {
  switch (code) {
    case PartViewExceptionType.BEHIND_SCHEDULE:
      return Colors.exceptions.BEHIND_SCHEDULE;
    case PartViewExceptionType.MISSING:
      return Colors.exceptions.MISSING;
    default:
      return Colors.exceptions.DEFAULT;
  }
};

export const getExceptionChartColor = (code) => {
  switch (code) {
    case PartViewExceptionType.BACK_ORDER:
      return Colors.exceptions.BACK_ORDER;
    case PartViewExceptionType.SHORT:
      return Colors.exceptions.SHORT;
    case PartViewExceptionType.HELD:
      return Colors.exceptions.HELD;
    case PartViewExceptionType.LOST:
      return Colors.exceptions.LOST;
    case PartViewExceptionType.MISROUTED:
      return Colors.exceptions.MISROUTED;
    case PartViewExceptionType.RECYCLED:
      return Colors.exceptions.RECYCLED;
    case PartViewExceptionType.DELAYED:
      return Colors.exceptions.DELAYED;
    case PartViewExceptionType.BEHIND_SCHEDULE:
      return Colors.exceptions.BEHIND_SCHEDULE;
    case PartViewExceptionType.MISSING:
      return Colors.exceptions.PARTVIEW_MISSING; // color for missing widget in partview
    default:
      return Colors.exceptions.DEFAULT;
  }
};

export const getIconData = (code) => {
  const iconColor = getExceptionIconColor(code);

  switch (code) {
    case PartViewExceptionType.SHORT:
      return {
        type: IconType.FontAwesome,
        src: faArrowDown,
        color: iconColor,
      };
    case PartViewExceptionType.MISSING:
      return {
        type: IconType.FontAwesome,
        src: faExclamationSquare,
        color: iconColor,
      };
    case PartViewExceptionType.HELD:
      return {
        type: IconType.FontAwesome,
        src: faHandHoldingBox,
        color: iconColor,
      };
    case PartViewExceptionType.DAMAGED:
      return {
        type: IconType.FontAwesome,
        src: faBoneBreak,
        color: iconColor,
      };
    case PartViewExceptionType.MISROUTED:
      return {
        type: IconType.FontAwesome,
        src: faRoute,
        color: iconColor,
      };
    case PartViewExceptionType.RECYCLED:
      return {
        type: IconType.FontAwesome,
        src: faRecycle,
        color: iconColor,
      };
    case PartViewExceptionType.LOST:
      return {
        type: IconType.FontAwesome,
        src: faCompass,
        color: iconColor,
      };
    case PartViewExceptionType.BEHIND_SCHEDULE:
      return {
        type: IconType.ReactIcons,
        src: IoMdTimer,
        color: iconColor,
      };
    case PartViewExceptionType.BACK_ORDER:
      return {
        type: IconType.FontAwesome,
        src: faSendBack,
        color: iconColor,
      };
    case "Delivered":
      return {
        type: IconType.FontAwesome,
        src: faTruckContainer,
        color: iconColor,
      };
    default:
      return null;
  }
};

/**
 *  Returns a list for PartView's ExceptionsPanel dashboard component
 *
 * e.g.
 * ```
 * <ExceptionsPanel
 *  exceptionGroups={[
 *    {
 *      title: "Exceptions",
 *      exceptions: [ ... ] // <- return of this function goes here
 *      isLoading: isLoading
 *    }
 *  ]}
 *  ...
 * />
 * ```
 *
 * @param {Array<{label: string, value: string}>} exceptions List of exception types
 * @param {{ active?: number, delivered?: number, exceptions?: { [reasonCode: string]: number }}} counts
 * Map of reasonCode (value) to count for each exception type (and "active" or "delivered")
 * @param {Function} t Translation function from i18next
 * @returns
 */
export const getExceptionsDataForWidget = (exceptions, counts = {}) => {
  if (!exceptions || !counts) {
    return [];
  }

  const widgetData = exceptions.map((exception) => {
    const reasonCode = exception.value;

    const count = counts.exceptions?.[reasonCode] ?? 0;
    const total = counts.active ?? 0;
    let percentage = 0;

    if (total > 0) {
      percentage = count / total;
      percentage *= 100;
    }

    return {
      name: exception.name,
      count: count,
      percentage: percentage.toFixed(2),
      icon: getIconData(reasonCode),
      reasonCode: reasonCode,
    };
  });

  return widgetData ?? [];
};

export const useExceptionFomattedDetail = (exceptions, totalActivePackage) => {
  const { getTranslatedExceptionNameByCode } = usePartViewExceptionLabel();
  if (!exceptions) {
    return [];
  }
  let totalActive = totalActivePackage;
  let totalExceptions = exceptions;

  let exceptionArr = Object.keys(totalExceptions)?.map((exception) => {
    let percentage = 0;
    if (totalActive > 0) {
      percentage = totalExceptions[exception] / totalActive;
      percentage *= 100;
    }
    return {
      name: getTranslatedExceptionNameByCode(exception),
      count: totalExceptions[exception],
      percentage: percentage.toFixed(2),
      icon: getIconData(exception),
      resonCode: exception,
    };
  });
  return exceptionArr;
};

export const getDeliveredDataForWidget = (totalActive, deliveredCount) => {
  const totalDeliveredCounts = deliveredCount ?? 0;
  const total = totalActive ?? 0;
  let percentage = 0;

  if (total > 0) {
    percentage = totalDeliveredCounts / total;
    percentage *= 100;
  }
  return percentage.toFixed(2);
};

export const packageDetailsWithFilteredExceptions = (result) => {
  if (result === null) {
    return;
  }

  let activeExceptionList = [];
  let exceptionList = [];
  if (result && result?.ActiveExceptionList?.length > 0) {
    activeExceptionList = result?.ActiveExceptionList?.filter(
      (exception) => exception.reasonCode !== "DM",
    );
  }
  if (result && result?.activeExceptions?.length > 0) {
    activeExceptionList = result?.activeExceptions?.filter(
      (exception) => exception.reasonCode !== "DM",
    );
  }
  if (result && result?.ExceptionList?.length > 0) {
    exceptionList = result?.ExceptionList?.filter(
      (exception) => exception.reasonCode !== "DM",
    );
  }
  return {
    ...result,
    ActiveExceptionList: activeExceptionList,
    ExceptionList: exceptionList,
  };
};

export const useFormattedExceptionCount = (exceptions) => {
  const { getTranslatedExceptionNameByCode } = usePartViewExceptionLabel();
  if (!exceptions) {
    return [];
  } else {
    const orderedExceptions = [
      PartViewExceptionType.BACK_ORDER,
      PartViewExceptionType.SHORT,
      PartViewExceptionType.HELD,
      PartViewExceptionType.LOST,
      PartViewExceptionType.MISROUTED,
      PartViewExceptionType.RECYCLED,
      PartViewExceptionType.MISSING,
      PartViewExceptionType.BEHIND_SCHEDULE,
      PartViewExceptionType.DELAYED,
    ];

    let exceptionArr = Object.values(orderedExceptions);
    const sortedExceptions = exceptionArr
      .filter((exception) => exceptions.hasOwnProperty(exception))
      .map((key) => ({
        type: getTranslatedExceptionNameByCode(key),
        reasonCode: key,
        count: exceptions[key] ? exceptions[key] : 0,
      }));
    return sortedExceptions;
  }
};
