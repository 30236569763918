import buildSavedSearchState from "components/saved-search/SavedSearchStateBuilder";
import { SEARCH_CATEGORIES_OPEN_SEARCH } from "pages/finishedvehicle/search/FinVehicleSearchCategoryDefsOpenSearch";
import apiUrl from "api-url";
import SearchState from "pages/finishedvehicle/redux/FinVehicleSearchBarStateOpenSearch";

const STORE_MOUNT_POINT = "fvSavedSearchOpenSearch";

export default buildSavedSearchState({
  topic: STORE_MOUNT_POINT,
  savedSearchUrl: apiUrl("/preferences-ng/search-history"),
  searchType: "ENTITY",
  searchCategories: SEARCH_CATEGORIES_OPEN_SEARCH,
  actions: {
    clearSearchFilters: SearchState.actionCreators.clearSearchFilters,
    setSearchCategory: SearchState.actionCreators.setSearchCategory,
    setSearchText: SearchState.actionCreators.setSearchText,
    setSearchFilter: SearchState.actionCreators.setSearchFilter,
  },
});
