import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import { buildLocationAsyncFilterOptionsState } from "pages/administration/location-management/utils/buildLocationAsyncFilterOptionsState";
import {
  currentPositionCodesUrl,
  productTypesUrl,
  lastMilestoneUrl,
  soldToUrl,
  finCodeUrl,
  endUserFinCodeUrl,
  dealerRegionUrl,
  dealerZoneUrl,
  dealerDistrictUrl,
  partnersUrl,
  originCountryUrl,
  destinationCountryUrl,
  exteriorColorUrl,
  interiorColorUrl,
  doorsUrl,
  powertrainUrl,
  drivetrainUrl,
  bodyStyleUrl,
  fuelTypeUrl,
  yearUrl,
  makeUrl,
  modelUrl,
  trimUrl,
  originUrl,
  destinationUrl,
  carrierUrl,
  productTypeUrl,
  lastMilestoneUrlOpenSearch,
  soldToUrlOpenSearch,
  finCodeUrlOpenSearch,
  endUserFinCodeUrlOpenSearch,
  partnerUrlOpenSearch,
  shipmentStatusUrlOpenSearch,
  itssIdUrlOpenSearch,
  spotBuyAuthUrlOpenSearch,
  originCountryUrlOpenSearch,
  destinationCountryUrlOpenSearch,
  dealerRegionUrlOpenSearch,
  dealerZoneUrlOpenSearch,
  dealerDistrictUrlOpenSearch,
  currentLocationUrlOpenSearch,
  routeIdUrlOpenSearch,
} from "modules/domain-data/FinVehicleDomainData";
import { PaginationType } from "components/search-bar/enums.utils";

export const originOptionsState = buildLocationAsyncFilterOptionsState({
  topic: "originShipmentFilter",
  additionalParams: {
    verbose: false,
  },
  additionalQueryOnlyParams: {
    everythingRestrict: "code,name",
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
});

export const originOptionsStateOpenSearch = buildAsyncFilterOptionsState({
  topic: "originShipmentFilterOpenSearch",
  getUrl: originUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.key,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const destinationOptionsStateOpenSearch = buildAsyncFilterOptionsState({
  topic: "destinationFilterOpenSearch",
  getUrl: destinationUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.key,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const carrierOptionsStateOpenSearch = buildAsyncFilterOptionsState({
  topic: "carrierFilterOpenSearch",
  getUrl: carrierUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.key,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const productTypeOptionsStateOpenSearch = buildAsyncFilterOptionsState({
  topic: "productTypeFilterOpenSearch",
  getUrl: productTypeUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.key,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const lastMilestoneOptionsStateOpenSearch = buildAsyncFilterOptionsState(
  {
    topic: "lastMilestoneFilterOpenSearch",
    getUrl: lastMilestoneUrlOpenSearch,
    getResponseData: (data) => data?.data ?? [],
    transformResult: (result) => ({
      label: result.value,
      value: result.key,
    }),
    paginationType: PaginationType.OPEN_SEARCH,
    methodType: "POST",
  },
);

export const soldToOptionsStateOpenSearch = buildAsyncFilterOptionsState({
  topic: "soldToFilterOpenSearch",
  getUrl: soldToUrlOpenSearch,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.key,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const finCodeOptionsStateOpenSearch = buildAsyncFilterOptionsState({
  topic: "finCodeFilterOpenSearch",
  getUrl: finCodeUrlOpenSearch,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.key,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const endUserFinCodeOptionsStateOpenSearch =
  buildAsyncFilterOptionsState({
    topic: "endUserFinCodeFilterOpenSearch",
    getUrl: endUserFinCodeUrlOpenSearch,
    getResponseData: (data) => data?.data ?? [],
    transformResult: (result) => ({
      label: result.value,
      value: result.key,
    }),
    paginationType: PaginationType.OPEN_SEARCH,
    methodType: "POST",
  });

export const partnerOptionsStateOpenSearch = buildAsyncFilterOptionsState({
  topic: "partnerFilterOpenSearch",
  getUrl: partnerUrlOpenSearch,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.key,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const shipmentStatusOptionsStateOpenSearch =
  buildAsyncFilterOptionsState({
    topic: "shipmentStatusFilterOpenSearch",
    getUrl: shipmentStatusUrlOpenSearch,
    getResponseData: (data) => data?.data ?? [],
    transformResult: (result) => ({
      label: result.value,
      value: result.key,
    }),
    paginationType: PaginationType.OPEN_SEARCH,
    methodType: "POST",
  });

export const itssIdOptionsStateOpenSearch = buildAsyncFilterOptionsState({
  topic: "itssIdFilterOpenSearch",
  getUrl: itssIdUrlOpenSearch,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.key,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const spotBuyAuthOptionsStateOpenSearch = buildAsyncFilterOptionsState({
  topic: "spotBuyAuthFilterOpenSearch",
  getUrl: spotBuyAuthUrlOpenSearch,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.key,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const originCountryOptionsStateOpenSearch = buildAsyncFilterOptionsState(
  {
    topic: "originCountryFilterOpenSearch",
    getUrl: originCountryUrlOpenSearch,
    getResponseData: (data) => data?.data ?? [],
    transformResult: (result) => ({
      label: result.value,
      value: result.key,
    }),
    paginationType: PaginationType.OPEN_SEARCH,
    methodType: "POST",
  },
);

export const destinationCountryOptionsStateOpenSearch =
  buildAsyncFilterOptionsState({
    topic: "destinationCountryUrlOpenSearchFilterOpenSearch",
    getUrl: destinationCountryUrlOpenSearch,
    getResponseData: (data) => data?.data ?? [],
    transformResult: (result) => ({
      label: result.value,
      value: result.key,
    }),
    paginationType: PaginationType.OPEN_SEARCH,
    methodType: "POST",
  });
export const dealerZoneOptionsStateOpenSearch = buildAsyncFilterOptionsState({
  topic: "dealerZoneUrlOpenSearchFilterOpenSearch",
  getUrl: dealerZoneUrlOpenSearch,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.key,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});
export const dealerRegionOptionsStateOpenSearch = buildAsyncFilterOptionsState({
  topic: "dealerRegionUrlOpenSearchFilterOpenSearch",
  getUrl: dealerRegionUrlOpenSearch,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.key,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});
export const dealerDistrictOptionsStateOpenSearch =
  buildAsyncFilterOptionsState({
    topic: "dealerDistrictUrlOpenSearchFilterOpenSearch",
    getUrl: dealerDistrictUrlOpenSearch,
    getResponseData: (data) => data?.data ?? [],
    transformResult: (result) => ({
      label: result.value,
      value: result.key,
    }),
    paginationType: PaginationType.OPEN_SEARCH,
    methodType: "POST",
  });

export const currentLocationOptionsStateOpenSearch =
  buildAsyncFilterOptionsState({
    topic: "currentLocationUrlOpenSearchFilterOpenSearch",
    getUrl: currentLocationUrlOpenSearch,
    getResponseData: (data) => data?.data ?? [],
    transformResult: (result) => ({
      label: result.value,
      value: result.key,
    }),
    paginationType: PaginationType.OPEN_SEARCH,
    methodType: "POST",
  });

export const routeIdOptionsStateOpenSearch = buildAsyncFilterOptionsState({
  topic: "routeIdUrlOpenSearchFilterOpenSearch",
  getUrl: routeIdUrlOpenSearch,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.key,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const destinationOptionsState = buildLocationAsyncFilterOptionsState({
  topic: "destinationShipmentFilter",
  additionalParams: {
    verbose: false,
  },
  additionalQueryOnlyParams: {
    everythingRestrict: "code,name",
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
});

export const currentPositionsCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "fvCurrentPositionCodeFilter",
  getUrl: currentPositionCodesUrl,
  getResponseData: (data) => data?.currentPositionCodes ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.id,
  }),
});

export const productTypeOptionsState = buildAsyncFilterOptionsState({
  topic: "fvProductTypeFilter",
  getUrl: productTypesUrl,
  getResponseData: (data) => data?.productTypes ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const lastMilestoneOptionsState = buildAsyncFilterOptionsState({
  topic: "lastMilestone",
  getUrl: lastMilestoneUrl,
  getResponseData: (data) => data?.lastMilestone ?? [],
  transformResult: (option) => ({
    label: `${option.codeDescription} (${option.code})`,
    value: `${option.codeDescription} (${option.code})`,
  }),
});

export const soldToOptionsState = buildAsyncFilterOptionsState({
  topic: "fvSoldToFilter",
  getUrl: soldToUrl,
  getResponseData: (data) => {
    return data?.["ref:soldToDealer"] ?? [];
  },
  transformResult: (result) => {
    const label = `(${result.sold_to_code}) ${
      result.sold_to_name ? result.sold_to_name : ""
    }`;
    return { label: label, value: result.sold_to_code };
  },
});

export const finCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "fvFinCodeFilter",
  getUrl: finCodeUrl,
  getResponseData: (data) => {
    return data?.["ref:FinCode"] ?? [];
  },
  transformResult: (result) => {
    const label = `${result.name ? result.name : ""} (${result.code})`;
    return { label: label, value: result.code };
  },
});

export const endUserFinCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "fvEndUserFinCodeFilter",
  getUrl: endUserFinCodeUrl,
  getResponseData: (data) => {
    return data?.["ref:EndUserFinCode"] ?? [];
  },
  transformResult: (result) => {
    const label = `${result.name ? result.name : ""} (${result.code})`;
    return { label: label, value: result.code };
  },
});

export const dealerRegionOptionsState = buildAsyncFilterOptionsState({
  topic: "fvDealerRegionFilter",
  getUrl: dealerRegionUrl,
  getResponseData: (data) => data?.dealerRegion ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const dealerZoneOptionsState = buildAsyncFilterOptionsState({
  topic: "fvDealerZoneFilter",
  getUrl: dealerZoneUrl,
  getResponseData: (data) => data?.dealerZone ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const dealerDistrictOptionsState = buildAsyncFilterOptionsState({
  topic: "fvDealerDistrictFilter",
  getUrl: dealerDistrictUrl,
  getResponseData: (data) => data?.dealerDistrict ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const originCountryFinOptionsState = buildAsyncFilterOptionsState({
  topic: "fvOriginCountryFilter",
  getUrl: originCountryUrl,
  getResponseData: (data) => data?.originCountry ?? [],
  transformResult: (option) => ({ label: option.name, value: option.code }),
});

export const destinationCountryFinOptionsState = buildAsyncFilterOptionsState({
  topic: "fvDestinationCountryFilter",
  getUrl: destinationCountryUrl,
  getResponseData: (data) => data?.destinationCountry ?? [],
  transformResult: (option) => ({ label: option.name, value: option.code }),
});

export const partnersOptionsState = buildAsyncFilterOptionsState({
  topic: "fvPartnersFilter",
  getUrl: partnersUrl,
  getResponseData: (data) => data?.partners ?? [],
  transformResult: (option) => ({
    label: option.org_name,
    value: option.fv_id,
  }),
});

export const exteriorColorOptionsState = buildAsyncFilterOptionsState({
  topic: "fvExteriorColorFilter",
  getUrl: exteriorColorUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.value,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const interiorColorOptionsState = buildAsyncFilterOptionsState({
  topic: "fvInteriorColorFilter",
  getUrl: interiorColorUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.value,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const doorsOptionsState = buildAsyncFilterOptionsState({
  topic: "fvDoorsFilter",
  getUrl: doorsUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.value,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const powertrainOptionsState = buildAsyncFilterOptionsState({
  topic: "fvPowertrainFilter",
  getUrl: powertrainUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.value,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const drivetrainOptionsState = buildAsyncFilterOptionsState({
  topic: "fvDrivetrainFilter",
  getUrl: drivetrainUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.value,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const bodyStyleOptionsState = buildAsyncFilterOptionsState({
  topic: "fvBodyStyleFilter",
  getUrl: bodyStyleUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.value,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const fuelTypeOptionsState = buildAsyncFilterOptionsState({
  topic: "fvFuelTypeFilter",
  getUrl: fuelTypeUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.value,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const yearOptionsState = buildAsyncFilterOptionsState({
  topic: "fvYearFilter",
  getUrl: yearUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.value,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const makeOptionsState = buildAsyncFilterOptionsState({
  topic: "fvMakeFilter",
  getUrl: makeUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.value,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const modelOptionsState = buildAsyncFilterOptionsState({
  topic: "fvModelFilter",
  getUrl: modelUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.value,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});

export const trimOptionsState = buildAsyncFilterOptionsState({
  topic: "fvTrimFilter",
  getUrl: trimUrl,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.value,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
  methodType: "POST",
});
