import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CurrentLocationType, SourceType, EntityStatus } from "api/consts";
import { logWarningForMissingTranslation } from "utils/log-warning.utils";

export const useLifecycleStateTranslation = () => {
  const { t } = useTranslation("fv-dashboard");

  const getTranslatedStatus = useCallback(
    (option: string) => {
      switch (option) {
        case EntityStatus.ACTIVE:
          return t("fv-dashboard:Active");
        case EntityStatus.DELIVERED:
          return t("fv-dashboard:Delivered");
        case EntityStatus.COMPLETE:
          return t("fv-dashboard:Complete");
        case "All Active":
          return t("fv-dashboard:All Active");
        case "Order Only":
          return t("fv-dashboard:Order Only");
        case "VIN Only":
          return t("fv-dashboard:VIN Only");
        case "New Active":
          return t("fv-dashboard:New Active");
        default: {
          logWarningForMissingTranslation(option);
          return option;
        }
      }
    },
    [t],
  );

  return { getTranslatedStatus };
};

export const useEntityTranslation = () => {
  const { t } = useTranslation("fv-vin-search");

  const getTranslatedCurrentLocationTypes = useCallback(
    (option: string) => {
      switch (option.replace(/ /g, "").toLowerCase()) {
        case CurrentLocationType.AT_LOCATION:
          return t("fv-vin-search:At Location");
        case CurrentLocationType.ON_RAIL:
          return t("fv-vin-search:On Rail");
        case CurrentLocationType.ON_THE_ROAD:
          return t("fv-vin-search:On the Road");
        case CurrentLocationType.ON_THE_WATER:
          return t("fv-vin-search:On the Water");
        default: {
          logWarningForMissingTranslation(option);
          return option;
        }
      }
    },
    [t],
  );

  const getTranslatedSource = useCallback(
    (option: string) => {
      switch (option) {
        case SourceType.SHIPMENT:
          return t("fv-vin-search:Shipment");
        case SourceType.CONNECTED_CAR:
        case SourceType.TAG:
          return t("fv-vin-search:Connected Car");
        case SourceType.MILESTONE:
          return t("fv-vin-search:Milestone");
        default: {
          logWarningForMissingTranslation(option);
          return option;
        }
      }
    },
    [t],
  );

  const getTranslatedBatchSearchOptions = useCallback(
    (option: string) => {
      switch (option) {
        // existing translations
        case "vin":
          return t("fv-vin-search:VIN");
        case "description":
          return t("fv-vin-search:Product Type");
        case "last_8_of_vin":
          return t("fv-vin-search:Last 8 of VIN");
        case "OrderNumber":
          return t("fv-vin-search:Order Number");
        // new translations
        case "vinNumberBatch":
          return t("fv-vin-search:VIN");
        case "productTypeBatch":
          return t("fv-vin-search:Product Type");
        case "last8OfVin":
          return t("fv-vin-search:Last 8 of VIN");
        case "orderNumberBatch":
          return t("fv-vin-search:Order Number");
        default: {
          logWarningForMissingTranslation(option);
          return option;
        }
      }
    },
    [t],
  );

  return {
    getTranslatedSource,
    getTranslatedCurrentLocationTypes,
    getTranslatedBatchSearchOptions,
  };
};
