import _ from "lodash";
import {
  getBasicQueryString,
  getBasicWithStaticOptionsQueryString,
  getEverythingQueryString,
  getEntityDateRangeQueryString,
  getEmptyQueryString,
  getMultiSelectQueryString,
  getNQueryStringFilterValuePriority,
  getLocationQueryString,
  getLocationQueryStringOrigin,
  getDateRangeQueryString,
} from "components/search-bar/search-filter-query-strings";
import { isDateRangeValueValid } from "components/search-bar/search-filter-validators";
import {
  DateRangeFilterButton,
  BatchFilterButton,
  NFilterButton,
  AsyncSelectFilterButton,
  SelectFilterButton,
} from "components/search-bar/FilterButtons";
import { DealerOrgFilterButton } from "./DealerOrgFilterButton.molecule";
import { Features } from "modules/auth/Authorization";
import { OrganizationType } from "shared/constants/organization.const";
import {
  dealerOrgOptionsState,
  currentPositionsCodeOptionsState,
  productTypeOptionsState,
  vinViewLastMilestoneOptionsState,
  vinViewLocationsOptionsState,
  vinViewSoldToOptionsState,
  vinViewCustomerProvidedExteriorColorOptionsState,
  vinViewCustomerProvidedInteriorColorOptionsState,
  vinViewCustomerProvidedDoorTypeOptionsState,
  vinViewCustomerProvidedPowertrainOptionsState,
  vinViewFinCodeOptionsState,
  vinViewEndUserFinCodeOptionsState,
  vinViewOriginOptionsState,
} from "pages/vinview/redux/VinViewSearchFilterLoaders";
import {
  batchCsvVinExample,
  batchCsvProductTypeExample,
  batchCsvOrderNumberExample,
  batchCsvLast8ofVinExample,
} from "components/search-bar/batch-comment-csv-data";
import {
  useEntityTranslation,
  useLifecycleStateTranslation,
} from "shared/hooks/useEntityTranslation";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: (t) => t("fv-vin-search:Search Everything"),
    placeholder: (t) => {
      return t("vinview-search:Search VIN, Order #, or Product Type");
    },
    queryBuilder: getEverythingQueryString,
    property: null,
  },
  {
    queryKey: "entityId",
    label: (t) => t("fv-vin-search:VIN"),
    placeholder: (t) => t("fv-vin-search:Search VIN"),
    queryBuilder: getBasicQueryString,
    property: "vins",
  },
  {
    queryKey: "ref:OrderNumber:1",
    label: (t) => t("fv-vin-search:Order #"),
    placeholder: (t) => t("fv-vin-search:Search Order #"),
    queryBuilder: getBasicQueryString,
    property: "orderNumbers",
  },
  {
    // H2-305 productType is now descriptionSearch for the search bar
    queryKey: "descriptionSearch",
    label: (t) => t("fv-vin-search:Product Type"),
    placeholder: (t) => t("fv-vin-search:Search Product Type"),
    queryBuilder: getBasicQueryString,
    property: "productTypes",
  },
  {
    queryKey: "altKey",
    label: (t) => t("fv-vin-search:Last 8 of VIN"),
    placeholder: (t) => t("fv-vin-search:Search Last 8 of VIN"),
    queryBuilder: getBasicQueryString,
    property: "vins",
    minCharacters: 8,
    maxCharacters: 8,
  },
];

export const FILTERS = [
  {
    queryKey: "dealerOrgId",
    label: (t) => t("fv-vin-search:Dealer Org"),
    Component: DealerOrgFilterButton,
    optionsState: dealerOrgOptionsState,
    queryBuilder: getMultiSelectQueryString,
    // Shippers with the VinView feature will need this fitler.
    requiredOrgTypes: [OrganizationType.SHIPPER],
    requiredFeatures: [Features.VIN_VIEW],
    // Prevents clearing this filter when we clear all filters.
    onlyUserClearable: true,
    // Prevent selecting other filters before this one is selected.
    prerequisiteForSearchAndFilters: true,
  },
  {
    queryKey: "vinviewOriginLocations",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Origin"),
    optionsState: vinViewOriginOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryStringOrigin("originId", filterValue, {
        valueKey: "code",
      }),
  },
  {
    queryKey: "vinViewLocations",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Destination"),
    optionsState: vinViewLocationsOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryString("destinationId", filterValue, {
        valueKey: "code",
      }),
  },
  {
    queryKey: "ref:OrderType:0",
    label: (t) => t("fv-vin-search:Order Type"),
    optionsGetter: (props) => props.orderTypeFilterOptions,
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "ref:soldToDealer:0",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Sold To"),
    isMulti: true,
    optionsState: vinViewSoldToOptionsState,
    queryBuilder: (queryParameter, filterValue) => {
      // Fuzzy search is disabled right now, but when we re-enable it:
      // This filter is special. For normally-selected options, the API
      // wants the queryKey to be `ref:soldToDealer:0`, but for the fuzzy
      // search options, it wants it to be `ref:soldToDealer:1` (by default,
      // our code automatically makes fuzzy search to be the
      // queryKey above + `:contains`). So, we're doing the below to replace
      // instances of `:0:contains` with `:1`.
      let soldToQueryString = getMultiSelectQueryString(
        queryParameter,
        filterValue,
      );
      soldToQueryString = soldToQueryString.replace(":0:contains", ":1");
      return soldToQueryString;
    },
    requiredOrgFilters: "VIN_SOLD_TO",
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "ref:FinCode:0",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:FIN Code"),
    isMulti: true,
    optionsState: vinViewFinCodeOptionsState,
    queryBuilder: (queryParameter, filterValue) => {
      let finCodeQueryString = getMultiSelectQueryString(
        queryParameter,
        filterValue,
      );
      return finCodeQueryString;
    },
    requiredOrgFilters: "VIN_FINCODE",
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "ref:EndUserFinCode:0",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:End User FIN Code"),
    isMulti: true,
    optionsState: vinViewEndUserFinCodeOptionsState,
    queryBuilder: (queryParameter, filterValue) => {
      let endUserFinCodeQueryString = getMultiSelectQueryString(
        queryParameter,
        filterValue,
      );
      return endUserFinCodeQueryString;
    },
    requiredOrgFilters: "VIN_ENDUSER_FINCODE",
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "carrier",
    label: (t) => t("fv-vin-search:Carrier"),
    optionsGetter: (props) => props.carrierFilterOptions,
    queryBuilder: getBasicWithStaticOptionsQueryString,
  },
  // TODO: re-add internal space to the "XxType" filter labels once it won't cause
  // a line break in the widget
  // (this already seems to work in the shipment filters)
  {
    // H2-305 for the filters producttype is now called description
    queryKey: "description",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Product Type"),
    optionsState: productTypeOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: ["lifeCycleState", "isNew"],
    label: (t) => t("fv-vin-search:VIN Status"),
    Component: (props) => {
      const { getTranslatedStatus } = useLifecycleStateTranslation();

      let translatedOptions = props?.options;

      for (const [status, subStatusOptions] of Object.entries(props?.options)) {
        translatedOptions[status] = subStatusOptions.map((option) => ({
          ...option,
          label: getTranslatedStatus(option.label),
        }));
      }

      return <NFilterButton {...props} options={translatedOptions} />;
    },
    nIsAsync: {
      lifeCycleState: false,
      isNew: false,
    },
    nLabels: {
      lifeCycleState: null,
      isNew: "Active Subcategory",
    },
    nRequirments: {
      isNew: ["Active"],
    },
    nIsMulti: {
      lifeCycleState: true,
      isNew: false,
    },
    nHideFuzzySearch: {
      lifeCycleState: true,
      isNew: true,
    },
    nHideSelectAll: {
      lifeCycleState: true,
      isNew: true,
    },
    nHideSelectEmpty: {
      lifeCycleState: true,
      isNew: true,
    },
    nDefaultValue: {
      isNew: "all_active",
    },
    showAll: false,
    optionsGetter: (props, t) => {
      return {
        lifeCycleState: props.lifeCycleStateFilterOptions,
        isNew: [
          { label: "All Active", value: "all_active" },
          { label: "New Active", value: "new_active" },
          { label: "Order Only", value: "prebuilt" },
          { label: "VIN Only", value: "active" },
        ],
      };
    },
    queryBuilder: (queryKeys, filterValues) => {
      if (filterValues.isNew === "prebuilt") {
        filterValues.lifeCycleState = ["Prebuilt"];
      } else if (filterValues.isNew === "all_active") {
        filterValues.lifeCycleState = [
          ...filterValues.lifeCycleState,
          "Prebuilt",
        ];
      } else if (filterValues.activeSubStatus === "active") {
        filterValues.lifeCycleState = ["Active"];
      }
      queryKeys.filter((key) => {
        if (key === "isNew" && filterValues.isNew === "new_active") {
          filterValues.isNew = true;
          return filterValues.isNew;
        }
      });
      return getNQueryStringFilterValuePriority(
        queryKeys.filter((key) => {
          return key === "isNew" ? _.isBoolean(filterValues.isNew) : true;
        }),
        filterValues,
      );
    },
  },
  {
    queryKey: "exceptionType",
    label: (t) => t("fv-vin-search:VIN Exception"),
    optionsGetter: (props, t) => {
      // H2-708: content of exceptions should be translated when presented.
      return props.exceptionTypeFilterOptions.map((item) => {
        item.label = t(`exceptions:${item.label}`);
        return item;
      });
    },
    queryBuilder: (queryParameter, filterValue) =>
      getBasicWithStaticOptionsQueryString("exception", filterValue),
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "lastMilestone",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Last Milestone"),
    optionsState: vinViewLastMilestoneOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    // ordered top down from 0 - N
    queryKey: ["currentPositionTypes", "currentPositionCodes"],
    label: (t) => t("fv-vin-search:Current Location"),
    Component: (props) => {
      const { getTranslatedCurrentLocationTypes } = useEntityTranslation();

      const translatedOptions = {
        currentPositionTypes: props?.options?.currentPositionTypes.map(
          (option) => ({
            ...option,
            label: getTranslatedCurrentLocationTypes(option.label),
          }),
        ),
      };

      return (
        <NFilterButton
          {...props}
          options={
            !_.isEmpty(translatedOptions.currentPositionTypes)
              ? translatedOptions
              : props.options
          }
        />
      );
    },
    nIsAsync: {
      currentPositionTypes: false,
      currentPositionCodes: true,
    },
    nOptionsState: {
      currentPositionTypes: null,
      currentPositionCodes: currentPositionsCodeOptionsState,
    },
    //Label of filter where key is corresponding select
    nLabels: {
      currentPositionTypes: null,
      currentPositionCodes: "At Location",
    },
    //Fields required from previous filter
    //Field with contraints as key
    nRequirments: {
      currentPositionCodes: ["AtLocation"],
    },
    // Hides fuzzy search options
    nHideFuzzySearch: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    // Hides select all option
    nHideSelectAll: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    // Hides select empty option
    nHideSelectEmpty: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    //Showall filters with no requirments or contraints
    showAll: false,
    //Object key as query key
    optionsGetter: (props) => {
      return {
        currentPositionTypes: props.currentPositionTypes ?? [],
      };
    },
    queryBuilder: getNQueryStringFilterValuePriority,
  },
  {
    queryKey: "completedDate",
    label: (t) => t("fv-vin-search:Completed Date"),
    Component: DateRangeFilterButton,
    queryBuilder: (queryParameter, filterValue) =>
      getDateRangeQueryString(queryParameter, filterValue, {
        formatDateTypeValue: JSON.stringify,
        convertToUtc: true,
      }),
    isValueValid: isDateRangeValueValid,
    requiredOrgFilters: "VIN_COMPLETED_DATE",
    dateTypeOptions: (t) => [],
  },
  {
    queryKey: "deliveryDate",
    label: (t) => t("fv-vin-search:Delivery Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getEntityDateRangeQueryString,
    dateTypeOptions: (t) => [
      { label: t("fv-vin-search:Actual"), value: "Actual" },
      {
        label: t("fv-vin-search:ETA"),
        value: "ETA",
      },
    ],
    isValueValid: isDateRangeValueValid,
  },
  {
    queryKey: "shippability",
    Component: SelectFilterButton,
    label: (t) => t("fv-vin-search:Shippability"),
    optionsGetter: (props, t) => {
      return [
        {
          label: t("fv-vin-search:Shippable"),
          value: "shippable",
        },
        {
          label: t("fv-vin-search:Non-Shippable"),
          value: "non-shippable",
        },
      ];
    },
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString(queryParameter, filterValue),
    requiredOrgFilters: "FIN_SHIPPABILITY",
  },
  {
    queryKey: "batch",
    label: (t) => t("fv-vin-search:Batch Search"),
    Component: (props) => {
      const { getTranslatedBatchSearchOptions } = useEntityTranslation();

      const translatedOptions = props?.options.map((option) => ({
        ...option,
        label: getTranslatedBatchSearchOptions(option.value),
      }));

      return <BatchFilterButton {...props} options={translatedOptions} />;
    },
    optionsGetter: () => {
      return [
        {
          value: "vin",
          batchCsvExample: batchCsvVinExample,
        },
        {
          value: "description",
          batchCsvExample: batchCsvProductTypeExample,
        },
        {
          value: "last_8_of_vin",
          batchCsvExample: batchCsvLast8ofVinExample,
        },
        {
          value: "OrderNumber",
          batchCsvExample: batchCsvOrderNumberExample,
        },
      ];
    },
    queryBuilder: getEmptyQueryString,
  },
  {
    queryKey: "ref:CustomerProvided-ExteriorColor:0",
    label: (t) => t("fv-vin-search:Exterior Color"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: vinViewCustomerProvidedExteriorColorOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    requiredOrgFilters: "VIN_FEATURED_REFERENCES",
  },
  {
    queryKey: "ref:CustomerProvided-InteriorColor:0",
    label: (t) => t("fv-vin-search:Interior Color"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: vinViewCustomerProvidedInteriorColorOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    requiredOrgFilters: "VIN_FEATURED_REFERENCES",
  },
  {
    queryKey: "ref:CustomerProvided-DoorType:0",
    label: (t) => t("fv-vin-search:Door Type"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: vinViewCustomerProvidedDoorTypeOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    requiredOrgFilters: "VIN_FEATURED_REFERENCES",
  },
  {
    queryKey: "ref:CustomerProvided-Powertrain:0",
    label: (t) => t("fv-vin-search:Power Train"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: vinViewCustomerProvidedPowertrainOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    requiredOrgFilters: "VIN_FEATURED_REFERENCES",
  },
];
