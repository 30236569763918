import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import FinVehicleSearchBarState from "../redux/FinVehicleSearchBarStateOpenSearch";
import { OPEN_SEARCH_FILTERS } from "./FinVehicleSearchFilterDefsOpenSearch";

import {
  selectCarrierFilterOptions,
  selectCarrierFilterOptionsOS,
  selectExceptionTypeFilterOptions,
  selectProductTypeFilterOptions,
  selectLifeCycleStateFilterOptions,
  selectShipmentStatusFilterOptions,
  selectShipmentExceptionFilterOptions,
  selectOrderTypeFilterOptions,
  selectItssIdFilterOptions,
  selectItssIdFilterOptionsOS,
  selectSpotBuyFilterOptions,
  selectSpotBuyFilterOptionsOS,
  selectCurrentPositionTypes,
  selectRouteIdFilterOptions,
  selectShipmentStatusFilterOptionsOS,
  selectRouteIdFilterOptionsOS,
  selectOrderTypeFilterOptionsOS,
} from "./FinVehicleSearchFilterSelectors";
import DomainDataState from "modules/domain-data/DomainDataState";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const mapStateToProps = (state) => {
  const auth = getAuthorization(state);
  return {
    auth: auth,
    searchFilters: FinVehicleSearchBarState.selectors.getSearchFilters(state),
    hasSearchCriteriaChanged:
      FinVehicleSearchBarState.selectors.getHasSearchCriteriaChanged(state),
    canUserSearch: FinVehicleSearchBarState.selectors.getCanUserSearch(state),
    carrierFilterOptions: selectCarrierFilterOptions(state),
    carrierFilterOptionsOS: selectCarrierFilterOptionsOS(state),
    exceptionTypeFilterOptions: selectExceptionTypeFilterOptions(state),
    productTypeFilterOptions: selectProductTypeFilterOptions(state),
    lifeCycleStateFilterOptions: selectLifeCycleStateFilterOptions(state),
    orderTypeFilterOptions: selectOrderTypeFilterOptions(state),
    orderTypeFilterOptionsOS: selectOrderTypeFilterOptionsOS(state),
    shipmentStatusFilterOptions: selectShipmentStatusFilterOptions(state),
    shipmentStatusFilterOptionsOS: selectShipmentStatusFilterOptionsOS(state),
    itssFilterOptions: selectItssIdFilterOptions(state),
    itssFilterOptionsOS: selectItssIdFilterOptionsOS(state),
    spotBuyFilterOptions: selectSpotBuyFilterOptions(state),
    spotBuyFilterOptionsOS: selectSpotBuyFilterOptionsOS(state),
    shipmentExceptionFilterOptions: selectShipmentExceptionFilterOptions(state),
    solutionId: getSolutionId(state),
    regionFilterOptions:
      DomainDataState.selectors.selectRegionFilterOptions(state),
    currentPositionTypes: selectCurrentPositionTypes(state),
    routeIdOptions: selectRouteIdFilterOptions(state),
    routeIdOptionsOS: selectRouteIdFilterOptionsOS(state),
    isOpenSearchFeatureEnabled: auth.validateEntitySystemConfigValue([
      { key: "USE_ES_ENABLED_FIN_VIEW_FILTER_APIS", value: "true" },
    ]),
  };
};

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = FinVehicleSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    filtersMetadata: OPEN_SEARCH_FILTERS,
  };
};

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
