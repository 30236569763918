/** @jsxImportSource @emotion/react */
// Third party Libraries
import _ from "lodash";
import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import Link from "redux-first-router-link";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { useTranslation, Trans } from "react-i18next";
import { useEtaTranslations } from "shared/hooks/useEtaTranslations";
import moment from "moment";

// Third party UI Libraries
import { Alert, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import {
  faCommentAlt,
  faQuestionCircle,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faClock,
  faCopy,
  faCheckSquare as faCheck,
} from "@fortawesome/pro-light-svg-icons";

// UI Libraries
import Colors from "styles/colors";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { VinMilestoneCell } from "components/organisms/base-table/Cell/VinMilestoneCell";
import { DateTimeCell } from "components/organisms/base-table/Cell/DateTimeCell";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { Button } from "components/atoms/Button.atom";
import { Text } from "components/atoms/Text.atom";
import { Icon, FontSize } from "components/atoms/Icon.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { MadChicletCSS as MadChiclet } from "components/chiclets";
import {
  DpuLocationCell,
  LastMileStoneCell,
  StatusCell as StatusHistoryStatusCell,
  ModifiedByCell,
  CommentCell,
} from "pages/driveaway/components/table-cells/status-history";
import { PickupWindowField } from "../components/table-cells/status/PickupWindowField";

// Utils
import { getCurrentPositionDetails } from "shared/utils/entity.utils";
import {
  translateDenyReason,
  denyCodeToReadableFormat,
  translateStatusLabel,
  formatIntlPhoneNum,
  translateReasonExplanation,
} from "pages/driveaway/utils/driveaway.utils";
import {
  localizedTimeFormatter,
  localizedDateFormatter,
} from "utils/date-time";
import { isNumberOnly } from "pages/driveaway/utils/driveaway.utils";
import { isValidEmail } from "utils/validation-utils";
import AuthenticationUtils from "modules/auth/authentication";

import { SourceType } from "api/consts";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import {
  isDealer,
  isCarrier,
  isShipper,
} from "shared/utils/organizations.utils";
import { DealerPickUpFormButton } from "../components/search/DriveAway.DealerPickUpForm.Button";
import { DPUStatus, FederatedUserEmailTrail } from "../utils/enums.utils";

// Helpers
const findReference = (references, key) => {
  if (references === undefined) {
    return null;
  }
  const ref = references.find((obj) => obj.qualifier === key);
  return ref ? ref.value : null;
};

const RequiredLabel = ({ label }) => {
  const asteriskStyle = {
    color: "red",
    marginLeft: "2px",
  };

  return (
    <span>
      {label}
      <span style={asteriskStyle}>*</span>
    </span>
  );
};

/**
 * Calculates the minutes that has past since the input time
 * @param {*} ddaTimeStamp Takes anything that MomentJs can convert into a moment object
 * @returns
 */
const calculatMinutesPassedNoSunday = (ddaTimeStamp) => {
  let totalMinutes = 0;
  const currentTime = moment();
  const inputTime = moment(ddaTimeStamp);
  for (
    let i = inputTime;
    i.isBefore(currentTime, "minute");
    i.add(1, "minute")
  ) {
    if (i.day() !== 0) {
      totalMinutes += 1;
    }
  }
  return totalMinutes;
};

const ModalErrorIndicator = (props) => {
  const { t } = useTranslation("driveaway-search");
  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faTimesCircle} color={Colors.highlight.RED} size={3} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
      >
        <Text>
          {t("driveaway-search:There was an error. Please try again later.")}
        </Text>
      </div>
    </Fragment>
  );
};

const OneWayDistanceText = (props) => {
  const { t, oneWayDistanceKm, oneWayDistanceMi, countryCode } = props;
  return (
    <div css={{ display: "flex", flexDirection: "row" }}>
      <Text bold css={{ marginRight: 3 }}>
        {t("driveaway-search:Dealership Pickup Distance")}:
      </Text>
      {!_.isNil(oneWayDistanceKm) && !_.isNil(oneWayDistanceMi) ? (
        <Tooltip
          placement="top"
          tooltipChildren={
            <Fragment>
              {countryCode !== "US" ? (
                <Fragment>
                  <Text css={{ marginRight: 3 }}>
                    {!_.isNil(oneWayDistanceMi)
                      ? Number(oneWayDistanceMi).toFixed(1)
                      : Number(oneWayDistanceKm * 1.609344).toFixed(1)}
                  </Text>
                  <Text>{t("driveaway-search:mi")}</Text>
                </Fragment>
              ) : (
                <Fragment>
                  <Text css={{ marginRight: 3 }}>
                    {!_.isNil(oneWayDistanceKm)
                      ? Number(oneWayDistanceKm).toFixed(1)
                      : Number(oneWayDistanceMi * 0.62137).toFixed(1)}
                  </Text>
                  <Text>{t("driveaway-search:km")}</Text>
                </Fragment>
              )}
            </Fragment>
          }
        >
          {countryCode === "US" ? (
            <Fragment>
              <Text css={{ marginRight: 3 }}>
                {!_.isNil(oneWayDistanceMi)
                  ? Number(oneWayDistanceMi).toFixed(1)
                  : Number(oneWayDistanceKm * 1.609344).toFixed(1)}
              </Text>
              <Text>{t("driveaway-search:mi")}</Text>
            </Fragment>
          ) : (
            <Fragment>
              <Text css={{ marginRight: 3 }}>
                {!_.isNil(oneWayDistanceKm)
                  ? Number(oneWayDistanceKm).toFixed(1)
                  : Number(oneWayDistanceMi * 0.62137).toFixed(1)}
              </Text>
              <Text>{t("driveaway-search:km")}</Text>
            </Fragment>
          )}
        </Tooltip>
      ) : (
        <Text>{t("driveaway-search:Unavailable")}</Text>
      )}
    </div>
  );
};

OneWayDistanceText.propTypes = {
  t: PropTypes.func,
  oneWayDistanceKm: PropTypes.number,
  oneWayDistanceMi: PropTypes.number,
  countryCode: PropTypes.string,
};

const VinCell = (props) => {
  const {
    id,
    description,
    references,
    commentsCount,
    carrier,
    authorizedToShipDate,
    dayCount,
    solutionId,
  } = props.value;
  const { t } = useTranslation("driveaway-search");
  const orderType = findReference(references, "OrderType") || "";
  const orderNumber = findReference(references, "OrderNumber") || "";
  const carrierName = carrier?.name || "";
  const carrierSCAC = carrier?.scac || "";

  const [clipboardIcon, setClipboardIcon] = useState(faCopy);
  const [clipboardIconColor, setClipboardIconColor] = useState(
    Colors.highlight.DARK_GRAY,
  );

  const handleCopy = (e) => {
    navigator.clipboard.writeText(id);
    setClipboardIcon(faCheck);
    setClipboardIconColor(Colors.highlight.GREEN);
    setTimeout(() => {
      setClipboardIcon(faCopy);
      setClipboardIconColor(Colors.highlight.DARK_GRAY);
    }, 1800);
    e.stopPropagation();
  };

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div
        css={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {commentsCount && commentsCount > 0 ? (
          <Tooltip
            placement="top"
            tooltipChildren={
              <Text>{t("driveaway-search:This VIN contains comments")}</Text>
            }
          >
            <Icon
              src={faCommentAlt}
              color={Colors.comments.unreadCommentIcon}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
        ) : null}
        <div css={{ display: "flex", alignItems: "center" }}>
          <Text bold css={{ marginRight: "0.5em" }}>
            {id}
          </Text>
          <Icon
            src={clipboardIcon}
            size={FontSize.size16}
            color={clipboardIconColor}
            onClick={handleCopy}
            css={{ cursor: "pointer" }}
          />
        </div>
      </div>
      {description && description !== "None" ? (
        <Text>{description}</Text>
      ) : null}
      <div css={{ display: "flex", flexDirection: "row" }}>
        {orderType ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              {t("driveaway-search:Order Type")} -
            </Text>
            <Text>{orderType}</Text>
          </React.Fragment>
        ) : null}
      </div>
      <div css={{ display: "flex", flexDirection: "row" }}>
        {orderNumber ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              {t("driveaway-search:Order #")} -
            </Text>
            <Text>{orderNumber}</Text>
          </React.Fragment>
        ) : null}
      </div>
      <div css={{ display: "flex", flexDirection: "row" }}>
        {carrierName ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              {t("driveaway-search:Carrier (SCAC)")}
            </Text>
            <Text>
              {carrierName} {carrierSCAC ? "(" + carrierSCAC + ")" : null}
            </Text>
          </React.Fragment>
        ) : null}
      </div>
      {!_.isNil(authorizedToShipDate) ? (
        <div>
          <Text bold style={{ marginRight: 3 }} block>
            {t("driveaway-search:Authorized to ship date")}
          </Text>
          <Text>{authorizedToShipDate}</Text>
        </div>
      ) : null}
      {!_.isNil(dayCount) ? (
        <div>
          <Text bold style={{ marginRight: 3 }} block>
            {t("driveaway-search:Days since authorized to ship")}
          </Text>
          <Text>{dayCount}</Text>
        </div>
      ) : null}
    </div>
  );
};

VinCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    references: PropTypes.array,
    commentsCount: PropTypes.number,
    carrier: PropTypes.object,
    authorizedToShipDate: PropTypes.string,
    dayCount: PropTypes.number,
    solutionId: PropTypes.string,
  }),
};

let StatusCell = ({
  value: {
    status,
    previousStatus,
    reason,
    reasonsDescription,
    reasonExplanation,
    carrierTime,
    deniedTime,
    carrierEmail,
    dealerPhone,
    dealerTime,
    dealerEmail,
    id,
    internalId,
    requestDriveAway,
    cancelDriveAway,
    confirmPickUpRequest,
    pickupWindow,
    fetchDenyReasons,
    solutionId,
    approvalId,
    setShowReviewRequestModal,
    pickUpWindowTzAbbr,
    pickUpWindowTz,
    isExportingPDF,
    exportPDFEntities,
    ddaCommentsCount,
    ddaComments,
    fetchComments,
    errorFetchingDdaComments,
    fetchStatusHistory,
    statusHistory,
    statusHistoryFetchStatus,
    userPreference,
    vin,
    ultimateDestinationCountryCode,
    oneWayDistanceKm,
    oneWayDistanceMi,
    confirmationTs,
    confirmationEmail,
    subStatus,
    persistentApprovalEnabled,
  },
  activeOrganization,
}) => {
  const { t } = useTranslation("driveaway-search");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showPickUpConfirmationDialog, setShowPickUpConfirmationDialog] =
    useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [cancelComments, setCancelComments] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberExt, setPhoneNumberExt] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const defaultPhoneNumCountryInfo = {
    countryCode: "us",
    dialCode: "1",
    format: "+. (...) ...-....",
    name: "United States",
  };
  const [phoneNumCountryInfo, setPhoneNumCountryInfo] = useState(
    defaultPhoneNumCountryInfo,
  );
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);
  const [showStatusHistoryDialog, setShowStatusHistoryDialog] = useState(false);

  const dealerPhoneArray = dealerPhone?.split("#");

  const submittedAgingTime = useMemo(() => {
    let hours = null;
    let minutes = null;

    if (status === DPUStatus.SUBMITTED) {
      const ddaTimeStamp = moment.utc(carrierTime).local();
      const diffMinutes = calculatMinutesPassedNoSunday(ddaTimeStamp);
      hours = Math.floor(diffMinutes / 60);
      minutes = diffMinutes - hours * 60;
    }

    return { hours, minutes };
  }, [carrierTime, status]);

  const isFederatedUser = AuthenticationUtils.userEmail.endsWith(
    FederatedUserEmailTrail,
  );

  useEffect(() => {
    // Here we check if the submit button needs to be disabled

    if (isFederatedUser) {
      // If it's federated user then we have to check if email is valid
      if (
        isValidPhoneNumber(phoneNumber, phoneNumberExt) &&
        isValidEmail(emailAddress)
      ) {
        setSubmitButtonDisabled(false);
      } else {
        setSubmitButtonDisabled(true);
      }
    } else {
      if (isValidPhoneNumber(phoneNumber, phoneNumberExt)) {
        setSubmitButtonDisabled(false);
      } else {
        setSubmitButtonDisabled(true);
      }
    }
  }, [phoneNumber, phoneNumberExt, emailAddress]);

  useEffect(() => {
    // This useEffect initialize the input fields whenever the pop up is showing up

    if (showConfirmationDialog) {
      // Init phone number if default phone number exists
      const defaultPhone = userPreference?.defaultCommunication?.phone ?? "";
      if (defaultPhone && defaultPhone.startsWith("+1")) {
        setPhoneNumber(defaultPhone.replace("+", ""));
      }
      // Init the email if it's federated user and default email exists
      if (isFederatedUser) {
        const defaultEmail = userPreference?.defaultCommunication?.email ?? "";
        if (defaultEmail) {
          setEmailAddress(defaultEmail);
        }
      }
    }
  }, [isFederatedUser, showConfirmationDialog, userPreference]);

  const isValidPhoneNumber = useCallback((phone, ext) => {
    if (phone?.length === 11 && isNumberOnly(phone) && isNumberOnly(ext)) {
      return true;
    }
    return false;
  }, []);

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flexStart",
        }}
      >
        {status === DPUStatus.AVAILABLE ? (
          <React.Fragment>
            <div css={{ height: "2rem" }}>
              <Text bold color={Colors.donuts.BLUE}>
                {translateStatusLabel(t, status)}
              </Text>
            </div>
            <OneWayDistanceText
              t={t}
              oneWayDistanceKm={oneWayDistanceKm}
              oneWayDistanceMi={oneWayDistanceMi}
              countryCode={ultimateDestinationCountryCode}
            ></OneWayDistanceText>
            {isDealer(activeOrganization) ? (
              <Fragment>
                <div>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowConfirmationDialog(true);
                    }}
                  >
                    {t("driveaway-search:Submit Pick Up Request")}
                  </Button>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <DialogModal
                      show={showConfirmationDialog}
                      onHide={() => {
                        setShowConfirmationDialog(false);
                        setPhoneNumCountryInfo(defaultPhoneNumCountryInfo);
                        setPhoneNumber("");
                        setPhoneNumberExt("");
                        setEmailAddress("");
                      }}
                      title={t(
                        "driveaway-search:Submit Dealer Pick Up Request",
                      )}
                      cancelButtonText={t("driveaway-search:Cancel")}
                      submitButtonText={t("driveaway-search:Submit")}
                      submitButtonVariant="primary"
                      size="lg"
                      submitButtonDisabled={submitButtonDisabled}
                      onSubmit={() => {
                        // Right now backend doesn't store country code, to avoid future chaos this if block splits phone number
                        // into the following format: +X-XXXXXXXXXX
                        let modifiedPhoneNum = "";
                        if (!!phoneNumber && !!phoneNumCountryInfo?.dialCode) {
                          modifiedPhoneNum = `+${phoneNumber.slice(
                            0,
                            phoneNumCountryInfo.dialCode.length,
                          )}-${phoneNumber.slice(
                            phoneNumCountryInfo.dialCode.length,
                          )}`;
                        }
                        requestDriveAway(
                          internalId,
                          modifiedPhoneNum,
                          phoneNumberExt,
                          emailAddress.trim(),
                        );
                        setShowConfirmationDialog(false);
                      }}
                    >
                      <Text block>
                        {t(
                          "driveaway-search:By submitting this VIN for a pick up request, the associated carrier will have the opportunity to either approve or deny the request. If approved, a designated pick up window will be provided.",
                        )}
                      </Text>
                      <Text block style={{ marginTop: 10, marginBottom: 20 }}>
                        {t("driveaway-search:Are you sure you want to submit?")}
                      </Text>

                      <FormGroup css={{ marginBottom: "1rem" }}>
                        <Text>
                          {t(
                            "driveaway-search:Please enter contact phone number",
                          )}
                        </Text>
                        <div css={{ display: "flex" }}>
                          <PhoneInput
                            style={{ width: "16em" }}
                            inputStyle={{ width: "16em" }}
                            value={phoneNumber}
                            onChange={(newNumber, countryInfo) => {
                              setPhoneNumCountryInfo(countryInfo);
                              setPhoneNumber(newNumber);
                            }}
                            country={"us"}
                            onlyCountries={["us", "ca"]}
                            placeholder={"(555) 555-5555"}
                            countryCodeEditable={false}
                          />
                          <FormControl
                            css={{ width: "5em" }}
                            type="tel"
                            placeholder="ext."
                            onChange={(e) => {
                              setPhoneNumberExt(e.target.value);
                            }}
                          ></FormControl>
                        </div>
                      </FormGroup>
                      {isFederatedUser ? (
                        <FormGroup>
                          <Text>
                            {t("driveaway-search:Please enter email address")}
                          </Text>
                          <FormControl
                            css={{ width: "16em", marginBottom: "1rem" }}
                            type="email"
                            placeholder="Email Address"
                            value={emailAddress}
                            onChange={(e) => {
                              setEmailAddress(e.target.value);
                            }}
                          ></FormControl>
                        </FormGroup>
                      ) : null}
                      {isFederatedUser ? (
                        <Trans t={t}>
                          Set a default recipient email and phone number in the{" "}
                          <Link to={{ type: "PROFILE" }} target="_blank">
                            My Profile
                          </Link>{" "}
                          page.
                        </Trans>
                      ) : (
                        <Trans t={t}>
                          Set a default recipient phone number in the{" "}
                          <Link to={{ type: "PROFILE" }} target="_blank">
                            My Profile
                          </Link>{" "}
                          page.
                        </Trans>
                      )}
                      {!isNumberOnly(phoneNumber) ||
                      !isNumberOnly(phoneNumberExt) ? (
                        <Alert variant="warning">
                          <div>
                            {t(
                              `driveaway-search:Please enter only numeric values for phone number and extension.`,
                            )}
                          </div>
                        </Alert>
                      ) : null}
                      {isFederatedUser && !isValidEmail(emailAddress) ? (
                        <Alert variant="warning" css={{ marginTop: "1rem" }}>
                          <div>
                            {t(
                              `driveaway-search:Please enter a valid email address.`,
                            )}
                          </div>
                        </Alert>
                      ) : null}
                    </DialogModal>
                  </div>
                </div>
              </Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
        {status === DPUStatus.SUBMITTED ? (
          <React.Fragment>
            <Text bold color={Colors.text.YELLOW}>
              {translateStatusLabel(t, status)}
            </Text>
            <OneWayDistanceText
              t={t}
              oneWayDistanceKm={oneWayDistanceKm}
              oneWayDistanceMi={oneWayDistanceMi}
              countryCode={ultimateDestinationCountryCode}
            ></OneWayDistanceText>

            <Text style={{ overflowWrap: "break-word" }}>
              <Text bold>{t("driveaway-search:Dealer Email")}: </Text>
              {carrierEmail}
            </Text>
            <Text style={{ overflowWrap: "break-word" }}>
              <Text bold>{t("driveaway-search:Dealer Phone")}: </Text>
              {dealerPhoneArray && dealerPhoneArray[0]
                ? formatIntlPhoneNum(dealerPhoneArray[0])
                : null}
              {dealerPhoneArray && dealerPhoneArray[1]
                ? ` #${dealerPhoneArray[1]}`
                : null}
            </Text>
            {/* Eventhough below it says carrier time it is ddastatus.ts. */}
            <Text>
              {localizedDateFormatter(carrierTime)}{" "}
              {localizedTimeFormatter(carrierTime)}
            </Text>
            <div
              css={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <div css={{ alignSelf: "center" }}>
                {/* yellow */}
                {submittedAgingTime.hours < 24 ? (
                  <Icon
                    src={faClock}
                    size={FontSize.size28}
                    color={Colors.icon.YELLOW}
                    style={{ marginRight: "8px" }}
                  />
                ) : null}
                {/* red */}
                {submittedAgingTime.hours >= 24 ? (
                  <Icon
                    src={faClock}
                    size={FontSize.size28}
                    color={Colors.icon.RED}
                    style={{ marginRight: "8px" }}
                  />
                ) : null}
              </div>
              <div className="d-flex flex-column">
                {submittedAgingTime.hours > 24 ? (
                  <Text bold>{t("driveaway-search:Submitted Aging")}</Text>
                ) : null}
                <Text>
                  {submittedAgingTime.hours} {t("driveaway-search:hours")}
                </Text>
                <Text>
                  {submittedAgingTime.minutes} {t("driveaway-search:minutes")}
                </Text>
              </div>
            </div>
            {isCarrier(activeOrganization) ? (
              <div>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    fetchDenyReasons(solutionId);
                    setShowReviewRequestModal(
                      true,
                      id,
                      persistentApprovalEnabled,
                      pickUpWindowTzAbbr,
                      pickUpWindowTz,
                    );
                  }}
                >
                  {t("driveaway-search:Respond")}
                </Button>
              </div>
            ) : null}
            {isDealer(activeOrganization) ? (
              <div>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowCancelDialog(true);
                    setCancelComments(""); // clean up the comments section
                  }}
                >
                  {t("driveaway-search:Cancel")}
                </Button>
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
        {status === DPUStatus.APPROVED ? (
          <React.Fragment>
            <div>
              {!_.isNil(ddaCommentsCount) && ddaCommentsCount > 0 ? (
                <Tooltip
                  placement="top"
                  tooltipChildren={
                    <Text>{t("driveaway-search:Click to view comments.")}</Text>
                  }
                >
                  <Icon
                    src={faCommentAlt}
                    css={{ marginRight: 8 }}
                    color={Colors.comments.unreadCommentIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      fetchComments(id);
                      setShowCommentsDialog(true);
                    }}
                  />
                </Tooltip>
              ) : null}
              <Text bold color={Colors.text.GREEN}>
                {translateStatusLabel(t, status)}{" "}
                <Text className="mt-2" color={Colors.text.BLACK}>
                  (
                  <Text bold color="inherit">
                    {t("driveaway-search:ID")}:{" "}
                  </Text>
                  <Text color="inherit" style={{ wordBreak: "break-all" }}>
                    {approvalId}
                  </Text>
                  )
                </Text>
              </Text>
            </div>
            <OneWayDistanceText
              t={t}
              oneWayDistanceKm={oneWayDistanceKm}
              oneWayDistanceMi={oneWayDistanceMi}
              countryCode={ultimateDestinationCountryCode}
            ></OneWayDistanceText>
            <Text style={{ overflowWrap: "break-word", marginTop: "5px" }}>
              <Text bold>{t("driveaway-search:Dealer Email")}: </Text>
              {dealerEmail}
            </Text>
            <Text style={{ overflowWrap: "break-word" }}>
              <Text bold>{t("driveaway-search:Dealer Phone")}: </Text>
              {dealerPhoneArray && dealerPhoneArray[0]
                ? formatIntlPhoneNum(dealerPhoneArray[0])
                : null}
              {dealerPhoneArray && dealerPhoneArray[1]
                ? ` #${dealerPhoneArray[1]}`
                : null}
            </Text>
            <Text>
              {localizedDateFormatter(dealerTime)}{" "}
              {localizedTimeFormatter(dealerTime)}
            </Text>
            <Text style={{ overflowWrap: "break-word", marginTop: "5px" }}>
              <Text bold>{t("driveaway-search:Carrier Email")}: </Text>
              {carrierEmail}
            </Text>
            <Text>
              {localizedDateFormatter(carrierTime)}{" "}
              {localizedTimeFormatter(carrierTime)}
            </Text>
            <PickupWindowField
              pickupWindows={pickupWindow}
              pickUpWindowTz={pickUpWindowTz}
            ></PickupWindowField>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <DialogModal
                show={showCommentsDialog}
                onHide={() => setShowCommentsDialog(false)}
                title={t("driveaway-search:Comments")}
                loadStatus="success"
                showCloseButton={false}
                size="sm"
                okButtonText={t("driveaway-search:Close")}
                cancelButtonText=""
                submitButtonText=""
                onSubmit={() => null}
                centered
              >
                {errorFetchingDdaComments ? (
                  <ModalErrorIndicator></ModalErrorIndicator>
                ) : (
                  <Text css={{ wordBreak: "break-word" }}>{ddaComments}</Text>
                )}
                {!ddaComments && !errorFetchingDdaComments ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Icon src={faSpinner} spin size={FontSize.size32} />
                  </div>
                ) : null}
              </DialogModal>
            </div>
            <div
              css={{
                marginTop: "1em",
                display: "flex",
                flexFlow: "row wrap",
                columnGap: "0.5em",
                rowGap: "0.3em",
              }}
            >
              <DealerPickUpFormButton
                activeOrganization={activeOrganization}
                exportPDFEntities={exportPDFEntities}
                approvalId={approvalId}
                solutionId={solutionId}
                userPreference={userPreference}
                isExportingPDF={isExportingPDF}
              />

              {isCarrier(activeOrganization) ? (
                <Fragment>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowReviewRequestModal(
                        true,
                        id,
                        persistentApprovalEnabled,
                        pickUpWindowTzAbbr,
                        pickUpWindowTz,
                        pickupWindow,
                        false,
                      );
                    }}
                  >
                    {t("driveaway-search:Modify")}
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowCancelDialog(true);
                      setCancelComments(""); // clean up the comments section
                    }}
                  >
                    {t("driveaway-search:Cancel")}
                  </Button>
                </Fragment>
              ) : null}
              {isDealer(activeOrganization) ? (
                <Fragment>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowCancelDialog(true);
                      setCancelComments(""); // clean up the comments section
                    }}
                  >
                    {t("driveaway-search:Cancel")}
                  </Button>

                  <Button
                    css={{ marginTop: "0.5em" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowPickUpConfirmationDialog(true);
                    }}
                  >
                    {t("driveaway-search:Dealer Pick Up Confirmation")}
                  </Button>
                </Fragment>
              ) : null}
            </div>
          </React.Fragment>
        ) : null}
        {status === DPUStatus.DENIED ? (
          <React.Fragment>
            <Text color={Colors.text.RED} bold>
              {translateStatusLabel(t, status)}
            </Text>
            <OneWayDistanceText
              t={t}
              oneWayDistanceKm={oneWayDistanceKm}
              oneWayDistanceMi={oneWayDistanceMi}
              countryCode={ultimateDestinationCountryCode}
            ></OneWayDistanceText>
            <Text>
              <Text bold>{t("driveaway-search:Reason")}:</Text>
              <Text>
                <Text> {denyCodeToReadableFormat(t, reason)}</Text>
                {ddaCommentsCount === 1 ? (
                  <Tooltip
                    placement="top"
                    tooltipChildren={
                      <Text>
                        {t("driveaway-search:Click to view comments.")}
                      </Text>
                    }
                  >
                    <Icon
                      src={faQuestionCircle}
                      style={{ marginLeft: 8, alignSelf: "center" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        fetchComments(id);
                        setShowCommentsDialog(true);
                      }}
                    />
                  </Tooltip>
                ) : !_.isNil(reasonsDescription) ||
                  !_.isNil(reasonExplanation) ? (
                  <Tooltip
                    placement="top"
                    tooltipChildren={
                      <Fragment>
                        {reasonsDescription ? (
                          <Text>
                            {translateDenyReason(t, reasonsDescription)}
                          </Text>
                        ) : null}
                        {reasonExplanation ? (
                          <Text>
                            {" "}
                            ({translateReasonExplanation(t, reasonExplanation)})
                          </Text>
                        ) : null}
                      </Fragment>
                    }
                  >
                    <Icon
                      src={faQuestionCircle}
                      style={{ marginLeft: 8, alignSelf: "center" }}
                    />
                  </Tooltip>
                ) : null}
              </Text>
            </Text>
            <Text style={{ overflowWrap: "break-word", marginTop: "5px" }}>
              <Text bold>{t("driveaway-search:Dealer Email")}: </Text>
              {dealerEmail}
            </Text>
            <Text>
              {localizedDateFormatter(dealerTime)}{" "}
              {localizedTimeFormatter(dealerTime)}
            </Text>
            <Text style={{ overflowWrap: "break-word" }}>
              <Text bold>{t("driveaway-search:Carrier Email")}: </Text>
              {carrierEmail}
            </Text>
            <Text>
              {localizedDateFormatter(carrierTime)}{" "}
              {localizedTimeFormatter(carrierTime)}
            </Text>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <DialogModal
                show={showCommentsDialog}
                onHide={() => setShowCommentsDialog(false)}
                title={t("driveaway-search:Comments")}
                loadStatus="success"
                showCloseButton={false}
                size="sm"
                okButtonText={t("driveaway-search:Close")}
                cancelButtonText=""
                submitButtonText=""
                onSubmit={() => null}
                centered
              >
                {errorFetchingDdaComments ? (
                  <ModalErrorIndicator></ModalErrorIndicator>
                ) : (
                  <Text>{ddaComments}</Text>
                )}

                {!ddaComments && !errorFetchingDdaComments ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Icon src={faSpinner} spin size={FontSize.size32} />
                  </div>
                ) : null}
              </DialogModal>
            </div>
            {isCarrier(activeOrganization) ? (
              <div>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowReviewRequestModal(
                      true,
                      id,
                      persistentApprovalEnabled,
                      pickUpWindowTzAbbr,
                      pickUpWindowTz,
                      undefined,
                      false,
                    );
                  }}
                >
                  {t("driveaway-search:Approve")}
                </Button>
                <Button
                  css={{
                    marginLeft: "10px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    fetchDenyReasons(solutionId);
                    setShowReviewRequestModal(
                      true,
                      id,
                      persistentApprovalEnabled,
                      pickUpWindowTzAbbr,
                      pickUpWindowTz,
                      undefined,
                      true,
                      true,
                      reason,
                    );
                  }}
                >
                  {t("driveaway-search:Modify")}
                </Button>
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
        {status === DPUStatus.DELIVERED ? (
          <React.Fragment>
            <Text color={Colors.text.GREEN} bold>
              {translateStatusLabel(t, status)}
            </Text>
            <OneWayDistanceText
              t={t}
              oneWayDistanceKm={oneWayDistanceKm}
              oneWayDistanceMi={oneWayDistanceMi}
              countryCode={ultimateDestinationCountryCode}
            ></OneWayDistanceText>
            <Text style={{ overflowWrap: "break-word", marginTop: "5px" }}>
              <Text bold>{t("driveaway-search:Dealer Email")}: </Text>
              {dealerEmail}
            </Text>
            <Text style={{ overflowWrap: "break-word" }}>
              <Text bold>{t("driveaway-search:Dealer Phone")}: </Text>
              {dealerPhoneArray && dealerPhoneArray[0]
                ? formatIntlPhoneNum(dealerPhoneArray[0])
                : null}
              {dealerPhoneArray && dealerPhoneArray[1]
                ? ` #${dealerPhoneArray[1]}`
                : null}
            </Text>
            <Text>
              {localizedDateFormatter(dealerTime)}{" "}
              {localizedTimeFormatter(dealerTime)}
            </Text>
            <Text style={{ overflowWrap: "break-word", marginTop: "5px" }}>
              <Text bold>{t("driveaway-search:Carrier Email")}: </Text>
              {carrierEmail}
            </Text>
            <Text>
              {localizedDateFormatter(carrierTime)}{" "}
              {localizedTimeFormatter(carrierTime)}
            </Text>
            <PickupWindowField
              pickupWindows={pickupWindow}
              pickUpWindowTz={pickUpWindowTz}
            ></PickupWindowField>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <DialogModal
                show={showCommentsDialog}
                onHide={() => setShowCommentsDialog(false)}
                title={t("driveaway-search:Comments")}
                loadStatus="success"
                showCloseButton={false}
                size="sm"
                okButtonText={t("driveaway-search:Close")}
                cancelButtonText=""
                submitButtonText=""
                onSubmit={() => null}
                centered
              >
                {errorFetchingDdaComments ? (
                  <ModalErrorIndicator></ModalErrorIndicator>
                ) : (
                  <Text css={{ wordBreak: "break-word" }}>{ddaComments}</Text>
                )}
                {!ddaComments && !errorFetchingDdaComments ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Icon src={faSpinner} spin size={FontSize.size32} />
                  </div>
                ) : null}
              </DialogModal>
            </div>

            {(isShipper(activeOrganization) || isDealer(activeOrganization)) &&
            !_.isNil(confirmationEmail) &&
            !_.isNil(confirmationTs) ? (
              <Fragment>
                <Text
                  bold
                  style={{ overflowWrap: "break-word", marginTop: "5px" }}
                >
                  {t("driveaway-search:Dealer Pick Up Confirmation")}:{" "}
                </Text>

                <Text>{confirmationEmail}</Text>
                <Text>
                  {localizedDateFormatter(confirmationTs)}{" "}
                  {localizedTimeFormatter(confirmationTs)}
                </Text>
              </Fragment>
            ) : null}
            {previousStatus && previousStatus === DPUStatus.APPROVED ? (
              <div
                css={{
                  marginTop: "1em",
                  display: "flex",
                  flexFlow: "row wrap",
                  columnGap: "0.5em",
                  rowGap: "0.3em",
                }}
              >
                <DealerPickUpFormButton
                  activeOrganization={activeOrganization}
                  exportPDFEntities={exportPDFEntities}
                  approvalId={approvalId}
                  solutionId={solutionId}
                  userPreference={userPreference}
                  isExportingPDF={isExportingPDF}
                />
              </div>
            ) : null}
            {(isShipper(activeOrganization) || isDealer(activeOrganization)) &&
            _.isNil(confirmationEmail) &&
            _.isNil(confirmationTs) ? (
              <div
                css={{
                  display: "flex",
                  flexFlow: "row wrap",
                  columnGap: "0.5em",
                  rowGap: "0.3em",
                }}
              >
                {isDealer(activeOrganization) ? (
                  <Button
                    css={{ marginTop: "0.5em" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowPickUpConfirmationDialog(true);
                    }}
                  >
                    {t("driveaway-search:Dealer Pick Up Confirmation")}
                  </Button>
                ) : null}
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
        {isShipper(activeOrganization) ? (
          <div
            css={{
              marginTop: "1em",
              display: "flex",
              flexFlow: "row wrap",
              columnGap: "0.5em",
              rowGap: "0.3em",
            }}
          >
            <Button
              variant="secondary"
              onClick={(e) => {
                setShowStatusHistoryDialog(true);
                fetchStatusHistory(internalId, solutionId);
                e.stopPropagation();
              }}
            >
              {t("driveaway-search:Status History")}
            </Button>
          </div>
        ) : null}
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <DialogModal
          show={showStatusHistoryDialog}
          onHide={() => setShowStatusHistoryDialog(false)}
          title={`${t("driveaway-search:Status History for VIN")}: ${vin}`}
          loadStatus="success"
          showCloseButton={false}
          size="lg"
          okButtonText={t("driveaway-search:Close")}
          cancelButtonText=""
          submitButtonText=""
          onSubmit={() => null}
          centered
        >
          {statusHistoryFetchStatus === "FAILED" ? (
            <ModalErrorIndicator></ModalErrorIndicator>
          ) : null}
          {statusHistoryFetchStatus === "IN_PROGRESS" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "2em",
              }}
            >
              <Icon src={faSpinner} spin size={FontSize.size32} />
            </div>
          ) : null}
          {statusHistoryFetchStatus === "SUCCESS" && !_.isNil(statusHistory) ? (
            <BaseTable
              theme={Themes.LIGHT}
              columns={[
                {
                  Header: t("driveaway-search:Event time"),
                  Cell: (cellInfo) => {
                    return (
                      <DateTimeCell
                        localize
                        dateTime={cellInfo.row.original.eventTs}
                      />
                    );
                  },
                  accessor: "eventTs",
                  width: 85,
                },
                {
                  Header: t("driveaway-search:Status"),
                  Cell: StatusHistoryStatusCell,
                  accessor: (d) => {
                    return {
                      status: d.status,
                      denyReasonCode: d.denyReasonCode,
                    };
                  },
                  width: 120,
                },
                {
                  Header: t("driveaway-search:DPU Location"),
                  Cell: DpuLocationCell,
                  accessor: "dpuLocation",
                  width: 100,
                },
                {
                  Header: t("driveaway-search:Last Milestone"),
                  Cell: LastMileStoneCell,
                  accessor: "milestone",
                  width: 125,
                },
                {
                  Header: t("driveaway-search:Modified By"),
                  Cell: ModifiedByCell,
                  accessor: "modifiedBy",
                },
                {
                  Header: t("driveaway-search:Comments"),
                  Cell: CommentCell,
                  accessor: "comment",
                },
              ]}
              data={statusHistory}
            />
          ) : null}
        </DialogModal>
      </div>
      <CancelModal
        id={id}
        showCancelDialog={showCancelDialog}
        setShowCancelDialog={setShowCancelDialog}
        setCancelComments={setCancelComments}
        cancelComments={cancelComments}
        cancelDriveAway={cancelDriveAway}
        t={t}
      />
      <DealerPickUpConfirmationModal
        internalId={internalId}
        showPickUpConfirmationDialog={showPickUpConfirmationDialog}
        setShowPickUpConfirmationDialog={setShowPickUpConfirmationDialog}
        confirmPickUpRequest={confirmPickUpRequest}
        t={t}
      />
    </div>
  );
};

const CancelModal = (props) => {
  const {
    id,
    showCancelDialog,
    setShowCancelDialog,
    cancelDriveAway,
    cancelComments,
    setCancelComments,
    t,
  } = props;
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  useEffect(() => {
    if (showCancelDialog) {
      setSubmitButtonDisabled(true);
      setCancelComments("");
    }
  }, [showCancelDialog, setCancelComments]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogModal
        show={showCancelDialog}
        onHide={() => setShowCancelDialog(false)}
        title={t("driveaway-search:Cancel Dealer Pick Up Request")}
        cancelButtonText={t("driveaway-search:Cancel")}
        submitButtonText={t("driveaway-search:Submit")}
        submitButtonVariant="primary"
        submitButtonDisabled={submitButtonDisabled}
        size="lg"
        onSubmit={() => {
          cancelDriveAway(id, { status: "canceled", comments: cancelComments });
          setShowCancelDialog(false);
        }}
      >
        <Text block>
          {t(
            "driveaway-search:By cancelling this dealer pick up request, you will be changing the VIN back to Available status for a further pick up request to be submitted.",
          )}
        </Text>
        <Text block style={{ marginTop: 10, marginBottom: 15 }}>
          {t("driveaway-search:Are you sure you want to cancel?")}
        </Text>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <FormLabel style={{ margin: 0 }}>
            <RequiredLabel label={t("Additional Comments")} />
          </FormLabel>
          <FormControl
            as="textarea"
            rows={4}
            value={cancelComments}
            onChange={(e) => {
              setCancelComments(e.target.value);
              let isCommentInvalid = e.target.value.trim() === "";
              setSubmitButtonDisabled(isCommentInvalid);
            }}
          />
        </FormGroup>
      </DialogModal>
    </div>
  );
};

CancelModal.propTypes = {
  id: PropTypes.string,
  showCancelDialog: PropTypes.bool,
  setShowCancelDialog: PropTypes.func,
  cancelDriveAway: PropTypes.func,
  cancelComments: PropTypes.string,
  setCancelComments: PropTypes.func,
  t: PropTypes.func,
};

const DealerPickUpConfirmationModal = (props) => {
  const {
    internalId,
    showPickUpConfirmationDialog,
    setShowPickUpConfirmationDialog,
    confirmPickUpRequest,
    t,
  } = props;

  const requestdriveawayDebounced = useMemo(
    () =>
      _.debounce(confirmPickUpRequest, 100, {
        trailing: true,
      }),
    [],
  );

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogModal
        show={showPickUpConfirmationDialog}
        onHide={() => setShowPickUpConfirmationDialog(false)}
        title={t("driveaway-search:Pick Up Confirmation")}
        cancelButtonText={t("driveaway-search:Cancel")}
        submitButtonText={t("driveaway-search:Submit")}
        submitButtonVariant="primary"
        size="sm"
        onSubmit={() => {
          requestdriveawayDebounced(internalId);
          setShowPickUpConfirmationDialog(false);
        }}
      >
        <Text block>
          {t("driveaway-search:Confirm that the vehicle has been picked up.")}
        </Text>
      </DialogModal>
    </div>
  );
};

StatusCell.propTypes = {
  activeOrganization: PropTypes.object,
  value: PropTypes.shape({
    status: PropTypes.string,
    previousStatus: PropTypes.string,
    reason: PropTypes.string,
    reasonsDescription: PropTypes.string,
    carrierTime: PropTypes.string,
    deniedTime: PropTypes.string,
    carrierEmail: PropTypes.string,
    dealerTime: PropTypes.string,
    dealerEmail: PropTypes.string,
    id: PropTypes.string,
    internalId: PropTypes.number,
    requestDriveAway: PropTypes.func,
    cancelDriveAway: PropTypes.func,
    pickupWindow: PropTypes.array,
    fetchDenyReasons: PropTypes.func,
    solutionId: PropTypes.string,
    approvalId: PropTypes.string,
    setShowReviewRequestModal: PropTypes.func,
    pickUpWindowTzAbbr: PropTypes.string,
    pickUpWindowTz: PropTypes.string,
    isExportingPDF: PropTypes.bool,
    exportPDFEntities: PropTypes.func,
    ddaCommentsCount: PropTypes.number,
    ddaComments: PropTypes.string,
    fetchComments: PropTypes.func,
    errorFetchingDdaComments: PropTypes.bool,
    fetchStatusHistory: PropTypes.func,
    statusHistory: PropTypes.array,
    statusHistoryFetchStatus: PropTypes.string,
    vin: PropTypes.string,
    ultimateDestinationCountryCode: PropTypes.string,
    oneWayDistanceKm: PropTypes.number,
    oneWayDistanceMi: PropTypes.number,
    subStatus: PropTypes.any,
    persistentApprovalEnabled: PropTypes.bool,
  }),
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const LastUpdateCell = (props) => {
  const {
    mode,
    lastPosition,
    lastProgress,
    currentLocation,
    canDisplayConnectedCarSource,
    t,
  } = props.value;

  let isOnShipment = false;

  if (lastPosition?.currentPositionType !== t("driveaway-search:At Location")) {
    isOnShipment = true;
  }

  let city = null;
  let state = null;

  if (lastPosition) {
    city = lastPosition?.city?.trim();
    state = lastPosition?.state?.trim();
  }

  let cityAndState = null;
  if (city || state) {
    if (city && !state) {
      cityAndState = city;
    } else if (state && !city) {
      cityAndState = state;
    } else {
      cityAndState = `${city}, ${state}`;
    }
  }

  let modeType = mode;
  if (lastPosition?.currentPositionType === "On Rail") {
    modeType = "Rail";
  } else if (lastPosition?.currentPositionType === "On the Road") {
    modeType = "Truck";
  } else if (lastPosition?.currentPositionType === "On the Water") {
    modeType = "Ocean";
  }

  let source = lastPosition?.sourceType;
  if (source === SourceType.SHIPMENT) {
    source = t("driveaway-search:Shipment");
  } else if (source === SourceType.CONNECTED_CAR) {
    source = t("driveaway-search:Connected Car");
  } else if (source === SourceType.MILESTONE) {
    source = t("driveaway-search:Milestone");
  }

  // H2-3038: on VinView suppress source field per customer configuration
  const canDisplaySource =
    source &&
    (lastPosition?.sourceType !== SourceType.CONNECTED_CAR ||
      canDisplayConnectedCarSource);

  if (
    isOnShipment &&
    (lastProgress?.eventTs || cityAndState || lastPosition?.sourceType)
  ) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <div className="d-flex align-items-center flex-wrap">
          {modeType ? (
            <Tooltip
              placement="top"
              tooltipChildren={<Text>{modeType}</Text>}
              style={{ marginRight: "0.5em" }}
            >
              <MadChiclet
                shipmentMode={modeType}
                stopMode={modeType}
                activeException={null}
                hideBorder={true}
                height={24}
                width={24}
              />
            </Tooltip>
          ) : null}
          <Text>
            {lastPosition?.currentPositionType ??
              t("driveaway-search:On Shipment")}
          </Text>
        </div>

        {lastPosition?.datetime ? (
          <div className="d-flex flex-wrap">
            <Text>
              {`${localizedTimeFormatter(
                lastPosition?.datetime,
              )} ${localizedDateFormatter(lastPosition?.datetime)}`}
            </Text>
          </div>
        ) : null}

        {/* City/State */}
        {cityAndState ? <Text>{cityAndState}</Text> : null}

        {/* Departing Location */}
        {source === t("driveaway-search:Shipment") ||
        source === t("driveaway-search:Milestone") ? (
          <React.Fragment>
            {lastPosition?.currentPositionName ? (
              <Text>{lastPosition?.currentPositionName}</Text>
            ) : null}
          </React.Fragment>
        ) : null}
        {/* Source */}
        {canDisplaySource ? (
          <React.Fragment>
            <Text>
              <Text bold>{t("driveaway-search:Source")}</Text> - {source}
            </Text>
          </React.Fragment>
        ) : null}
      </div>
    );
  } else if (
    !isOnShipment &&
    (lastPosition?.datetime || currentLocation?.name || cityAndState)
  ) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <Text>{t("driveaway-search:At Location")}</Text>
        {/* Location Name */}
        {lastPosition?.currentPositionName ? (
          <Text>{lastPosition?.currentPositionName}</Text>
        ) : null}
        {/* City/State */}
        {cityAndState ? <Text>{cityAndState}</Text> : null}
        {/* Source */}
        {canDisplaySource ? (
          <React.Fragment>
            <Text>
              <Text bold>{t("driveaway-search:Source")}</Text> - {source}
            </Text>
          </React.Fragment>
        ) : null}
        {/* Arrival Time */}
        {lastPosition?.datetime ? (
          <div className="d-flex flex-wrap">
            {/* <Text>Departed At:</Text> */}
            <Text>
              {`${localizedTimeFormatter(
                lastPosition?.datetime,
              )} ${localizedDateFormatter(lastPosition?.datetime)}`}
            </Text>
          </div>
        ) : null}
      </div>
    );
  }

  return null;
};

LastUpdateCell.propTypes = {
  value: PropTypes.shape({
    lastPosition: PropTypes.object,
    lastProgress: PropTypes.object,
    state: PropTypes.string,
    mode: PropTypes.string,
    currentLocation: PropTypes.object,
    canDisplayConnectedCarSource: PropTypes.bool,
    t: PropTypes.func,
  }).isRequired,
};

const LocationCell = (props) => {
  const {
    location,
    type,
    eta,
    tripPlanCompleteTs,
    ddaStatus,
    manualEtaRangeStart,
    manualEtaRangeEnd,
  } = props.value;
  const { t } = useTranslation("driveaway-search");
  const { getEtaTranslation, isEtaName } = useEtaTranslations();

  if (_.isNil(location)) {
    return <div />;
  }

  const isPickUpOr =
    type === t("driveaway-search:origin")
      ? t("driveaway-search:Pickup")
      : t("driveaway-search:Delivery");

  const timeToDisplay = location.arrived
    ? location.arrived
    : tripPlanCompleteTs;

  let etaElement = <DateTime dateTime={eta} plain localize />;
  if (isEtaName(eta)) {
    etaElement = (
      <Text>
        {getEtaTranslation(eta, manualEtaRangeStart, manualEtaRangeEnd)}
      </Text>
    );
  }

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text bold>{location.code}</Text>
      <Text>{location.name}</Text>
      {location.city && location.state ? (
        <Text>{`${location.city}, ${location.state}`}</Text>
      ) : null}
      {timeToDisplay ? (
        <React.Fragment>
          <Text bold underline>
            {t("driveaway-search:Actual")} {isPickUpOr}
          </Text>
          <DateTime plain localize dateTime={timeToDisplay} />
        </React.Fragment>
      ) : null}
      {!timeToDisplay &&
      eta &&
      !ddaStatus?.status.toLowerCase().includes("delivered") ? (
        <div css={{ display: "flex", flexDirection: "row" }}>
          <Text
            bold
            style={{
              marginRight: 5,
            }}
          >
            {t("driveaway-search:ETA")}:
          </Text>
          {etaElement}
        </div>
      ) : null}
    </div>
  );
};

LocationCell.propTypes = {
  value: PropTypes.object.isRequired,
};

export const columns = (
  t,
  selectedDealerOrgId,
  driveAwaySearchResults,
  requestDriveAway,
  cancelDriveAway,
  confirmPickUpRequest,
  driveAwayReviewRequest,
  driveAwayDenyReasons,
  fetchDenyReasons,
  solutionId,
  setShowReviewRequestModal,
  isExportingPDF,
  exportPDFEntities,
  ddaComments,
  fetchComments,
  errorFetchingDdaComments,
  fetchStatusHistory,
  statusHistory,
  statusHistoryFetchStatus,
  userPreference,
) => {
  const results = [
    {
      Header: t("driveaway-search:Status"),
      id: "status",
      minWidth: 340,
      disableSortBy: true,
      disableResizing: true,
      Cell: StatusCell,
      accessor: (d) => {
        return {
          status: d.ddaStatus?.status,
          previousStatus: d.ddaStatus?.previousStatus,
          reason: d.ddaStatus?.reason,
          reasonsDescription: d.ddaStatus?.reason_description,
          reasonExplanation: d.ddaStatus?.reason_explanation,
          dealerPhone: d.ddaStatus?.dealerPhoneNumber,
          carrierTime: d.ddaStatus?.ts,
          carrierEmail: d.ddaStatus?.email,
          dealerTime: d.ddaStatus?.requestor?.ts,
          dealerEmail: d.ddaStatus?.requestor?.email,
          deniedTime: d.ddaStatus?.ts,
          id: String(d.ddaStatus?.id),
          internalId: d.internal_id,
          requestDriveAway: requestDriveAway,
          cancelDriveAway: cancelDriveAway,
          confirmPickUpRequest: confirmPickUpRequest,
          pickupWindow: d.ddaStatus?.workflow_approval_pickup_windows,
          driveAwayReviewRequest: driveAwayReviewRequest,
          driveAwayDenyReasons: driveAwayDenyReasons,
          fetchDenyReasons: fetchDenyReasons,
          solutionId: d.solution_id,
          approvalId: String(d.ddaStatus?.approvalId),
          setShowReviewRequestModal: setShowReviewRequestModal,
          pickUpWindowTzAbbr: d.ddaStatus?.pickup_window_tz_abbreviated,
          pickUpWindowTz: d.ddaStatus?.pickup_window_tz,
          isExportingPDF: isExportingPDF,
          exportPDFEntities: exportPDFEntities,
          ddaCommentsCount: d.ddaStatus?.commentsCount,
          ddaComments: ddaComments,
          fetchComments: fetchComments,
          errorFetchingDdaComments: errorFetchingDdaComments,
          fetchStatusHistory: fetchStatusHistory,
          statusHistory: statusHistory,
          statusHistoryFetchStatus: statusHistoryFetchStatus,
          userPreference: userPreference,
          vin: d.id,
          ultimateDestinationCountryCode: d?.ultimateDestination?.country,
          oneWayDistanceKm: d.ddaStatus?.oneWayDistanceKm,
          oneWayDistanceMi: d.ddaStatus?.oneWayDistanceMi,
          confirmationTs: d.ddaStatus?.confirmationTs,
          confirmationEmail: d.ddaStatus?.confirmationEmail,
          subStatus: d.ddaStatus?.substatus,
          persistentApprovalEnabled: d.ddaStatus?.persistentApprovalEnabled,
        };
      },
    },
    {
      Header: t("driveaway-search:VIN"),
      id: "dda_vin",
      Cell: VinCell,
      disableSortBy: true,
      accessor: (d) => {
        return {
          id: d.id,
          description: d.description,
          references: d.references,
          commentsCount: d.commentsCount,
          carrier: d.carrier,
          authorizedToShipDate: d.ddaStatus?.shippingInfo?.authorizedDate,
          dayCount: d.ddaStatus?.shippingInfo?.dayCount,
          solutionId: d.solution_id,
        };
      },
      minWidth: 200,
    },
    {
      Header: t("driveaway-search:Last Milestone"),
      id: "lastMilestone",
      minWidth: 125,
      disableSortBy: true,
      Cell: (cellInfo) => {
        return (
          <VinMilestoneCell
            lastMilestone={cellInfo.row.original.lastStatusUpdate}
          />
        );
      },
    },
    {
      Header: t("driveaway-search:Last Update"),
      id: "activeShipment",
      minWidth: 125,
      disableSortBy: true,
      Cell: LastUpdateCell,
      accessor: (details) => {
        return {
          mode: details.activeTransportMode,
          lastPosition: getCurrentPositionDetails(details, t),
          lastProgress: details.lastProgressUpdate,
          currentLocation: details.locationData?.current,
          canDisplayConnectedCarSource:
            details?.configurations?.displayConnectedCarSource,
          t: t,
        };
      },
    },
    {
      Header: t("driveaway-search:Origin Details"),
      id: "origin",
      minWidth: 135,
      disableSortBy: true,
      Cell: LocationCell,
      accessor: (d) => {
        return {
          location: d.ultimateOrigin,
          type: "origin",
        };
      },
    },
    {
      Header: t("driveaway-search:Destination Details"),
      id: "destinationTime",
      minWidth: 135,
      disableSortBy: true,
      Cell: LocationCell,
      accessor: (d) => {
        let eta = null;
        // If the VIN is not delivered, show the ETA.
        if (d?.lifeCycleState !== "Delivered") {
          // `lastEntityProgressUpdate.destinationEta` is the source of the ETA for VINView if an etaDateRange is not avaliable.
          // The API will handle which ETA to show (FV, HERE, Smart, etc) in this field.
          eta = d?.lastEntityProgressUpdate?.destinationEta;
        }

        return {
          location: d.ultimateDestination,
          type: "destination",
          eta: eta,
          t: t,
          tripPlanCompleteTs: d?.tripPlanCompleteTs,
          ddaStatus: d.ddaStatus,
          manualEtaRangeStart: d?.manualEtaRangeStart,
          manualEtaRangeEnd: d?.manualEtaRangeEnd,
        };
      },
    },
  ];

  return results;
};

function mapStateToProps(state) {
  return {
    activeOrganization: getActiveOrganization(state),
  };
}

StatusCell = connect(mapStateToProps)(StatusCell);
