import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { env } from "../env";
import ApplicationConfig from "../application-config";

const DATADOG_SERVICE_NAME = "web-portal";

const datadogEnabled =
  // for production builds
  env.NODE_ENV === "production" &&
  // where configuration is valid
  env.REACT_APP_DATADOG_APPLICATION_ID &&
  env.REACT_APP_DATADOG_CLIENT_TOKEN &&
  env.REACT_APP_DATADOG_SITE &&
  // not the public portal (temporary for the trial period)
  !env.REACT_APP_IS_PUBLIC &&
  // not from a local machine
  window.location.hostname !== "localhost" &&
  // in valid environments (all but prod, prod-a, and test)
  !ApplicationConfig.isEnvironment("prod", "proda", "test");

export function initDatadog() {
  if (datadogEnabled) {
    // Browser log collection
    // https://docs.datadoghq.com/logs/log_collection/javascript/#initialization-parameters
    datadogLogs.init({
      clientToken: env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: env.REACT_APP_DATADOG_SITE,
      service: DATADOG_SERVICE_NAME,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });

    // User tracking
    // https://docs.datadoghq.com/real_user_monitoring/browser/setup/client?tab=rum#initialization-parameters
    datadogRum.init({
      applicationId: env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: env.REACT_APP_DATADOG_CLIENT_TOKEN,
      service: DATADOG_SERVICE_NAME,
      env: env.REACT_APP_ENVIRONMENT,
      site: env.REACT_APP_DATADOG_SITE,
      defaultPrivacyLevel: "mask-user-input",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      enablePrivacyForActionName: true,
      // TODO: Allow user to opt-in to tracking. Default value is "granted"
      // trackingConsent: "granted"
    });
  }
}
