import { connect } from "react-redux";

import { Subscribe } from "./subscribe.page";
import SubscribeState from "./redux/SubscribeState";

function mapStateToProps(state) {
  return {
    guid: state.location.payload.guid,
    category: state.location.payload.category,
    subscription: SubscribeState.selectors.getSubscription(state),
    organizationImageConfig:
      SubscribeState.selectors.getOrganizationImageConfig(state),
    isFetchingSubscription:
      SubscribeState.selectors.getIsFetchingSubscription(state),
    isFetchSubscriptionError:
      SubscribeState.selectors.getIsFetchSubscriptionError(state),
    subscriptionErrorMessage:
      SubscribeState.selectors.getFetchSubscriptionErrorMessage(state),
    isSubscribeComplete: SubscribeState.selectors.getIsSubscribeComplete(state),
    isSubscribing: SubscribeState.selectors.getIsSubscribing(state),
    isSubscribeError: SubscribeState.selectors.getIsSubscribeError(state),
    subscribeErrorMessage:
      SubscribeState.selectors.getSubscribeErrorMessage(state),
  };
}

const {
  resetSubscribe,
  fetchSubscription,
  subscribe,
  fetchOrganizationImages,
} = SubscribeState.actionCreators;

const actionCreators = {
  resetSubscribe,
  fetchSubscription,
  subscribe,
  fetchOrganizationImages,
};

export default connect(mapStateToProps, actionCreators)(Subscribe);
