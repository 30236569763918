import { InferType } from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  MdErrorOutline,
  MdFileDownload,
  MdFileUpload,
  MdHourglassEmpty,
  MdLockOutline,
  MdTimer,
} from "react-icons/md";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { FiEye } from "react-icons/fi";
import {
  faLocationDotSlash,
  faRoute,
  faTireFlat,
  faUndoAlt,
} from "@fortawesome/pro-solid-svg-icons";

import { IconType } from "components/atoms/enums";
import { IconPropTypes } from "components/atoms/Icon.atom";
import Colors from "styles/colors";
import { formatDuration } from "utils/date-time";
import { logWarningForMissingTranslation } from "utils/log-warning.utils";
import { ShipmentException } from "shared/constants/shipments.const";

/**
 * The exception representation received from the backend.
 *
 * /shipping-ng/exception_filter
 */
type ShipmentExceptionOption = {
  id: number;
  name: ShipmentException;
};

/**
 * Combines exception types and counts to a format for ExceptionPanel and ExceptionCountGroup.
 *
 * @param exceptionTotals The API data for exception counts.
 * @param exceptionTypes The list of exceptions available.
 * @returns The counts for all shipments and each exception type.
 */
export const getExceptionData = (
  exceptionTotals: { [x: string]: number } = {},
  exceptionTypes: ShipmentExceptionOption[] = [],
) => {
  const totals = exceptionTypes.map((exceptionType) => {
    let count;
    switch (exceptionType.name) {
      case ShipmentException.BEHIND_SCHEDULE:
        count = exceptionTotals.total_behind_schedule;
        break;
      case ShipmentException.MISSED_PICKUP:
        count = exceptionTotals.total_missed_pickup;
        break;
      case ShipmentException.MISSED_DROP_OFF:
        count = exceptionTotals.total_missed_dropoff;
        break;
      case ShipmentException.CARRIER_DELAYED:
        count = exceptionTotals.total_carrier_delayed;
        break;
      case ShipmentException.SHIPPER_DELAYED:
        count = exceptionTotals.total_shipper_delayed;
        break;
      case ShipmentException.BACKORDER:
        count = exceptionTotals.total_backorder;
        break;
      case ShipmentException.LOST:
        count = exceptionTotals.total_lost;
        break;
      case ShipmentException.IDLE_TRAIN:
        count = exceptionTotals.total_idle_train;
        break;
      case ShipmentException.BAD_ORDER:
        count = exceptionTotals.total_bad_order;
        break;
      case ShipmentException.IN_HOLD:
        count = exceptionTotals.total_in_hold;
        break;
      case ShipmentException.UNDER_REVIEW:
        count = exceptionTotals.total_under_review;
        break;
      case ShipmentException.OFF_ROUTE:
        count = exceptionTotals.total_off_route;
        break;
      case ShipmentException.NO_ACTIVITY:
        count = exceptionTotals.total_no_activity;
        break;
      default:
        count = 0;
    }

    return {
      ...exceptionType,
      count: count ?? 0,
    };
  });

  return {
    exceptions: sortExceptions(totals),
    totalShipments: exceptionTotals.total_shipments,
  };
};

/**
 * Sorts a list of exception types.
 *
 * Used to get the desired order of exception counts for
 * Shipment Exceptions dashboard widget and saved search cards.
 *
 * @param exceptions A list of exception types.
 * @returns The sorted list.
 */

export const sortExceptions = (exceptions: ShipmentExceptionOption[] = []) => {
  const order = [
    ShipmentException.BEHIND_SCHEDULE,
    ShipmentException.MISSED_PICKUP,
    ShipmentException.MISSED_DROP_OFF,
    ShipmentException.CARRIER_DELAYED,
    ShipmentException.SHIPPER_DELAYED,
    ShipmentException.BACKORDER,
    ShipmentException.LOST,
    ShipmentException.IDLE_TRAIN,
    ShipmentException.BAD_ORDER,
    ShipmentException.IN_HOLD,
    ShipmentException.UNDER_REVIEW,
    ShipmentException.OFF_ROUTE,
    ShipmentException.NO_ACTIVITY,
  ];

  const sorted: ShipmentExceptionOption[] = [];

  order.forEach((name) => {
    let exception = exceptions.find((exception) => exception.name === name);
    if (exception) {
      sorted.push(exception);
    }
  });

  return sorted;
};

/**
 * Get the icon type, source and color for an exception by name.
 *
 * @param exceptionName The exception name from the backend.
 * @returns The icon info the exception.
 */
export const getIconData = (exceptionName: ShipmentException) => {
  let iconType: IconType = IconType.FontAwesome;
  let src: InferType<typeof IconPropTypes.src> = null;

  switch (exceptionName) {
    case ShipmentException.BEHIND_SCHEDULE:
      iconType = IconType.ReactIcons;
      src = MdTimer;
      break;
    case ShipmentException.MISSED_PICKUP:
      iconType = IconType.ReactIcons;
      src = MdFileUpload;
      break;
    case ShipmentException.MISSED_DROP_OFF:
      iconType = IconType.ReactIcons;
      src = MdFileDownload;
      break;
    case ShipmentException.BAD_ORDER:
      iconType = IconType.ReactIcons;
      src = MdErrorOutline;
      break;
    case ShipmentException.IN_HOLD:
      iconType = IconType.ReactIcons;
      src = MdLockOutline;
      break;
    case ShipmentException.IDLE_TRAIN:
      iconType = IconType.ReactIcons;
      src = MdHourglassEmpty;
      break;
    case ShipmentException.UNDER_REVIEW:
      iconType = IconType.ReactIcons;
      src = FiEye;
      break;
    case ShipmentException.LOST:
      iconType = IconType.ReactIcons;
      src = IoIosHelpCircleOutline;
      break;
    case ShipmentException.CARRIER_DELAYED:
      iconType = IconType.FontAwesome;
      src = faTireFlat;
      break;
    case ShipmentException.SHIPPER_DELAYED:
      iconType = IconType.FontAwesome;
      src = faTireFlat;
      break;
    case ShipmentException.BACKORDER:
      iconType = IconType.FontAwesome;
      src = faUndoAlt;
      break;
    case ShipmentException.OFF_ROUTE:
      iconType = IconType.FontAwesome;
      src = faRoute;
      break;
    case ShipmentException.NO_ACTIVITY:
      iconType = IconType.FontAwesome;
      src = faLocationDotSlash;
      break;
    default:
      break;
  }

  return {
    type: iconType,
    src: src,
    color: getColorForException(exceptionName),
  };
};

/**
 * Get the color for the given exception.
 *
 * @param exceptionType The exception type.
 * @returns The exception's color.
 */
export const getColorForException = (exceptionName: ShipmentException) => {
  switch (exceptionName) {
    case ShipmentException.BEHIND_SCHEDULE:
    case ShipmentException.IDLE_TRAIN:
    case ShipmentException.OFF_ROUTE:
    case ShipmentException.NO_ACTIVITY:
      return Colors.highlight.YELLOW;
    case ShipmentException.MISSED_PICKUP:
    case ShipmentException.MISSED_DROP_OFF:
    case ShipmentException.BAD_ORDER:
    case ShipmentException.IN_HOLD:
    case ShipmentException.LOST:
    case ShipmentException.CARRIER_DELAYED:
      return Colors.highlight.RED;
    case ShipmentException.SHIPPER_DELAYED:
      return Colors.highlight.RED;
    case ShipmentException.BACKORDER:
      return Colors.exceptions.BACKORDER;
    case ShipmentException.UNDER_REVIEW:
      return Colors.highlight.MEDIUM_LIGHT_GRAY;
    default:
      return null;
  }
};

const getDescriptionFromCode = (code: string, t: Function) => {
  switch (code) {
    case "A1": {
      return `${t("carrier-delayed-reason:Missed Delivery")}`;
    }
    case "A2": {
      return `${t("carrier-delayed-reason:Incorrect Address")}`;
    }
    case "A3": {
      return `${t("carrier-delayed-reason:Indirect Delivery")}`;
    }
    case "A5": {
      return `${t("carrier-delayed-reason:Unable to Locate")}`;
    }
    case "A6": {
      return `${t(
        "carrier-delayed-reason:Address Corrected - Delivery Attempted",
      )}`;
    }
    case "AA": {
      return `${t("carrier-delayed-reason:Mis-sort")}`;
    }
    case "AD": {
      return `${t(
        "carrier-delayed-reason:Customer Requested Future Delivery",
      )}`;
    }
    case "AE": {
      return `${t("carrier-delayed-reason:Restricted Articles Unacceptable")}`;
    }
    case "AF": {
      return `${t("carrier-delayed-reason:Accident")}`;
    }
    case "AG": {
      return `${t("carrier-delayed-reason:Consignee Related")}`;
    }
    case "AH": {
      return `${t("carrier-delayed-reason:Driver Related")}`;
    }
    case "AI": {
      return `${t("carrier-delayed-reason:Mechanical Breakdown")}`;
    }
    case "AJ": {
      return `${t("carrier-delayed-reason:Other Carrier Related")}`;
    }
    case "AK": {
      return `${t("carrier-delayed-reason:Damaged, Rewrapped in Hub")}`;
    }
    case "AL": {
      return `${t("carrier-delayed-reason:Previous Stop")}`;
    }
    case "AM": {
      return `${t("carrier-delayed-reason:Shipper Related")}`;
    }
    case "AN": {
      return `${t("carrier-delayed-reason:Holiday - Closed")}`;
    }
    case "AO": {
      return `${t(
        "carrier-delayed-reason:Weather or Natural Disaster Related",
      )}`;
    }
    case "AP": {
      return `${t("carrier-delayed-reason:Awaiting Export")}`;
    }
    case "AQ": {
      return `${t(
        "carrier-delayed-reason:Recipient Unavailable - Delivery Delayed",
      )}`;
    }

    case "AR": {
      return `${t("carrier-delayed-reason:Improper International Paperwork")}`;
    }
    case "AS": {
      return `${t(
        "carrier-delayed-reason:Hold Due to Customs Documentation Problems",
      )}`;
    }
    case "AT": {
      return `${t(
        "carrier-delayed-reason:Unable to Contact Recipient for Broker Information",
      )}`;
    }
    case "AU": {
      return `${t("carrier-delayed-reason:Civil Event Related Delay")}`;
    }
    case "AV": {
      return `${t("carrier-delayed-reason:Exceeds Service Limitations")}`;
    }

    case "AW": {
      return `${t("carrier-delayed-reason:Past Cut-off Time")}`;
    }
    case "AX": {
      return `${t("carrier-delayed-reason:Insufficient Pick-up Time")}`;
    }
    case "AY": {
      return `${t("carrier-delayed-reason:Missed Pick-up")}`;
    }
    case "AZ": {
      return `${t("carrier-delayed-reason:Alternate Carrier Delivered")}`;
    }

    case "B1": {
      return `${t("carrier-delayed-reason:Consignee Closed")}`;
    }
    case "B2": {
      return `${t("carrier-delayed-reason:Trap for Customer")}`;
    }
    case "B4": {
      return `${t("carrier-delayed-reason:Held for Payment")}`;
    }
    case "B5": {
      return `${t("carrier-delayed-reason:Held for Consignee")}`;
    }

    case "B8": {
      return `${t(
        "carrier-delayed-reason:Improper Unloading Facility or Equipment",
      )}`;
    }
    case "B9": {
      return `${t("carrier-delayed-reason:Receiving Time Restricted")}`;
    }
    case "BB": {
      return `${t("carrier-delayed-reason:Held per Shipper")}`;
    }
    case "BC": {
      return `${t("carrier-delayed-reason:Missing Documents")}`;
    }

    case "BD": {
      return `${t("carrier-delayed-reason:Border Clearance")}`;
    }
    case "BE": {
      return `${t("carrier-delayed-reason:Road Conditions")}`;
    }
    case "BF": {
      return `${t("carrier-delayed-reason:Carrier Keying Error")}`;
    }
    case "BG": {
      return `${t("carrier-delayed-reason:Other")}`;
    }

    case "BH": {
      return `${t(
        "carrier-delayed-reason:Insufficient Time to Complete Delivery",
      )}`;
    }
    case "BI": {
      return `${t("carrier-delayed-reason:Cartage Agent")}`;
    }
    case "BJ": {
      return `${t("carrier-delayed-reason:Customer Wanted Earlier Delivery")}`;
    }
    case "BK": {
      return `${t("carrier-delayed-reason:Prearranged Appointment")}`;
    }

    case "BL": {
      return `${t("carrier-delayed-reason:Held for Protective Service")}`;
    }
    case "BM": {
      return `${t("carrier-delayed-reason:Flatcar Shortage")}`;
    }
    case "BN": {
      return `${t("carrier-delayed-reason:Failed to Release Billing")}`;
    }
    case "BO": {
      return `${t("carrier-delayed-reason:Railroad Failed to Meet Schedule")}`;
    }
    case "BP": {
      return `${t("carrier-delayed-reason:Load Shifted")}`;
    }
    case "BQ": {
      return `${t("carrier-delayed-reason:Shipment Overweight")}`;
    }
    case "BR": {
      return `${t("carrier-delayed-reason:Train Derailment")}`;
    }
    case "BS": {
      return `${t("carrier-delayed-reason:Refused by Customer")}`;
    }
    case "BT": {
      return `${t("carrier-delayed-reason:Returned to Shipper")}`;
    }
    case "C1": {
      return `${t("carrier-delayed-reason:Waiting for Customer Pick-up")}`;
    }
    case "C2": {
      return `${t("carrier-delayed-reason:Credit Hold")}`;
    }
    case "C3": {
      return `${t("carrier-delayed-reason:Suspended at Customer Request")}`;
    }
    case "C4": {
      return `${t("carrier-delayed-reason:Customer Vacation")}`;
    }
    case "C5": {
      return `${t("carrier-delayed-reason:Customer Strike")}`;
    }
    case "C6": {
      return `${t("carrier-delayed-reason:Waiting Shipping Instructions")}`;
    }
    case "C7": {
      return `${t(
        "carrier-delayed-reason:Waiting for Customer Specified Carrier",
      )}`;
    }
    case "C8": {
      return `${t("carrier-delayed-reason:Collect on Delivery Required")}`;
    }
    case "C9": {
      return `${t("carrier-delayed-reason:Cash Not Available From Consignee")}`;
    }
    case "CA": {
      return `${t("carrier-delayed-reason:Customs (Import or Export)")}`;
    }

    case "CB": {
      return `${t(
        "carrier-delayed-reason:No Requested Arrival Date Provided by Shipper",
      )}`;
    }

    case "CC": {
      return `${t(
        "carrier-delayed-reason:No Requested Arrival Time Provided by Shipper",
      )}`;
    }

    case "CR": {
      return `${t("carrier-delayed-reason:COVID Related")}`;
    }

    case "D1": {
      return `${t("carrier-delayed-reason:Carrier Dispatch Error")}`;
    }

    case "D2": {
      return `${t("carrier-delayed-reason:Driver Not Available")}`;
    }

    case "F1": {
      return `${t("carrier-delayed-reason:Non-Express Clearance Delay")}`;
    }

    case "F2": {
      return `${t("carrier-delayed-reason:International Non-carrier Delay")}`;
    }

    case "HB": {
      return `${t("carrier-delayed-reason:Held Pending Appointment")}`;
    }

    case "HS": {
      return `${t("carrier-delayed-reason:Hours of Service")}`;
    }

    case "NA": {
      return `${t("carrier-delayed-reason:Normal Appointment")}`;
    }

    case "NS": {
      return `${t("carrier-delayed-reason:Normal Status")}`;
    }

    case "P1": {
      return `${t("carrier-delayed-reason:Processing Delay")}`;
    }

    case "P2": {
      return `${t("carrier-delayed-reason:Waiting Inspection")}`;
    }

    case "P3": {
      return `${t("carrier-delayed-reason:Production Falldown")}`;
    }

    case "P4": {
      return `${t("carrier-delayed-reason:Held for Full Carrier Load")}`;
    }

    case "RC": {
      return `${t("carrier-delayed-reason:Reconsigned")}`;
    }

    case "S1": {
      return `${t("carrier-delayed-reason:Delivery Shortage")}`;
    }

    case "T1": {
      return `${t(
        "carrier-delayed-reason:Tractor With Sleeper Car Not Available",
      )}`;
    }

    case "T2": {
      return `${t(
        "carrier-delayed-reason:Tractor, Conventional, Not Available",
      )}`;
    }

    case "T3": {
      return `${t("carrier-delayed-reason:Trailer not Available")}`;
    }

    case "T4": {
      return `${t(
        "carrier-delayed-reason:Trailer Not Usable Due to Prior Product",
      )}`;
    }

    case "T5": {
      return `${t("carrier-delayed-reason:Trailer Class Not Available")}`;
    }

    case "T6": {
      return `${t("carrier-delayed-reason:Trailer Volume Not Available")}`;
    }

    case "T7": {
      return `${t("carrier-delayed-reason:Insufficient Delivery Time")}`;
    }
    default:
      return null;
  }
};

/**
 * A hook that provides a function for translating carrier delayed exception reason codes.
 *
 * @returns {Object.getFullTranslatedLabelForReasonCode} A function that, given a reason code, will return the full translated label using getDescriptionFromCode function.
 * @returns {Object.getOptionLabelFromCode} A function that, given a reason code, will return the label for use in a select element using getDescriptionFromCode function.
 */
export const useCarrierDelayedReasonCode = () => {
  const { t } = useTranslation("carrier-delayed-reason");

  return {
    getFullTranslatedLabelForReasonCode: (code: string) => {
      let description = getDescriptionFromCode(code, t);
      return `${description} (${code})`;
    },
    getOptionLabelFromCode: (code: string) => {
      let description = getDescriptionFromCode(code, t);
      return `${code} - ${description}`;
    },
  };
};

/** Keys that represent customer-defined labels to categorize the shipment's ETA */
enum EtaTimeCategoryKey {
  VERY_EARLY = "VERY_EARLY",
  EARLY = "EARLY",
  LATE = "LATE",
  VERY_LATE = "VERY_LATE",
}

/** The status of the ETA used to determine the color to use when displayed */
enum EtaTimeCategoryStatus {
  OK = "ok",
  WARNING = "warning",
  CRITICAL = "critical",
}

/** The category of the ETA from the API */
export type EtaTimeCategory = {
  key: EtaTimeCategoryKey;
  status: EtaTimeCategoryStatus;
  /* The difference in seconds between the ETA and the scheduled window open or close. */
  difference: number;
};

/**
 * Returns values for displaying the ETA time category on shipments.
 *
 * @param etaTimeCategory The category of the shipment's ETA provided by the API.
 * @param mode The mode of the shipment
 * @returns An object with labels, colors, and duration values
 */
export const useEtaTimeCategory = (
  etaTimeCategory?: EtaTimeCategory,
  mode?: string,
) => {
  const { t } = useTranslation("shipment-search");

  const getLabelTranslation = useCallback(
    (label: string) => {
      switch (label) {
        case EtaTimeCategoryKey.VERY_EARLY:
          return t("shipment-search:Very Early");
        case EtaTimeCategoryKey.EARLY:
          return t("shipment-search:Early");
        case EtaTimeCategoryKey.LATE:
          return t("shipment-search:Late");
        case EtaTimeCategoryKey.VERY_LATE:
          return t("shipment-search:Very Late");

        default: {
          logWarningForMissingTranslation(label);
          return label.split("_").join(" ");
        }
      }
    },
    [t],
  );

  const getLongLabelTranslation = useCallback(
    (label: string, duration: string) => {
      switch (label) {
        case EtaTimeCategoryKey.VERY_EARLY:
          return t("shipment-search:Very Early by [[[duration]]]", {
            duration,
          });
        case EtaTimeCategoryKey.EARLY:
          return t("shipment-search:Early by [[[duration]]]", {
            duration,
          });
        case EtaTimeCategoryKey.LATE:
          return t("shipment-search:Late by [[[duration]]]", {
            duration,
          });
        case EtaTimeCategoryKey.VERY_LATE:
          return t("shipment-search:Very Late by [[[duration]]]", {
            duration,
          });

        default: {
          logWarningForMissingTranslation(label);
          return `${label.split("_").join(" ")} by ${duration}`;
        }
      }
    },
    [t],
  );

  const getColor = useCallback((status: EtaTimeCategoryStatus) => {
    switch (status) {
      case EtaTimeCategoryStatus.OK:
        return Colors.etaTimeCategory.OK;
      case EtaTimeCategoryStatus.WARNING:
        return Colors.etaTimeCategory.WARNING;
      case EtaTimeCategoryStatus.CRITICAL:
        return Colors.etaTimeCategory.CRITICAL;
      default:
        return Colors.etaTimeCategory.DEFAULT;
    }
  }, []);

  if (!etaTimeCategory || mode === "LTL" || mode === "Parcel") {
    return null;
  }

  let color = getColor(etaTimeCategory.status);
  // TODO: Once IA-10453 is complete, remove this and rely only on `status` to determine color.
  // This is a work around because the DB does not have the correct config for "critical" statuses.
  if (etaTimeCategory.key === EtaTimeCategoryKey.VERY_LATE) {
    color = Colors.etaTimeCategory.CRITICAL;
  }

  const formattedDuration = formatDuration(etaTimeCategory.difference);
  return {
    label: getLabelTranslation(etaTimeCategory.key),
    longLabel: getLongLabelTranslation(etaTimeCategory.key, formattedDuration),
    color: color,
    formattedDuration,
  };
};
