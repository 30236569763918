import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";

import { getFilteredTriplegsByType } from "pages/partview/utils/tripleg.utils";

/**
 * Returns a formatted location object to use with the ArrivalAndDeparturePanelGroup.
 *
 * @param location - Origin or destination location object
 * @param type - origin | destination
 * @param packageDetails - package details object to read LifecycleState value
 *
 */
export const transformLocation = (
  location: Location,
  type: string,
  packageDetails: PackageDetails,
) => {
  if (_.isNil(location)) {
    return null;
  }

  let scheduledArrivalWindow = null;
  if (type === "origin") {
    scheduledArrivalWindow = location?.scheduledPickupWindow ?? [];
  } else if (type === "destination") {
    scheduledArrivalWindow = location?.scheduledDeliveryWindow ?? [];
  }

  let eta = location?.eta;
  if (packageDetails?.LifecycleState?.toLowerCase() === "delivered") {
    eta = null;
  }

  if (type === "origin") {
    return {
      name: location?.name,
      city: location?.city,
      state: location?.state,
      postalCode: location?.zipCode,
      country: location?.country,
      address: location?.address,
      scheduledPickupWindow: location?.scheduledPickupWindow,
      scheduledDeliveryWindow: location?.scheduledDeliveryWindow,
      earliestArrivalDateTime: scheduledArrivalWindow?.[0],
      latestArrivalDateTime: scheduledArrivalWindow?.[1],
      actualDepartureDateTime: location?.departureTs
        ? location.departureTs
        : location.actualDeparture,
      eta: eta,
    };
  } else {
    return {
      name: location?.name,
      city: location?.city,
      state: location?.state,
      postalCode: location?.zipCode,
      country: location?.country,
      address: location?.address,
      scheduledPickupWindow: location?.scheduledPickupWindow,
      scheduledDeliveryWindow: location?.scheduledDeliveryWindow,
      earliestArrivalDateTime: scheduledArrivalWindow?.[0],
      latestArrivalDateTime: scheduledArrivalWindow?.[1],
      actualArrivalDateTime: location?.arrivalTs
        ? location.arrivalTs
        : location.actualArrival,
      eta: eta,
    };
  }
};

/**
 * Returns the package's status based on event code and mode name
 *
 * @param milestoneCode - Milestone code - AR, DP, FD, APU, RO
 * @param mode - mode name
 * @param t - Translation function
 *
 */
export const getStatusByEventAndMode = (
  milestoneCode: string,
  mode: string,
  t: Function,
) => {
  if (milestoneCode === "DP") {
    const modeInLowerCase = mode?.toLowerCase();
    if (
      modeInLowerCase === "truck" ||
      modeInLowerCase === "ltl" ||
      modeInLowerCase === "parcel"
    ) {
      return t("partview-details:On the Road");
    } else if (modeInLowerCase === "air") {
      return t("partview-details:In the Air");
    } else if (modeInLowerCase === "rail") {
      return t("partview-details:On Rail");
    } else if (modeInLowerCase === "ocean") {
      return t("partview-details:On the Water");
    }
  } else {
    return t("partview-details:At Location");
  }
};

/**
 * Returns the translated text
 *
 * @param type - Milestone or Shipment
 * @param latestUpdate - Translation function
 *
 */
const getTranslatedSource = (type: string, t: Function) => {
  const typeInLowerCase = type?.toLowerCase() ?? "";

  if (typeInLowerCase === "milestone") {
    return t("partview-details:Milestone");
  } else if (typeInLowerCase === "shipment") {
    return t("partview-details:Shipment");
  } else {
    return null;
  }
};

/**
 * Returns the leg which has the latest Update
 *
 * @param actualTriplegs - The Actual Trip Legs Array
 * @param latestUpdate - latest Update on the trip legs
 *
 */
const getLegWithLatestUpdate = (
  actualTriplegs: any[],
  latestUpdate: {
    code: string;
    locationCode: string;
  },
  sortedUpdates: Update[],
) => {
  let latestEventLeg;

  latestEventLeg = actualTriplegs.filter((stop, index) => {
    if (latestUpdate?.locationCode === stop?.origin?.code) {
      // when index is 0 and AR is received for Ultimate origin for pick Up(X3)
      if (
        (index === 0 && latestUpdate?.code === "AR") ||
        latestUpdate.code === "DP" ||
        latestUpdate.code === "APU"
      ) {
        return true;
      }
    }

    if (latestUpdate?.locationCode === stop?.destination?.code) {
      if (latestUpdate.code === "AR" || latestUpdate.code === "FD") {
        return true;
      }
    }

    return false;
  })?.[0];

  if (_.isEmpty(latestEventLeg)) {
    // handle edge cases
    // Get the latest update which has valid location as per the actual trip leg
    latestEventLeg = _.findLast(actualTriplegs, (leg) =>
      _.findLast(
        sortedUpdates,
        (update) =>
          update?.locationCode === leg?.destination?.code ||
          update?.locationCode === leg?.origin?.code,
      ),
    );
  }

  return latestEventLeg;
};

/**
 * Returns Current Position Details based on the latest Update
 *
 * @param data - The package details object
 * @param isShipmentsLoaded - Flag which indicates shipment api's are loaded or not
 * @param shipmentsForTriplegs - Shipment api responses for all legs
 *
 */
export const useCurrentPositionDetails = (
  data: Data,
  isShipmentsLoaded: boolean,
  shipmentsForTriplegs: object,
) => {
  const { t } = useTranslation("partview-details");

  const getCurrentLegModeAndLocation = useCallback(
    (
      latestMode: string,
      latestStop: any,
      latestMilestoneOrCarrierEvent: Update,
      triplegType: string,
    ) => {
      let city, state, country, latitude, longitude;

      if (triplegType === "shipmentActual") {
        city = latestStop?.current_city;
        state = latestStop?.current_state;
        country = latestStop?.current_country;
        latitude = latestStop?.latitude;
        longitude = latestStop?.longitude;
      } else if (triplegType === "partViewActual") {
        city = latestMilestoneOrCarrierEvent?.city;
        state = latestMilestoneOrCarrierEvent?.state;
        country = latestMilestoneOrCarrierEvent?.country;
        latitude = latestMilestoneOrCarrierEvent?.latitude;
        longitude = latestMilestoneOrCarrierEvent?.longitude;
      }

      return {
        city: city,
        state: state,
        country: country,
        datetime: latestMilestoneOrCarrierEvent.eventTs,
        status: getStatusByEventAndMode(
          latestMilestoneOrCarrierEvent.code,
          latestMode,
          t,
        ),
        source: getTranslatedSource(latestMilestoneOrCarrierEvent.eventType, t),
        latitude: latitude,
        longitude: longitude,
        currentLocationName: latestMilestoneOrCarrierEvent.locationName,
      };
    },
    [t],
  );

  return useMemo(() => {
    // shipment api's are loaded then compute CurrentPositionDetails
    if (!isShipmentsLoaded) {
      return null;
    }

    //get the shipment actual leg details
    let shipmentActualTripLegs = shipmentsForTriplegs
      ? Object.values(shipmentsForTriplegs)
      : [];

    const actualTriplegs = getFilteredTriplegsByType(data?.Triplegs, "actual");

    if (data?.updates?.length > 0) {
      // get all updates of partview generated actual trip leg
      // remove Re-open (RO) event from the updates as it shouldn't change the current location status.
      let allUpdates = data.updates.filter(
        (update: { code: string }) => update.code !== "RO",
      );

      if (allUpdates.length > 0) {
        // sort all the updates as per eventDatetime
        let sortedUpdates = _.orderBy(allUpdates, "eventDatetime");

        //get the latest update object
        let latestUpdate = sortedUpdates[sortedUpdates.length - 1];

        //get the partview generated actual trip leg details which has the latest update
        let legWithLatestUpdate = getLegWithLatestUpdate(
          actualTriplegs,
          latestUpdate,
          sortedUpdates,
        );

        //This is to get the current details and mode from shipment actual trip leg
        if (
          latestUpdate.code === "DP" &&
          shipmentsForTriplegs?.hasOwnProperty(
            legWithLatestUpdate?.creatorShipmentId,
          )
        ) {
          //get the shipment details from latest shipment actual  trip leg
          let latestActualTripLeg =
            !_.isEmpty(shipmentActualTripLegs) &&
            shipmentActualTripLegs.filter(
              (actualLeg) =>
                actualLeg.creator_shipment_id ===
                legWithLatestUpdate.creatorShipmentId,
            )?.[0];

          let shipmentCurrentLocationDetail =
            latestActualTripLeg?.current_location ?? "";

          let latestMode = latestActualTripLeg?.mode_name ?? "";

          return getCurrentLegModeAndLocation(
            latestMode,
            shipmentCurrentLocationDetail,
            latestUpdate,
            "shipmentActual",
          );
        } else {
          //get the current details and mode from partview generated actual leg when shipment actual leg is not available (i.e. gives 404 status)
          let latestMode = legWithLatestUpdate?.mode;

          return getCurrentLegModeAndLocation(
            latestMode,
            null, // added null as triplegType 'partViewActual' will get the required details from 'latestUpdate' itself.
            latestUpdate,
            "partViewActual",
          );
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [
    data,
    isShipmentsLoaded,
    shipmentsForTriplegs,
    getCurrentLegModeAndLocation,
  ]);
};

type Location = {
  arrivalTs?: string;
  actualArrival?: string;
  departureTs?: string;
  actualDeparture?: string;
  address: string;
  city: string;
  code: string;
  country: string;
  eta: string | null;
  name: string;
  scheduledArrivalWindow: any[];
  scheduledDeliveryWindow: any[];
  scheduledPickupWindow: any[];
  state: string;
  zipCode: string;
};

type PackageDetails = {
  LifecycleState: string;
};

type Update = {
  id: string;
  datetime: string;
  eventDatetime: string;
  code: string;
  locationName: string;
  locationCode: string;
  latitude: number;
  longitude: number;
  comments: string;
  updateDescription: string;
  eventType: string;
  city: string;
  state: string;
  country: string;
  eventTs: string;
  eventCode: string;
};

type Data = {
  Triplegs: Object[];
  updates: Update[];
};
