type BatchCsvHeader = {
  /**
   * The key used to match up the data in the CSV with the
   * appropriate header
   */
  key: string;
  /**
   * The label to display in the header row of the CSV file
   */
  label: string;
};

type BatchCsvExample = {
  /**
   * Example data that will be transformed into the rows
   * of the CSV file
   */
  data: object[];
  /**
   * The definition for the header row in the CSV file
   */
  headers: BatchCsvHeader[];
};

export const batchCsvVinsExample: BatchCsvExample = {
  data: [
    {
      vin: "VIN1",
      comment: "Shared Comment",
      share: "Y",
    },
    {
      vin: "VIN2",
      comment: "Not Shared Comment",
      share: "N",
    },
  ],
  headers: [
    { key: "vin", label: "VIN" },
    { key: "comment", label: "Comment" },
    { key: "share", label: "Share (Y/N)" },
  ],
};

export const batchCsvContainerExample: BatchCsvExample = {
  data: [
    {
      containerId: "container1",
      comment: "Shared Comment",
      share: "Y",
    },
    {
      containerId: "container2",
      comment: "Not Shared Comment",
      share: "N",
    },
  ],
  headers: [
    { key: "containerId", label: "Container ID" },
    { key: "comment", label: "Comment" },
    { key: "share", label: "Share (Y/N)" },
  ],
};

export const batchCsvShipmentExample: BatchCsvExample = {
  data: [
    {
      shipmentId: "ShipmentID1",
      comment: "Shared Comment",
      share: "Y",
    },
    {
      shipmentId: "ShipmentID2",
      comment: "Not Shared Comment",
      share: "N",
    },
  ],
  headers: [
    { key: "shipmentId", label: "Shipment ID" },
    { key: "comment", label: "Comment" },
    { key: "share", label: "Share (Y/N)" },
  ],
};

export const batchCsvLast8ofVinExampleOpenSearch: BatchCsvExample = {
  data: [
    {
      last8OfVin: "Last 8 of VIN 1",
    },
    {
      last8OfVin: "Last 8 of VIN 2",
    },
    {
      last8OfVin: "Last 8 of VIN 3",
    },
  ],
  headers: [{ key: "last8OfVin", label: "Last 8 of VIN" }],
};
export const batchCsvLast8ofVinExample: BatchCsvExample = {
  data: [
    {
      last_8_of_vin: "Last 8 of VIN 1",
    },
    {
      last_8_of_vin: "Last 8 of VIN 2",
    },
    {
      last_8_of_vin: "Last 8 of VIN 3",
    },
  ],
  headers: [{ key: "last_8_of_vin", label: "Last 8 of VIN" }],
};

export const batchCsvVinExampleOpenSearch: BatchCsvExample = {
  data: [
    {
      vinNumberBatch: "VIN 1",
    },
    {
      vinNumberBatch: "VIN 2",
    },
    {
      vinNumberBatch: "VIN 3",
    },
  ],
  headers: [{ key: "vinNumberBatch", label: "VINs" }],
};
export const batchCsvVinExample: BatchCsvExample = {
  data: [
    {
      vin: "VIN 1",
    },
    {
      vin: "VIN 2",
    },
    {
      vin: "VIN 3",
    },
  ],
  headers: [{ key: "vin", label: "VINs" }],
};

export const batchCsvProductTypeExampleOpenSearch: BatchCsvExample = {
  data: [
    {
      productTypeBatch: "Product Type 1",
    },
    {
      productTypeBatch: "Product Type 2",
    },
    {
      productTypeBatch: "Product Type 3",
    },
  ],
  headers: [{ key: "productTypeBatch", label: "Product Types" }],
};
export const batchCsvProductTypeExample: BatchCsvExample = {
  data: [
    {
      description: "Product Type 1",
    },
    {
      description: "Product Type 2",
    },
    {
      description: "Product Type 3",
    },
  ],
  headers: [{ key: "description", label: "Product Types" }],
};

export const batchCsvOrderNumberExampleOpenSearch: BatchCsvExample = {
  data: [
    {
      orderNumberBatch: "Order Number 1",
    },
    {
      orderNumberBatch: "Order Number 2",
    },
    {
      orderNumberBatch: "Order Number 3",
    },
  ],
  headers: [{ key: "orderNumberBatch", label: "Order Number" }],
};
export const batchCsvOrderNumberExample: BatchCsvExample = {
  data: [
    {
      OrderNumber: "Order Number 1",
    },
    {
      OrderNumber: "Order Number 2",
    },
    {
      OrderNumber: "Order Number 3",
    },
  ],
  headers: [{ key: "OrderNumber", label: "Order Number" }],
};

export const batchCsvShipmentIDExample: BatchCsvExample = {
  data: [
    {
      shipment_id: "Shipment ID # 1",
    },
    {
      shipment_id: "Shipment ID # 2",
    },
    {
      shipment_id: "Shipment ID # 3",
    },
  ],
  headers: [{ key: "shipment_id", label: "Shipment ID #s" }],
};

export const batchCsvTrailerEquipmentNumberExample: BatchCsvExample = {
  data: [
    {
      trailer_number: "Trailer/Equipment # 1",
    },
    {
      trailer_number: "Trailer/Equipment # 2",
    },
    {
      trailer_number: "Trailer/Equipment # 3",
    },
  ],
  headers: [{ key: "trailer_number", label: "Trailer/Equipment #s" }],
};

export const batchCsvRouteIdExample: BatchCsvExample = {
  data: [
    {
      route_number: "Route ID 1",
    },
    {
      route_number: "Route ID 2",
    },
    {
      route_number: "Route ID 3",
    },
  ],
  headers: [{ key: "route_number", label: "Route IDs" }],
};

export const batchCsvPartNumberExample: BatchCsvExample = {
  data: [
    {
      part_values: "Part # 1",
    },
    {
      part_values: "Part # 2",
    },
    {
      part_values: "Part # 3",
    },
  ],
  headers: [{ key: "part_values", label: "Part #s" }],
};

//Partview CSV
export const batchCsvPartViewPartNumberExample: BatchCsvExample = {
  data: [
    {
      partNumber: "Part # 1",
    },
    {
      partNumber: "Part # 2",
    },
    {
      partNumber: "Part # 3",
    },
  ],
  headers: [{ key: "partNumber", label: "Part #s" }],
};

export const batchCsvBillofLadingExample: BatchCsvExample = {
  data: [
    {
      billOfLading: "Bill of Lading # 1",
    },
    {
      billOfLading: "Bill of Lading # 2",
    },
    {
      billOfLading: "Bill of Lading # 3",
    },
  ],
  headers: [{ key: "billOfLading", label: "Bill of Lading #s" }],
};

export const batchCsvPartViewShipmentIDExample: BatchCsvExample = {
  data: [
    {
      shipmentId: "Shipment ID # 1",
    },
    {
      shipmentId: "Shipment ID # 2",
    },
    {
      shipmentId: "Shipment ID # 3",
    },
  ],
  headers: [{ key: "shipmentId", label: "Shipment ID #s" }],
};

export const batchCsvPackageNumberExample: BatchCsvExample = {
  data: [
    {
      trackingNumber: "Package # 1",
    },
    {
      trackingNumber: "Package # 2",
    },
    {
      trackingNumber: "Package # 3",
    },
  ],
  headers: [{ key: "trackingNumber", label: "Package #s" }],
};

export const batchCsvPartViewOrderNumberExample: BatchCsvExample = {
  data: [
    {
      orderNumber: "Order Number 1",
    },
    {
      orderNumber: "Order Number 2",
    },
    {
      orderNumber: "Order Number 3",
    },
  ],
  headers: [{ key: "orderNumber", label: "Order Number #s" }],
};

export const batchCsvEquipmentTrailerNumberExample: BatchCsvExample = {
  data: [
    {
      trailerEquipmentNumber: "Equipment/Trailer# 1",
    },
    {
      trailerEquipmentNumber: "Equipment/Trailer# 2",
    },
    {
      trailerEquipmentNumber: "Equipment/Trailer# 3",
    },
  ],
  headers: [{ key: "trailerEquipmentNumber", label: "Equipment/Trailer# #s" }],
};

export const batchCsvContainerTypeExample: BatchCsvExample = {
  data: [
    {
      container_type: "Container Type # 1",
    },
    {
      container_type: "Container Type # 2",
    },
    {
      container_type: "Container Type # 3",
    },
  ],
  headers: [{ key: "container_type", label: "Container Type #s" }],
};

export const batchCsvTagIdExample: BatchCsvExample = {
  data: [
    {
      tag_id: "Tag ID # 1",
    },
    {
      tag_id: "Tag ID # 2",
    },
    {
      tag_id: "Tag ID # 3",
    },
  ],
  headers: [{ key: "tag_id", label: "Tag ID #s" }],
};

export const batchCsvContainerIdExample: BatchCsvExample = {
  data: [
    {
      container_id: "Container ID # 1",
    },
    {
      container_id: "Container ID # 2",
    },
    {
      container_id: "Container ID # 3",
    },
  ],
  headers: [{ key: "container_id", label: "Container ID #s" }],
};

export const batchCsvProgramCodeExample: BatchCsvExample = {
  data: [
    {
      program_code: "Program Code # 1",
    },
    {
      program_code: "Program Code # 2",
    },
    {
      program_code: "Program Code # 3",
    },
  ],
  headers: [{ key: "program_code", label: "Program Code #s" }],
};

export const batchCsvSupplierCodeExample: BatchCsvExample = {
  data: [
    {
      supplier_code: "Supplier Code # 1",
    },
    {
      supplier_code: "Supplier Code # 2",
    },
    {
      supplier_code: "Supplier Code # 3",
    },
  ],
  headers: [{ key: "supplier_code", label: "Supplier Code #s" }],
};
