import { createSelector } from "reselect";

import FinVehicleDomainDataState, {
  SHIPMENT_STATUS_FILTER_OPTIONS,
} from "modules/domain-data/FinVehicleDomainData";
import { createOptionsMapper } from "utils/selectors-utils";

// Helpers
//
const valuesMapper = createOptionsMapper({ sorted: false });

// Selectors
export const selectCarrierFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getCarriers],
  createOptionsMapper({
    labelKey: (item) => `${item.carrier_name} (${item.carrier_scac})`,
    valueKey: "carrier_id",
  }),
);

export const selectCarrierFilterOptionsOS = createSelector(
  [FinVehicleDomainDataState.selectors.getCarriersOS],
  createOptionsMapper({
    labelKey: (item) => item?.value,
    valueKey: "key",
  }),
);

export const selectExceptionTypeFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.selectExceptionTypesAlphabetically],
  createOptionsMapper({ labelKey: "name", valueKey: "id" }),
);

export const selectProductTypeFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getProductTypes],
  valuesMapper,
);

export const selectLifeCycleStateFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getLifeCycleStates],
  valuesMapper,
);

export const selectOrderTypeFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getOrderTypes],
  valuesMapper,
);

export const selectOrderTypeFilterOptionsOS = createSelector(
  [FinVehicleDomainDataState.selectors.getOrderTypeOS],
  createOptionsMapper({
    labelKey: (item) => item?.value,
    valueKey: "key",
  }),
);

export const selectShipmentStatusFilterOptions = () =>
  SHIPMENT_STATUS_FILTER_OPTIONS.map((st) => ({
    value: st.value,
    label: st.label,
  }));

export const selectShipmentStatusFilterOptionsOS = () =>
  SHIPMENT_STATUS_FILTER_OPTIONS.map((st) => ({
    value: st.value,
    label: st.label,
  }));

export const selectItssIdFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getItssIds],
  createOptionsMapper({
    labelKey: (item) => `${item.category}`,
    valueKey: "id",
  }),
);

export const selectItssIdFilterOptionsOS = createSelector(
  [FinVehicleDomainDataState.selectors.getItssIdOS],
  createOptionsMapper({
    labelKey: (item) => item?.value,
    valueKey: "key",
  }),
);

export const selectSpotBuyFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getSpotBuyAuthCodes],
  createOptionsMapper({
    labelKey: (item) => `${item.category}`,
    valueKey: "id",
  }),
);

export const selectSpotBuyFilterOptionsOS = createSelector(
  [FinVehicleDomainDataState.selectors.getSpotBuyAuthOS],
  createOptionsMapper({
    labelKey: (item) => item?.value,
    valueKey: "key",
  }),
);

export const selectShipmentExceptionFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getShipmentExeptions],
  createOptionsMapper({
    labelKey: (item) => `${item}`,
    valueKey: (item) => item,
  }),
);

export const selectCurrentPositionTypes = createSelector(
  [FinVehicleDomainDataState.selectors.getCurrentPositionTypes],
  createOptionsMapper({
    labelKey: (item) => `${item.label}`,
    valueKey: (item) => `${item.value}`,
  }),
);

export const selectRouteIdFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getRouteIds],
  createOptionsMapper({
    labelKey: (item) => item,
    valueKey: (item) => item,
  }),
);

export const selectRouteIdFilterOptionsOS = createSelector(
  [FinVehicleDomainDataState.selectors.getRouteIdOS],
  createOptionsMapper({
    labelKey: (item) => item?.value,
    valueKey: "key",
  }),
);
