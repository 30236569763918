/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import Loader from "react-loader";
import { OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSparkles,
  faFolderPlus,
  faFolderMinus,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faTrashAlt,
  faFilter,
  faEnvelope,
  faCalendarAlt,
  faEdit,
} from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { faFolderTree } from "@fortawesome/pro-light-svg-icons";

import {
  localizedTimeFormatter,
  localizedDateFormatter,
} from "utils/date-time";
import Colors from "styles/colors";
import { FlexDiv } from "styles/container-elements";
import { LinkButton, actionButtonStyle } from "styles/table-action-styles";
import AuthenticationUtils from "modules/auth/authentication";
import {
  TreeMiddleAndEndCell,
  TreeStartCell,
} from "components/organisms/base-table/Cell/TreeCells";
import SourceFilter from "./SourceFilter";

export const ReportTypes = {
  CORE_REPORTS: "CORE_REPORTS",
  SHARED_REPORTS: "SHARED_REPORTS",
  MY_REPORTS: "MY_REPORTS",
  REPORT_BUILDER: "REPORT_BUILDER",
};

const fixSource = (t, source, directory, filterSet) => {
  if (directory) {
    return null;
  } else if (source === "report_builder") {
    return t("reports:Report Builder");
  } else if (filterSet) {
    return t("reports:Core Report");
  } else {
    return t("reports:Unknown");
  }
};

const ActionsCell = (props) => {
  const {
    reportType,
    source,
    filterSet,
    hasReportBuilderPrivilege,
    hasManageSharedReportsPrivilege,
    hasManageReportDirectory,
    isFVAdmin,
    createdReport,
    createFilterSet,
    actionHandler,
    emailScheduleCount,
    directory,
    t,
  } = props.value;
  const [isDeleting, setIsDeleting] = useState(false);

  const onCancel = () => {
    setIsDeleting(false);
  };

  return (
    <FlexDiv
      style={{ position: "relative", display: "flex", flexDirection: "row" }}
    >
      <Loader loaded={!isDeleting} scale={0.33} color={Colors.text.HIT_GRAY}>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {/*Directory Buttons*/}
          {directory &&
            ((reportType === ReportTypes.CORE_REPORTS && isFVAdmin) ||
              (reportType === ReportTypes.SHARED_REPORTS &&
                (isFVAdmin || hasManageReportDirectory)) ||
              reportType === ReportTypes.MY_REPORTS) && (
              <LinkButton
                style={{
                  ...actionButtonStyle,
                  display: "flex",
                  alignItems: "center",
                  padding: 5,
                }}
                clickHandler={() => {
                  return actionHandler("EDIT_FOLDER_REPORT", {
                    report: props.row.original, // get the value of original from state
                    toggleCreateAndEditModal: false,
                    folderCategory: reportType,
                  });
                }}
              >
                {t("reports:Edit")}
                <FontAwesomeIcon
                  css={{
                    height: "1.5em",
                    width: "1.5em",
                    margin: "4px",
                    marginLeft: "0.5em",
                    ":hover": { cursor: "pointer", color: "rgba(0,0,0,0.5)" },
                  }}
                  icon={faEdit}
                  alt={t("Edit")}
                />
              </LinkButton>
            )}

          {directory &&
            ((reportType === ReportTypes.CORE_REPORTS && isFVAdmin) ||
              (reportType === ReportTypes.SHARED_REPORTS &&
                (isFVAdmin || hasManageReportDirectory)) ||
              reportType === ReportTypes.MY_REPORTS) && (
              <LinkButton
                style={{
                  ...actionButtonStyle,
                  display: "flex",
                  alignItems: "center",
                  padding: 5,
                }}
                clickHandler={() => {
                  setIsDeleting(true);
                  return actionHandler("DELETE_FOLDER_REPORT", {
                    report: props.row.original,
                    onCancel,
                    reportType,
                  });
                }}
              >
                <span css={{ textDecoration: "underline" }}>
                  {t("reports:Delete")}
                </span>
                <FontAwesomeIcon
                  css={{
                    height: "1.5em",
                    width: "1.5em",
                    margin: "4px",
                    marginLeft: "0.5em",
                    ":hover": { cursor: "pointer", color: "rgba(0,0,0,0.5)" },
                  }}
                  icon={faTrashAlt}
                  alt={t("Delete Folder")}
                />
              </LinkButton>
            )}

          {/*Report Buttons */}
          {!directory &&
            ((reportType === ReportTypes.CORE_REPORTS && isFVAdmin) ||
              (reportType === ReportTypes.SHARED_REPORTS &&
                (isFVAdmin || hasManageReportDirectory || createdReport)) ||
              reportType === ReportTypes.MY_REPORTS) && (
              <LinkButton
                style={{
                  ...actionButtonStyle,
                  // Removing the parent underline because it cannot be overridden by children
                  // Applying text-decoration only to the "Schedule Email" text
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  padding: 5,
                }}
                clickHandler={() => {
                  const payload = { report: props.row.original, reportType };
                  return actionHandler("MOVE_FOLDER_REPORT", payload);
                }}
              >
                <span css={{ textDecoration: "underline" }}>
                  {t("reports:Move")}
                </span>
                <FontAwesomeIcon
                  css={{
                    height: "1.5em",
                    width: "1.5em",
                    margin: "4px",
                    marginLeft: "0.5em",
                    ":hover": { cursor: "pointer", color: "rgba(0,0,0,0.5)" },
                  }}
                  icon={faFolderTree}
                  alt={t("Move Report")}
                />
              </LinkButton>
            )}

          {!directory &&
            ((reportType === ReportTypes.SHARED_REPORTS &&
              (hasManageSharedReportsPrivilege || createdReport)) ||
              reportType === ReportTypes.MY_REPORTS) &&
            source === "report_builder" && (
              <LinkButton
                style={{
                  ...actionButtonStyle,
                  display: "flex",
                  alignItems: "center",
                  padding: 5,
                }}
                clickHandler={() => {
                  return actionHandler("EDIT_REPORT", {
                    report: props.row.original,
                  });
                }}
              >
                {t("reports:Edit")}
                <FontAwesomeIcon
                  css={{
                    height: "1.5em",
                    width: "1.5em",
                    margin: "4px",
                    marginLeft: "0.5em",
                    ":hover": { cursor: "pointer", color: "rgba(0,0,0,0.5)" },
                  }}
                  icon={faEdit}
                  alt={t("Edit")}
                />
              </LinkButton>
            )}
          {!directory &&
            filterSet &&
            ((reportType === ReportTypes.SHARED_REPORTS &&
              (hasManageSharedReportsPrivilege || createdReport)) ||
              reportType === ReportTypes.MY_REPORTS) && (
              <LinkButton
                style={{
                  ...actionButtonStyle,
                  display: "flex",
                  alignItems: "center",
                  padding: 5,
                }}
                clickHandler={() => {
                  return actionHandler("UPDATE_FILTERED_REPORT", {
                    report: props.row.original,
                    reportType,
                  });
                }}
              >
                {t("reports:Update Filters")}
                <FontAwesomeIcon
                  css={{
                    height: "1.5em",
                    width: "1.5em",
                    margin: "4px",
                    marginLeft: "0.5em",
                    ":hover": { cursor: "pointer", color: "rgba(0,0,0,0.5)" },
                  }}
                  icon={faEdit}
                  alt={t("Update Filters")}
                />
              </LinkButton>
            )}
          {reportType === ReportTypes.CORE_REPORTS &&
            !directory &&
            createFilterSet && (
              <LinkButton
                style={{
                  ...actionButtonStyle,
                  display: "flex",
                  alignItems: "center",
                  padding: 5,
                }}
                clickHandler={() => {
                  return actionHandler("CREATE_FILTERED_REPORT", {
                    report: props.row.original,
                    reportType,
                  });
                }}
              >
                {t("reports:Create Filtered Report")}
                <FontAwesomeIcon
                  css={{
                    height: "1.5em",
                    width: "1.5em",
                    margin: "4px",
                    marginLeft: "0.5em",
                    ":hover": { cursor: "pointer", color: "rgba(0,0,0,0.5)" },
                  }}
                  icon={faFilter}
                  alt={t("Create Filtered Report")}
                />
              </LinkButton>
            )}

          {!directory &&
            (reportType === ReportTypes.CORE_REPORTS ||
              reportType === ReportTypes.SHARED_REPORTS ||
              reportType === ReportTypes.MY_REPORTS) && (
              <React.Fragment>
                <LinkButton
                  style={{
                    ...actionButtonStyle,
                    display: "flex",
                    alignItems: "center",
                    padding: 5,
                  }}
                  clickHandler={() => {
                    return actionHandler("SEND_EMAIL", {
                      report: props.row.original,
                    });
                  }}
                >
                  {t("reports:Send Email")}
                  <FontAwesomeIcon
                    css={{
                      height: "1.5em",
                      width: "1.5em",
                      margin: "4px",
                      marginLeft: "0.5em",
                      ":hover": { cursor: "pointer", color: "rgba(0,0,0,0.5)" },
                    }}
                    icon={faEnvelope}
                    alt={t("Send Email")}
                  />
                </LinkButton>
                <LinkButton
                  style={{
                    ...actionButtonStyle,
                    // Removing the parent underline because it cannot be overridden by children
                    // Applying text-decoration only to the "Schedule Email" text
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    padding: 5,
                  }}
                  clickHandler={() => {
                    const payload = { report: props.row.original };

                    if (emailScheduleCount > 0) {
                      return actionHandler("SELECT_SCHEDULE", payload);
                    }

                    return actionHandler("CREATE_SCHEDULE", payload);
                  }}
                >
                  <span css={{ textDecoration: "underline" }}>
                    {t("reports:Schedule Email")}
                  </span>
                  <FontAwesomeIcon
                    css={{
                      height: "1.5em",
                      width: "1.5em",
                      margin: "4px",
                      marginLeft: "0.5em",
                      ":hover": { cursor: "pointer", color: "rgba(0,0,0,0.5)" },
                    }}
                    icon={faCalendarAlt}
                    alt={t("Schedule Email")}
                  />
                  {emailScheduleCount > 0 && (
                    <Badge pill variant="primary" style={{ fontSize: "unset" }}>
                      {emailScheduleCount}
                    </Badge>
                  )}
                </LinkButton>
              </React.Fragment>
            )}
          {!directory &&
            !filterSet &&
            ((reportType === ReportTypes.SHARED_REPORTS &&
              (hasManageSharedReportsPrivilege ||
                createdReport ||
                createdReport)) ||
              reportType === ReportTypes.MY_REPORTS) &&
            hasReportBuilderPrivilege && (
              <LinkButton
                style={{
                  ...actionButtonStyle,
                  display: "flex",
                  alignItems: "center",
                  padding: 5,
                }}
                clickHandler={() => {
                  setIsDeleting(true);
                  return actionHandler("DELETE_REPORT", {
                    report: props.row.original,
                    onCancel,
                    reportType,
                  });
                }}
              >
                {t("reports:Delete")}
                <FontAwesomeIcon
                  css={{
                    height: "1.5em",
                    width: "1.5em",
                    margin: "4px",
                    marginLeft: "0.5em",
                    ":hover": { cursor: "pointer", color: "rgba(0,0,0,0.5)" },
                  }}
                  icon={faTrashAlt}
                  alt={t("Delete Report")}
                />
              </LinkButton>
            )}
          {!directory &&
            filterSet &&
            ((reportType === ReportTypes.SHARED_REPORTS &&
              (hasManageSharedReportsPrivilege || createdReport)) ||
              reportType === ReportTypes.MY_REPORTS) && (
              <LinkButton
                style={{
                  ...actionButtonStyle,
                  display: "flex",
                  alignItems: "center",
                  padding: 5,
                }}
                clickHandler={() => {
                  setIsDeleting(true);
                  return actionHandler("DELETE_FILTERED_REPORT", {
                    report: props.row.original,
                    onCancel,
                    reportType,
                  });
                }}
              >
                {t("reports:Delete")}
                <FontAwesomeIcon
                  css={{
                    height: "1.5em",
                    width: "1.5em",
                    margin: "4px",
                    marginLeft: "0.5em",
                    ":hover": { cursor: "pointer", color: "rgba(0,0,0,0.5)" },
                  }}
                  icon={faTrashAlt}
                  alt={t("Delete Report")}
                />
              </LinkButton>
            )}
        </div>
      </Loader>
    </FlexDiv>
  );
};

ActionsCell.propTypes = {
  value: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
};

export const treeStartColumn = {
  Header: "",
  accessor: "reportId",
  Cell: TreeStartCell,
  width: 50,
  disableResizing: true,
  disableSortBy: true,
  expandedDataCanExpand: "directory",
  Filter: ({ column: { setFilter } }) => null,
  openIcon: faFolderPlus,
  closedIcon: faFolderMinus,
};

export const treeMiddleAndEndColumn = {
  Header: "",
  accessor: "reportId",
  Cell: TreeMiddleAndEndCell,
  width: 50,
  disableResizing: true,
  disableSortBy: true,
  Filter: ({ column: { setFilter } }) => null,
};

export const getColumns = (
  t,
  reportType,
  hasReportBuilderPrivilege,
  hasManageSharedReportsPrivilege,
  hasManageReportDirectory,
  isFVAdmin,
  actionHandler,
) => {
  const sourceTooltip = (
    originalReportName,
    filterSet,
    filterSetParentReportName,
  ) => {
    return (
      <Tooltip>
        <div
          css={{
            padding: ".75em",
            textAlign: "left",
          }}
        >
          {filterSet ? filterSetParentReportName : originalReportName}
        </div>
      </Tooltip>
    );
  };

  let columns = [];

  columns.push({
    Header: t("reports:Report Name"),
    id: "name",
    accessor: (d) => {
      if (d.directory) {
        return d.directory.name;
      } else if (d.filterSet && d.filterSet.name) {
        return d.filterSet.name;
      } else {
        return d.name;
      }
    },
    width: 250,
    Cell: (cellInfo) => {
      // If the report has a filterSet marked as new,
      // or the report doesn't have a filterSet and is marked as new
      let isNewReport =
        (!cellInfo.row.original.directory &&
          cellInfo.row.original.filterSet &&
          cellInfo.row.original.filterSet.isNew === true) ||
        (!cellInfo.row.original.directory &&
          !cellInfo.row.original.filterSet &&
          cellInfo.row.original.isNew === true);

      return (
        <div>
          {cellInfo.value}
          {isNewReport && (
            <span
              style={{
                border: `1px solid ${Colors.highlight.GREEN}`,
                color: Colors.highlight.GREEN,
                borderRadius: 4,
                padding: "2px 5px",
                marginLeft: 10,
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              <FontAwesomeIcon
                style={{ marginRight: 4 }}
                icon={faSparkles}
                alt={t("reports:New")}
              />
              {t("reports:New!")}
            </span>
          )}
        </div>
      );
    },
  });

  columns.push({
    Header: t("reports:Description"),
    id: "description",
    width: 250,
    accessor: (d) => {
      if (d.directory) {
        return d.directory.description;
      } else if (d.filterSet && d.filterSet.description) {
        return d.filterSet.description;
      } else {
        return d.description;
      }
    },
  });

  if (reportType === ReportTypes.SHARED_REPORTS) {
    columns.push({
      Header: t("reports:Created By"),
      id: "createdBy",
      accessor: (d) => {
        if (d.directory) {
          return null;
        } else if (d.filterSet && d.filterSet.createdBy) {
          return d.filterSet.createdBy;
        } else {
          return d.createdBy;
        }
      },
      width: 240,
    });
  }

  if (
    reportType === ReportTypes.SHARED_REPORTS ||
    reportType === ReportTypes.MY_REPORTS
  ) {
    columns.push({
      Header: t("reports:Source"),
      id: "source",
      accessor: (d) => {
        if (d.directory) {
          return null;
        } else if (d.filterSet && d.filterSet) {
          return d.name;
        } else {
          return d.source;
        }
      },
      width: 150,
      Cell: (cellInfo) => (
        <div>
          <React.Fragment>
            {fixSource(
              t,
              cellInfo.value,
              cellInfo.row.original.directory,
              cellInfo.row.original.filterSet,
            )}
            {!cellInfo.row.original.directory &&
              (cellInfo.row.original.originalReportName ||
                cellInfo.row.original.filterSet) && (
                <OverlayTrigger
                  placement="top"
                  overlay={sourceTooltip(
                    cellInfo.row.original.originalReportName,
                    cellInfo.row.original.filterSet,
                    cellInfo.value,
                  )}
                >
                  <FontAwesomeIcon
                    css={{
                      marginLeft: 4,
                      ":hover": { cursor: "help" },
                    }}
                    icon={faInfoCircle}
                    alt={cellInfo.value}
                  />
                </OverlayTrigger>
              )}
          </React.Fragment>
        </div>
      ),
      Filter: ({ column: { setFilter, filterValue } }) => {
        return <SourceFilter onChange={setFilter} value={filterValue} />;
      },
    });
  }

  if (
    reportType === ReportTypes.SHARED_REPORTS ||
    reportType === ReportTypes.MY_REPORTS
  ) {
    columns.push({
      Header: t("reports:Last Modified"),
      id: "lastUpdated",
      accessor: (d) => {
        if (d.directory) {
          return null;
        }
        if (d.filterSet && d.filterSet.createdOn) {
          return d.filterSet.createdOn;
        } else if (d.filterSet && d.filterSet.lastUpdated) {
          return d.filterSet.lastUpdated;
        } else {
          return d.lastUpdated;
        }
      },
      width: 170,
      Cell: (cellInfo) => {
        if (!cellInfo.value) {
          return "";
        } else {
          return (
            <div>
              {localizedDateFormatter(cellInfo.value)}{" "}
              {localizedTimeFormatter(cellInfo.value)}
            </div>
          );
        }
      },
      Filter: ({ column: { setFilter } }) => null,
    });
  }

  if (reportType !== ReportTypes.REPORT_BUILDER) {
    columns.push({
      Header: () => (
        <div style={{ textAlign: "center" }}>{t("reports:Actions")}</div>
      ),
      id: "user-actions",
      width: 500,
      Cell: (props) => (
        <ActionsCell
          key={`${props.value.reportId} ${
            props.value.filterSet?.filterSetId ?? ""
          } ${props.value.directory?.directoryId ?? ""}`}
          {...props}
        />
      ),
      accessor: (row) => {
        return {
          reportId: row.reportId,
          reportType: reportType,
          source: row.source,
          filterSet: row.filterSet,
          hasReportBuilderPrivilege: hasReportBuilderPrivilege,
          hasManageSharedReportsPrivilege: hasManageSharedReportsPrivilege,
          hasManageReportDirectory: hasManageReportDirectory,
          isFVAdmin: isFVAdmin,
          createdReport:
            (row.source === "report_builder" &&
              row.createdBy === AuthenticationUtils.userEmail) ||
            (row.filterSet &&
              row.filterSet.createdBy === AuthenticationUtils.userEmail),
          createFilterSet: row.createFilterSet,
          actionHandler: actionHandler,
          emailScheduleCount: row.emailScheduleCount,
          directory: row.directory,
          t: t,
        };
      },
      disableSortBy: true,
      Filter: ({ column: { setFilter } }) => null,
    });
  }

  return columns;
};
