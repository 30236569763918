/*
 * Helper to build search bar state in a mount point expected
 */
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { createSelector } from "reselect";

import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import chainReducers from "../../vendor/signal-utils/chain-reducers";
import ExportsState from "../../modules/exports/ExportsState";
import {
  csvHeaders,
  withoutPagination,
  withAsyncExport,
  ensurePagination,
} from "../../modules/exports/exportUtils";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { getAuthorizedFilters } from "./filters";
import { PaginationType, SortType } from "components/search-bar/enums.utils";
import {
  batchMapper,
  filterEmptyValues,
  keyMapper,
  mapAndFilterKeys,
} from "./key-mapper";

/**
 * Add byKey attribute to the list if it has no attribute like this
 */
const addByKeyAttribute = (list) => {
  if (!_.isArray(list)) {
    return list;
  }
  if (list.byKey) {
    return list;
  }
  const _filtersByKey = _.keyBy(list, "queryKey");
  list.byKey = _.partial(_.get, _filtersByKey);
  return list;
};

const RESET_ALL_SEARCH_BAR_STATES = "RESET_ALL_SEARCH_BAR_STATES";

export const resetAllSearchBarStates = () => {
  return { type: RESET_ALL_SEARCH_BAR_STATES };
};

const buildSearchBarState = (
  topic,
  searchCategories,
  searchFiltersCategories,
  fetchSearch,
  reducers = [],
  config = {},
  defaultPageSize = 20,
  paginationType = PaginationType.DEFAULT,
  sortType = SortType.DEFAULT,
) => {
  const TS = (actionName) => `${topic}/Search/${actionName}`;
  const SEARCH_CLICKED = TS("SEARCH_CLICKED");
  const SET_SEARCH_TEXT = TS("SET_SEARCH_TEXT");
  const SET_SEARCH_VALUE = TS("SET_SEARCH_VALUE");
  const CLEAR_SEARCH_TEXT = TS("CLEAR_SEARCH_TEXT");
  const SET_CATEGORY = TS("SET_SEARCH_CATEGORY");
  const SET_FILTER = TS("SET_SEARCH_FILTER");
  const CLEAR_FILTER = TS("CLEAR_SEARCH_FILTER");
  const CLEAR_SEARCH_FILTERS = TS("CLEAR_SEARCH_FILTERS");
  const RESET_SEARCH_BAR = TS("RESET_SEARCH_BAR");
  const SELECT_SAVED_SEARCH = TS("SELECT_SAVED_SEARCH");
  const RESET_SAVED_SEARCH = TS("RESET_SAVED_SEARCH");
  const RESET_EXPORT = TS("RESET_EXPORT");
  const SET_SHOW_ADVANCED_SEARCH = TS("SET_SHOW_ADVANCED_SEARCH");
  const SET_PAGINATION = TS("SET_PAGINATION");
  const SET_SORT = TS("SET_SORT");
  const CLEAR_ENTITIES = TS("CLEAR_ENTITIES");
  const EXPORT_IDENTIFIER_RECEIVED = TS("EXPORT_IDENTIFIER_RECEIVED");
  const EXPORT_SEARCH_SUCCEEDED = TS("EXPORT_SEARCH_SUCCEEDED");
  const EXPORT_SEARCH_FAILED = TS("EXPORT_SEARCH_FAILED");
  const CLEAR_EXPORT_ERRORS = TS("CLEAR_EXPORT_ERRORS");
  const EXPORT_REQUEST = TS("EXPORT_REQUEST");
  const duck = buildFetchDuck(topic);

  addByKeyAttribute(searchCategories);
  addByKeyAttribute(searchFiltersCategories);

  const searchEntities = (
    solutionId,
    resetPagination = true,
    preventRedirect = false,
  ) => {
    return (dispatch, getState) => {
      let state = getState();

      dispatch({ type: SEARCH_CLICKED });

      // H2-2539 - Checking for is loading state is a hack to resolve resetting page number when
      //           on vinview search results page. It looks like react hook state update for manual
      //           updates to search result page number conflicts with redux store state updates
      //           when filter controls are changed and search results are updated
      if (resetPagination || state[topic].isLoading) {
        dispatch({
          type: SET_PAGINATION,
          page: 0,
          pageSize: defaultPageSize,
        });
      }

      // Reset export in case of errors
      dispatch({
        type: RESET_EXPORT,
      });

      // Refresh the state object to get changes from the actions above.
      state = getState();

      let { queryString, requestBody } = selectSearchQueryString(state, config);
      if (queryString.startsWith("&")) {
        queryString = queryString.slice(1);
      }

      fetchSearch(
        queryString,
        solutionId,
        duck,
        dispatch,
        state,
        preventRedirect,
        requestBody,
      );
    };
  };
  const startAsyncDataExport = (
    dispatch,
    url,
    config,
    exportFileName = "search-results",
    isBatch = false,
    batchData = {},
  ) => {
    const fetchRequest = !isBatch
      ? axios.get(url, config)
      : axios.post(url, batchData, config);
    dispatch({ type: EXPORT_REQUEST });

    fetchRequest
      .then((response) => {
        const identifier = response.data.csvIdentifier;
        dispatch({
          type: EXPORT_IDENTIFIER_RECEIVED,
          identifier,
          exportName: exportFileName,
        });
      })
      .then(() => {
        dispatch({
          type: EXPORT_SEARCH_SUCCEEDED,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: EXPORT_SEARCH_FAILED,
        });
      });
  };

  /**
   * Generate CSV file calling an endpoint on the server which will send us
   * a file url that will be downloaded after all.
   */
  const exportEntities = (
    entitiesUrl,
    batchUrl = null,
    config = {},
    filename = "search-results",
    paginationType = PaginationType.DEFAULT,
    solutionId = null,
    usePagination = false,
  ) => {
    return (dispatch, getState) => {
      const state = getState();

      // TODO: check a better way to do this!
      const batchFilter = state[topic].searchFilters.batch;
      const exportFileName = `${filename}-${Date.now()}.csv`;

      let qs = "";
      if (paginationType === PaginationType.OPEN_SEARCH) {
        const { requestBody } = selectSearchQueryString(state) ?? {};
        const openSearchFilters = mapAndFilterKeys(
          requestBody?.filter || {},
          keyMapper,
        );
        const filteredFilters = filterEmptyValues(openSearchFilters);

        let combinedFilters = filteredFilters;

        if (batchFilter) {
          const batchFilters = batchMapper(batchFilter || {}, keyMapper);
          // Combine Open Search filters and batch filters
          combinedFilters = { ...filteredFilters, ...batchFilters };
        }

        qs = withAsyncExport(combinedFilters, exportFileName);
      } else {
        qs = withAsyncExport(
          !usePagination
            ? withoutPagination(
                selectSearchQueryString(state)?.queryString ?? "",
              )
            : selectSearchQueryString(state)?.queryString ?? "",
          exportFileName,
        );
      }

      const url = entitiesUrl(solutionId, qs, state);

      if (typeof config === "function") {
        config = config(state);
      } else if (_.isEmpty(config)) {
        config.headers = csvHeaders({ tz: null });
      } else {
        config.headers["x-time-zone"] = moment.tz.guess();
      }

      if (batchFilter) {
        // Batch export POST
        if (batchUrl === null) {
          throw Error(
            "Trying to export a batch search without batchUrl definition. " +
              "Please, inform batchUrl parameter to exportEntities function.",
          );
        }
        const batchUrlString = batchUrl(solutionId, qs, batchFilter.batch_type);
        return batchExport({
          url: batchUrlString,
          batchFilter,
          config,
          dispatch,
          exportFileName,
        });
      } else {
        // Normal export GET

        return startAsyncDataExport(dispatch, url, config, exportFileName);
      }
    };
  };

  const clearExportErrors = () => {
    return (dispatch) => {
      dispatch({
        type: CLEAR_EXPORT_ERRORS,
      });
    };
  };

  /**
   * When we are talking about a export for a batch search, this function
   * will add parameters and call the right endpoint for batch search
   * export.
   */
  const batchExport = ({
    url,
    batchFilter,
    config,
    dispatch,
    exportFileName,
  }) => {
    // H1-1405: There's a bug in the batch_search endpoint that will return the
    // entire unfiltered dataset if there are no pagination params on the query
    // string. To work around that we add a large pageSize, which triggers a
    // different code path in the endpoint that returns the filtered result set.
    // The pagination params can be removed once the endpoint is fixed.
    // Batch search is applied; POST the data payload
    const data = {
      batch_list: batchFilter.batch_list,
    };
    const paginatedUrl = ensurePagination(url, {
      pageNumber: 0,
      pageSize: 1000000,
    });
    return startAsyncDataExport(
      dispatch,
      paginatedUrl,
      config,
      exportFileName,
      true,
      data,
    );
  };

  const resetExport = () => {
    return (dispatch) => {
      dispatch({ type: RESET_EXPORT });
    };
  };

  //ignoreSearchCategory should be true so that search category dropdown selection remains same
  //on removing all the text using backspace and on click of reset search bar icon
  const setSearchText = (
    searchText,
    ignoreValueChange = false,
    ignoreSearchCategory = false,
  ) => ({
    type: SET_SEARCH_TEXT,
    searchText,
    ignoreValueChange,
    ignoreSearchCategory,
  });

  const setSearchValue = (searchValue) => ({
    type: SET_SEARCH_VALUE,
    searchValue,
  });

  const clearSearchText = () => ({
    type: CLEAR_SEARCH_TEXT,
  });

  const setSearchCategory = (category) => ({
    type: SET_CATEGORY,
    category,
  });

  const setSearchCategoryForKey = (catKey) =>
    setSearchCategory(searchCategories.byKey(catKey));

  const setSearchFilter = (key, value, ignoreValueChange = false) => {
    return (dispatch) => {
      dispatch({ type: SET_FILTER, key, value, ignoreValueChange });
    };
  };

  const setPagination = (solutionId, page, pageSize, preventSearch = false) => {
    return (dispatch) => {
      dispatch({ type: SET_PAGINATION, page, pageSize });

      if (!preventSearch) {
        dispatch(searchEntities(solutionId, false));
      }
    };
  };

  const setSort = (
    solutionId,
    sortColumn,
    reverseSort = false,
    sortColumnOverride = null,
    preventSearch = false,
  ) => {
    return (dispatch) => {
      dispatch({ type: SET_SORT, sortColumn, reverseSort, sortColumnOverride });
      if (!preventSearch) {
        dispatch(searchEntities(solutionId, false));
      }
    };
  };

  const clearEntities = () => {
    return (dispatch) => {
      dispatch({ type: CLEAR_ENTITIES });
    };
  };

  const clearSearchFilter = (key, ignoreValueChange = false) => {
    return (dispatch) => {
      dispatch({ type: CLEAR_FILTER, key, ignoreValueChange });
    };
  };

  const clearSearchFilters = () => ({
    type: CLEAR_SEARCH_FILTERS,
  });

  const resetSearchBar = (ignoreValueChange = false) => ({
    type: RESET_SEARCH_BAR,
    ignoreValueChange,
  });

  const resetSearchAndFilters = (ignoreValueChange = false) => {
    return (dispatch) => {
      dispatch(resetSearchBar(ignoreValueChange));
      dispatch(clearSearchFilters());
    };
  };

  const selectSavedSearch = (item) => {
    return (dispatch) => {
      return Promise.all([
        dispatch({ type: SELECT_SAVED_SEARCH, searchItem: item }),
      ]).catch((err) => {
        throw new Error(err);
      });
    };
  };

  const clearSavedSearch = () => (dispatch) => {
    dispatch({ type: RESET_SAVED_SEARCH });
  };

  const resetSavedSearch = () => {
    // When reseting the search, we also need to remove data loaded
    // from saved search on search bar and filters
    return (dispatch) => {
      dispatch(clearSavedSearch());
      dispatch(resetSearchBar());
      dispatch(clearSearchFilters());
    };
  };

  const toggleShowFilters = (showFilters) => {
    return (dispatch) => {
      dispatch({
        type: SET_SHOW_ADVANCED_SEARCH,
        value: showFilters,
      });
    };
  };

  // Helpers
  const buildSearchQueryString = (
    searchText,
    searchValue,
    searchCategory,
    filterValues,
    paginate = true,
    page = 0,
    pageSize = defaultPageSize,
    sortColumn = null,
    reverseSort = false,
    sortColumnOverride = null, // In case the sortColumn is different from the column accessor/id
    defaultSortColumn = null,
    defaultReverseSort = false,
    searchAfterHistory = [],
  ) => {
    let queryString = "";
    let searchQs = "";
    let requestBody = { filter: {}, pagination: { size: pageSize } };

    if (
      searchCategory &&
      !_.isEmpty(searchText) &&
      searchCategory?.transformFilterValue
    ) {
      const values = searchCategory.transformFilterValue(
        searchCategory.queryKey,
        searchText,
      );
      if (values) {
        requestBody = {
          ...requestBody,
          filter: {
            ...requestBody.filter,
            [searchCategory.queryKey]: values,
          },
        };
      }
    } else {
      if (searchCategory && !_.isEmpty(searchText)) {
        searchQs = `${searchCategory.queryKey}=${encodeURIComponent(
          searchText,
        )}`;

        if (searchCategory.queryBuilder) {
          searchQs = searchCategory.queryBuilder(
            searchCategory.queryKey,
            searchText,
            searchValue,
          );
        }
      }
    }

    const filterQs = _.isEmpty(filterValues)
      ? ""
      : _.map(searchFiltersCategories, (filterDef) => {
          // checks if the filter has a value and then the query params will be added only if the filter has a value.
          const filterValueKeys = Object.keys(filterValues);
          const isFiltered = Array.isArray(filterDef.queryKey)
            ? filterValueKeys.some((r) => filterDef.queryKey.includes(r))
            : filterValueKeys.includes(filterDef.queryKey);
          if (isFiltered) {
            let isNthFilter = Array.isArray(filterDef.queryKey);
            if (isNthFilter) {
              const filterValue = {};
              filterDef.queryKey.forEach((key) => {
                filterValue[key] = filterValues[key];
              });

              if (filterDef.queryBuilder) {
                return filterDef.queryBuilder(filterDef.queryKey, filterValue);
              }
              if (filterDef.transformFilterValue) {
                const transformedValue = filterDef.transformFilterValue(
                  filterDef.queryKey,
                  filterValue,
                );

                if (transformedValue) {
                  requestBody = {
                    ...requestBody,
                    filter: {
                      ...requestBody.filter,
                      ...transformedValue,
                    },
                  };
                }
              }
            } else {
              const value = filterValues[filterDef.queryKey];
              if (filterDef.queryBuilder) {
                return filterDef.queryBuilder(filterDef.queryKey, value);
              }

              /**
               * This function modifies the `requestBody` object directly.
               * Based on the filter selection, it appends or removes the values
               */
              if (filterDef.transformFilterValue) {
                /**
                 * transformFilterValue: Generates a request body parameter with the structure queryKey: value,
                 * where the value can be an array, another JSON object, or a string.
                 * transformFilterValue: (queryKey, filterValue) => ({ queryKey: filterValue }),
                 */
                const values = filterDef.transformFilterValue(
                  filterDef.queryKey,
                  value,
                );
                if (values && filterDef.queryKey !== "batch") {
                  requestBody = {
                    ...requestBody,
                    filter: {
                      ...requestBody.filter,
                      ...(filterDef.queryKey !== "batch" && {
                        [filterDef.queryKey]: values,
                      }),
                    },
                  };
                }
              }
            }
          }
        }).join("");
    // FIN-9124: Deduplicating the exact same query params key value pairs because
    // when filters share the same URL param name AND value duplicated query strings get generated
    // When duplicated URL param name are present it will become paramName[0]=example&paramName[1]=example instead of
    // paramName=example&paramName=example when making the request
    // An example with the following query string: "status=Submitted%2CDenied&status=Submitted%2CDenied&pageNumber=0&pageSize=20"
    // when the above string gets split with & it turns into [status=Submitted%2CDenied”, status=Submitted%2CDenied”, “pageNumber=0”, "pageSize=20"]
    // when that array is put into a Set and back into an array again it’ll be just [status=Submitted%2CDenied”, “pageNumber=0”, "pageSize=20"]
    // only the exact duplicate URL param name and values pairs are ignored
    const filterQsDedup = [...new Set(filterQs.split("&"))].join("&");
    if (searchQs || filterQsDedup) {
      queryString += `${searchQs}${filterQsDedup}`;
    }
    if (paginate) {
      if (paginationType === PaginationType.OPEN_SEARCH) {
        if (searchAfterHistory.length > 0) {
          const searchAfter = searchAfterHistory[searchAfterHistory.length - 1];
          queryString += `&searchAfter=${searchAfter}`;
          // set pagination.searchAfter in request body
          requestBody = {
            ...requestBody,
            pagination: {
              ...requestBody.pagination,
              searchAfter: searchAfter,
            },
          };
        }
        queryString += `&size=${pageSize}`;

        // set pagination.size in request body
        requestBody = {
          ...requestBody,
          pagination: {
            ...requestBody.pagination,
            size: pageSize,
          },
        };
      } else {
        queryString += `&pageNumber=${page}&pageSize=${pageSize}`;
      }
    }
    if (sortColumn) {
      let finalSortColumn = sortColumnOverride
        ? sortColumnOverride
        : sortColumn;
      if (sortType === SortType.OPEN_SEARCH) {
        let ascOrDesc = reverseSort ? "desc" : "asc";
        queryString += `&sort=${finalSortColumn}:${ascOrDesc}`;
        requestBody = {
          ...requestBody,
          pagination: {
            ...requestBody.pagination,
            sort: [`${finalSortColumn}:${ascOrDesc.toUpperCase()}`],
          },
        };
      } else {
        queryString += `&sortColumn=${finalSortColumn}&reverseSort=${
          reverseSort ? 1 : 0
        }`;
      }
    }

    return { queryString, requestBody };
  };

  // Selectors
  const getEntities = (state) =>
    state[topic].data ? state[topic].data.data : [];
  const getSearchCategory = (state) => state[topic].searchCategory;
  const getIgnoreSearchCategory = (state) => state[topic].ignoreSearchCategory;
  const getSearchText = (state) => state[topic].searchText;
  const getSearchValue = (state) => state[topic].searchValue;
  const getSearchFilters = (state) => state[topic].searchFilters;
  const getHasSearchCriteriaChanged = (state) =>
    state[topic].hasSearchCriteriaChanged;
  const getAreThereFiltersSelected = (state) => {
    const searchFilters = getSearchFilters(state);
    const hasFilter = Object.keys(searchFilters).find((key) =>
      typeof searchFilters[key] === "object" ||
      typeof searchFilters[key] === "string"
        ? _.size(searchFilters[key]) > 0
        : !_.isNil(searchFilters[key]),
    );
    return !!hasFilter || false;
  };
  const getAreAllPrerequisiteFiltersSelected = (state) => {
    const auth = getAuthorization(state);

    // Only want to validate against filters that are shown for the current org (e.g. shown by feature, org type, etc.)
    const authorizedFilters = getAuthorizedFilters(
      searchFiltersCategories,
      auth,
    );

    const authorizedPrerequisiteFilters = authorizedFilters.filter(
      (filter) => filter.prerequisiteForSearchAndFilters,
    );

    const currentSearchFilters = getSearchFilters(state);

    // Reduce the list of prereq filters to a single boolean.
    // This checks each prereq filter to see if a value has been set for them.
    // - True: means all prereq filters are set.
    // - False: some prereq filter needs to be set.
    return authorizedPrerequisiteFilters.reduce((allHaveValues, filter) => {
      const value = currentSearchFilters[filter.queryKey];
      const hasValue = Array.isArray(value)
        ? value.length > 0
        : !_.isNil(value);

      return allHaveValues && hasValue;
    }, true);
  };
  const getSelectedSavedSearch = (state) => state[topic].savedSearch;
  const getTypeaheadOptionsMetadata = (state) =>
    state[topic].typeaheadOptionsMetadata;

  /**
   * In some cases, search results will not go to the server, it
   * will just filter data on the browser. That's the reason behind some
   * complexity here.
   *
   */
  const getSearchResults = createSelector(
    [getEntities, getSearchText, getSearchCategory, getSearchFilters],
    (searchResults, searchText, searchCategory, searchFilters) => {
      let filteredResults = searchResults;

      // Filter by search category (search bar) if there is some memory filter
      // to be applied (we check for this by checking existance of
      // applyFilter property)
      if (!_.isEmpty(searchCategory) && searchCategory.applyFilter) {
        filteredResults = filteredResults.map((item) => {
          if (searchCategory.applyFilter(item, searchText)) {
            return item;
          } else {
            return null;
          }
        });
        filteredResults = filteredResults.filter((x) => x);
      }

      // Filter results by search filters (filter section) if there is some
      // memory filter to be applied (we check for this by checking existance of
      // applyFilter property)
      if (!_.isEmpty(searchFilters)) {
        filteredResults = filteredResults.map((item) => {
          // Apply filters as an "and"
          for (const searchFilterKey of Object.getOwnPropertyNames(
            searchFilters,
          )) {
            const filter = searchFiltersCategories.byKey(searchFilterKey);

            if (filter === undefined || filter.applyFilter === undefined) {
              continue;
            }

            if (_.isEmpty(searchFilters[searchFilterKey])) {
              continue;
            }

            // Check if pass through the filter, if it does not pass, just
            // return null and ignore the item.
            if (
              filter.applyFilter(
                item,
                searchFilterKey,
                searchFilters[searchFilterKey],
              ) === false
            ) {
              return null;
            }
          }
          return item;
        });
        filteredResults = filteredResults.filter((x) => x);
      }
      return filteredResults;
    },
  );

  const getPage = (state) => state[topic].page;
  const getPageSize = (state) => state[topic].pageSize;

  // TODO: Remove fallbacks once all APIs consistently return meta.totalPages
  const getTotalPages = (state) => {
    if (paginationType === PaginationType.OPEN_SEARCH) {
      return Math.ceil(
        (state[topic]?.data?.meta?.totalCount || 0) / getPageSize(state),
      );
    } else {
      // Get total pages from state.
      // This can come from a valid or 404 response.
      // A 404 may returned `meta` in the body which lets use a more up to date page count.

      // If we have a loadingError, attempt to pull `meta.totalPages` from it
      if (state[topic].loadingError) {
        let totalPages =
          state[topic].loadingError?.response?.data?.meta?.totalPages;
        if (!_.isNil(totalPages)) {
          return totalPages;
        }
      }

      // Otherwise, try and pull totalPages from a good response.
      if (state[topic].data && state[topic].data.meta) {
        console.assert(
          state[topic].data.meta["total-pages"] === undefined,
          "total-pages property should not be returned. Please, fix it on backend.",
        );
        return state[topic].data.meta.totalPages;
      }
    }

    // Default to 0 if we don't have a totalPages from the API.
    return 0;
  };

  // TODO: Remove fallbacks once all APIs consistently return meta.totalCount
  const getTotalEntities = (state) => {
    if (state[topic].data) {
      console.assert(
        state[topic].data.recordsFiltered === undefined,
        "recordsFiltered property should not be returned. Please, fix it on backend.",
      );
      return state[topic].data.meta ? state[topic].data.meta.totalCount : 0;
    } else {
      return 0;
    }
  };
  const getIsLoading = (state) => state[topic].isLoading || false;
  const getIsLoadingError = (state) => state[topic].isLoadingError || false;
  const getLoadingError = (state) => state[topic].loadingError;
  const getSortColumn = (state) => state[topic].sortColumn;
  const getReverseSort = (state) => state[topic].reverseSort;
  const getSortColumnOverride = (state) => state[topic].sortColumnOverride;
  const getDefaultSortColumn = (state) => state[topic].defaultSortColumn;
  const getDefaultReverseSort = (state) => state[topic].defaultReverseSort;

  const getExportIdentifier = (state) => state[topic].exportIdentifier || null;
  const getExportName = (state) => state[topic].exportName || null;
  const getIsExporting = (state) => state[topic].isExporting || false;
  const getExportFailed = (state) => {
    const exportIdentifier = getExportIdentifier(state);
    if (exportIdentifier) {
      return ExportsState.selectors.getExport(exportIdentifier)(state)
        .exportFailed;
    }
    return state[topic].exportFailed || false;
  };
  const getSearchAfterMetaData = (state) => {
    return state[topic]?.searchAfterHistory || [];
  };

  const selectSearchQueryString = createSelector(
    [
      getSearchText,
      getSearchValue,
      getSearchCategory,
      getSearchFilters,
      (paginate) => true,
      getPage,
      getPageSize,
      getSortColumn,
      getReverseSort,
      getSortColumnOverride,
      getDefaultSortColumn,
      getDefaultReverseSort,
      getSearchAfterMetaData,
    ],
    buildSearchQueryString,
  );

  const getShowAdvancedSearch = (state) => state[topic].isAdvancedSearchVisible;

  // state

  const initialState = {
    searchText: "",
    searchValue: null,
    typeaheadOptionsMetadata: searchCategories,
    searchCategory: searchCategories[0],
    ignoreSearchCategory: false,
    searchFilters: {},
    hasSearchCriteriaChanged: false,
    isAdvancedSearchVisible: true,
    selectedSavedSearch: {},
    page: 0,
    pageSize: defaultPageSize,
    isExporting: false,
    exportFailed: false,
    exportIdentifier: "",
    exportName: "",
    sortColumn: config.defaultSort,
    reverseSort: config.reverseSort,
    defaultSortColumn: config.defaultSort,
    defaultReverseSort: config.reverseSort,
    searchAfterHistory: [],
  };

  const searchFiltersReducer = (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_SEARCH_TEXT:
        return {
          ...state,
          searchText: "",
          searchValue: null,
          hasSearchCriteriaChanged: false,
        };

      case RESET_SEARCH_BAR:
        return {
          ...state,
          searchText: initialState.searchText,
          searchValue: initialState.searchValue,
          searchCategory: state.ignoreSearchCategory
            ? state.searchCategory
            : initialState.searchCategory,
          hasSearchCriteriaChanged: action.ignoreValueChange
            ? state.ignoreValueChange
            : state.searchText !== initialState.searchText,
        };

      case SET_SEARCH_TEXT:
        return {
          ...state,
          searchText: action.searchText,
          searchValue: null,
          ignoreSearchCategory: action.ignoreSearchCategory,
          // Don't update this part of state if we want it ignored.
          hasSearchCriteriaChanged: action.ignoreValueChange
            ? state.hasSearchCriteriaChanged
            : true,
        };

      case SET_SEARCH_VALUE:
        return {
          ...state,
          searchValue: action.searchValue,
        };

      case SET_CATEGORY:
        return {
          ...state,
          searchCategory: action.category || initialState.searchCategory,
        };

      case SEARCH_CLICKED:
        return {
          ...state,
          hasSearchCriteriaChanged: false,
        };

      case RESET_ALL_SEARCH_BAR_STATES:
        return initialState;

      case SET_FILTER:
        return {
          ...state,
          // Don't update this part of state if we want it ignored.
          hasSearchCriteriaChanged: action.ignoreValueChange
            ? state.hasSearchCriteriaChanged
            : true,
          searchFilters: {
            ...state.searchFilters,
            [action.key]: action.value,
          },
        };

      case CLEAR_FILTER:
        return {
          ...state,
          // Don't update this part of state if we want it ignored.
          hasSearchCriteriaChanged: action.ignoreValueChange
            ? state.hasSearchCriteriaChanged
            : true,
          searchFilters: {
            ...state.searchFilters,
            [action.key]: null,
          },
        };

      case RESET_EXPORT:
        return {
          ...state,
          isExporting: initialState.isExporting,
          exportFailed: initialState.isExporting,
          exportIdentifier: initialState.exportIdentifier,
          exportName: initialState.exportName,
        };

      case CLEAR_SEARCH_FILTERS:
        const clearedFilters = {};

        searchFiltersCategories.forEach((filter) => {
          if (filter.onlyUserClearable) {
            clearedFilters[filter.queryKey] =
              state.searchFilters[filter.queryKey];
          }
        });

        return {
          ...state,
          searchFilters: clearedFilters,
        };

      case SELECT_SAVED_SEARCH:
        return {
          ...state,
          savedSearch: action.searchItem,
        };

      case RESET_SAVED_SEARCH:
        return {
          ...state,
          savedSearch: null,
        };

      case SET_SHOW_ADVANCED_SEARCH:
        return {
          ...state,
          isAdvancedSearchVisible: action.value,
        };

      case SET_PAGINATION:
        let newSearchHistory;
        if (action.page === 0) {
          newSearchHistory = [];
        } else if (action.page < state.page) {
          newSearchHistory = state.searchAfterHistory.slice(0, action.page);
        } else {
          newSearchHistory = [
            ...state.searchAfterHistory,
            state.data.meta.searchAfter,
          ];
        }
        return {
          ...state,
          searchAfterHistory: newSearchHistory,
          page: action.page,
          pageSize: action.pageSize,
        };

      case SET_SORT:
        return {
          ...state,
          sortColumn: action.sortColumn ?? config.defaultSort,
          reverseSort: action.reverseSort ?? config.reverseSort,
          sortColumnOverride: action.sortColumnOverride,
        };

      case CLEAR_ENTITIES:
        if (state.data && state.data.data) {
          return {
            ...state,
            data: {
              ...state.data,
              data: [],
              meta: null,
            },
          };
        }
        return state;
      case EXPORT_IDENTIFIER_RECEIVED:
        return {
          ...state,
          exportIdentifier: action.identifier,
          exportName: action.exportName,
          isExporting: false /*This is counter intuative but the isExporting loader is controlled with this var  */,
        };
      case EXPORT_REQUEST:
        return {
          ...state,
          isExporting: true,
          exportFailed: false,
        };
      case EXPORT_SEARCH_FAILED:
        return {
          ...state,
          isExporting: false,
          exportIdentifier: "",
          exportName: "",
          exportFailed: true,
        };
      case CLEAR_EXPORT_ERRORS:
        return {
          ...state,
          exportFailed: false,
        };
      default:
        return state;
    }
  };

  const getCanUserSearch = (state) => {
    const areAllPrerequisiteFiltersSelected =
      getAreAllPrerequisiteFiltersSelected(state);
    const allFiltersHaveValidValues = searchFiltersCategories?.reduce(
      (allHaveValues, filterDef) => {
        if (filterDef?.isValueValid) {
          return (
            allHaveValues &&
            filterDef.isValueValid(getSearchFilters(state)[filterDef.queryKey])
          );
        }

        return allHaveValues;
      },
      true,
    );

    return areAllPrerequisiteFiltersSelected && allFiltersHaveValidValues;
  };

  return {
    mountPoint: topic,
    actionCreators: {
      setSearchText,
      setSearchValue,
      clearSearchText,
      setSearchCategory,
      setSearchCategoryForKey,
      setSearchFilter,
      clearSearchFilter,
      clearSearchFilters,
      resetSearchBar,
      resetSearchAndFilters,
      selectSavedSearch,
      clearSavedSearch,
      resetSavedSearch,
      searchEntities,
      clearEntities,
      exportEntities,
      clearExportErrors,
      resetExport,
      toggleShowFilters,
      setPagination,
      setSort,
    },
    selectors: {
      getSearchText,
      getSearchValue,
      getTypeaheadOptionsMetadata,
      getAreThereFiltersSelected,
      getSearchFilters,
      getHasSearchCriteriaChanged,
      getAreAllPrerequisiteFiltersSelected,
      getCanUserSearch,
      getSearchCategory,
      getIgnoreSearchCategory,
      getSelectedSavedSearch,
      getEntities,
      getSearchResults,
      getShowAdvancedSearch,
      getIsLoading,
      getIsLoadingError,
      getLoadingError,
      getPage,
      getPageSize,
      getTotalPages,
      getTotalEntities,
      getSortColumn,
      getReverseSort,
      getSortColumnOverride,
      getDefaultSortColumn,
      getDefaultReverseSort,
      getExportIdentifier,
      getExportName,
      getIsExporting,
      getExportFailed,
      getSearchAfterMetaData,
      selectSearchQueryString,
    },
    reducer: chainReducers([searchFiltersReducer, duck.reducer, ...reducers]),
  };
};

export default buildSearchBarState;
