/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { VictoryLabel, VictoryLegend, VictoryPie } from "victory";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import Colors from "styles/colors";

const defaultTitleComponentStyle = [
  {
    fontSize: 12,
    fill: Colors.text.TOTALS_LABEL_GRAY,
  },
  {
    fontSize: 14,
    fontWeight: 600,
    fill: Colors.text.DARK_GRAY,
  },
  {
    fontSize: 2,
    fontWeight: 600,
    fill: Colors.text.WHITE,
  },
];

const defaultLabelStyle = {
  labels: { fontSize: 12 },
};

const defaultVictoryLegendWidthHeight = {
  width: 140,
  height: 140,
};

export const PieChartWithLegends = ({
  widgetData,
  filteredWidgetData = [],
  title,
  subTitle,
  x,
  y,
  onClickHandler,
  queryKey,
  alignWidgetToLeft = false,
  innerRadius = 40,
  titleStyles = defaultTitleComponentStyle,
  labelStyles = defaultLabelStyle,
  widgetLabelsWidthHeight = defaultVictoryLegendWidthHeight,
  labelComponent,
}) => {
  const colorScale = widgetData.map((item) => item.fill);
  return (
    <PanelGroup>
      <PanelGroup.Content>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: `${alignWidgetToLeft ? "row-reverse" : "row"} `,
          }}
        >
          <VictoryLegend
            width={widgetLabelsWidthHeight.width}
            height={widgetLabelsWidthHeight.height}
            rowGutter={{ top: 0, bottom: 0 }}
            symbolSpacer={7}
            title={[subTitle, title, "_"]}
            data={widgetData}
            colorScale={colorScale}
            titleComponent={
              <VictoryLabel style={titleStyles} lineHeight={[2, 1, 2]} />
            }
            style={labelStyles}
          />
          <VictoryPie
            data={
              filteredWidgetData.length > 0 ? filteredWidgetData : widgetData
            }
            width={140}
            height={140}
            innerRadius={innerRadius}
            colorScale={colorScale}
            x={x}
            y={y}
            padAngle={0}
            padding={{ top: 10, right: 10, left: 10 }}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: () => ({
                    target: "data",
                    mutation: (props) => {
                      if (onClickHandler) {
                        onClickHandler(queryKey, props.datum.searchOption);
                      }
                    },
                  }),
                },
              },
            ]}
            style={{
              data: { cursor: "pointer" },
            }}
            labelComponent={labelComponent}
          />
        </div>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

PieChartWithLegends.propTypes = {
  widgetData: PropTypes.array,
  filteredWidgetData: PropTypes.array,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  x: PropTypes.string,
  y: PropTypes.string,
  onClickHandler: PropTypes.func,
  queryKey: PropTypes.string,
  datum: PropTypes.object,
  alignWidgetToLeft: PropTypes.bool,
  innerRadius: PropTypes.number,
  titleStyles: PropTypes.object,
  labelStyles: PropTypes.object,
  widgetLabelsWidthHeight: PropTypes.object,
  labelComponent: PropTypes.element,
};
