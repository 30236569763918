import _ from "lodash";
import { z } from "zod";

const emailSchema = z.string().email();

export const isValidEmail = (address) => {
  return emailSchema.safeParse(address).success;
};

export const validateEmailsAndDelimiter = (emailAddresses) => {
  let emailString = emailAddresses.trim().replace(/ /g, " ");
  const invalidErrors = {
    isEmailValid: false,
    invalidDelimiterFormat: true,
  };

  if (emailString === "") {
    return invalidErrors;
  }

  let delimiter = "";
  if (emailString.includes(";")) {
    delimiter = ";";
  } else if (emailString.includes(",")) {
    delimiter = ",";
  } else {
    invalidErrors.isEmailValid = isValidEmail(emailString);
    return invalidErrors;
  }

  if (emailString.includes(";") && emailString.includes(",")) {
    invalidErrors.invalidDelimiterFormat = false;
    return invalidErrors;
  }

  const foundEmails = [];
  const emails = emailString
    .split(delimiter)
    .map((email) => email.trim()) //trim email
    .filter((email) => !_.isEmpty(email)); //filter empty values after adding DELIMITER

  emails.forEach((email) => {
    if (isValidEmail(email) === true) {
      foundEmails.push({ email: email, valid: true });
    }
  });

  invalidErrors.isEmailValid = foundEmails.length === emails.length;
  return invalidErrors;
};
