/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Link from "redux-first-router-link";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import {
  PhoneNumberInput,
  isPossiblePhoneNumber,
} from "components/molecules/PhoneInput.molecule";
import AuthenticationUtils from "modules/auth/authentication";
import { ProfileState } from "pages/profile/redux/ProfileState";
import Colors from "styles/colors";
import { isValidEmail } from "utils/validation-utils";

const MODES = {
  SAVE: "save",
  UPDATE: "update",
};

export const AlertMeModal = ({
  show,
  hide,
  modalSize = "md",
  headerText = null,
  bodyText = null,
  emailText = null,
  textMessageText = null,
  platformNotificationText = null,
  subscribeeDetails,
  displayedId,
  isSubscriptionLoading = false,
  subscriptionDetails,
  subscriptionRequestError = false,
  updateSubscription,
  subscribe,
  unsubscribe,
  context,
  isSubscriptionUpdating = false,
  subscriptionUpdateSuccess = false,
  subscriptionUpdateError = false,
}) => {
  const { t } = useTranslation("fv-vin-details");

  // check for Alert me preferences
  // pre-populate if preferences are present
  const userPreferences = useSelector(
    ProfileState.selectors.getUserPreferences,
  );
  const preferredEmail = userPreferences?.defaultCommunication?.email ?? "";
  const preferredMobileNumber =
    userPreferences?.defaultCommunication?.phone ?? "";

  const [mode, setMode] = useState(null);
  const [subscriptionLocal, setSubscriptionLocal] = useState(null);
  const isOptIn =
    subscribeeDetails?.configurations?.alertVinAction === "opt-in";

  //In order to dismiss the success or error alerts
  const [showAlert, setShowAlert] = useState(false);
  const [showSubscriptionRequestAlert, setShowSubscriptionRequestAlert] =
    useState(false);

  const requiresApproval = (isOptIn, subscription) =>
    isOptIn && subscription?.requires_approval;
  const emailIsNotApproved = (subscription) =>
    subscription?.enable_email && !subscription?.is_email_approved;
  const smsIsNotApproved = (subscription) =>
    subscription?.enable_sms && !subscription?.is_sms_approved;

  const isSubscriptionPending = () => {
    return (
      requiresApproval(isOptIn, subscriptionDetails) &&
      (emailIsNotApproved(subscriptionDetails) ||
        smsIsNotApproved(subscriptionDetails))
    );
  };

  // When the modal opens, sync details to local state.
  useEffect(() => {
    if (show) {
      const mode = subscriptionDetails ? MODES.UPDATE : MODES.SAVE;
      setMode(mode);

      const recipientEmail = subscriptionDetails?.recipient_email
        ? subscriptionDetails.recipient_email
        : preferredEmail
        ? preferredEmail
        : AuthenticationUtils.userEmail;

      const mobileNumber = subscriptionDetails?.mobile_number
        ? subscriptionDetails.mobile_number
        : preferredMobileNumber;

      // Save - create a new subscription.
      // Update - modifying an existing subscription.
      if (mode === MODES.SAVE) {
        setSubscriptionLocal({
          enable_email: false,
          enable_sms: false,
          enable_platform: false,
          recipient_email: recipientEmail,
          mobile_number: mobileNumber,
        });
      } else if (mode === MODES.UPDATE) {
        setSubscriptionLocal({
          ...subscriptionDetails,
          recipient_email: recipientEmail,
          mobile_number: mobileNumber,
        });
      }
    }
  }, [show, subscriptionDetails, preferredEmail, preferredMobileNumber]);

  //In order to dismiss the error alert on subscription Request Failure
  useEffect(() => {
    if (subscriptionRequestError) {
      setShowSubscriptionRequestAlert(true);
    } else {
      setShowSubscriptionRequestAlert(false);
    }
  }, [subscriptionRequestError]);

  const hasVinSubscriptionChanged = () => {
    // when no subscription is set at all any checkbox will do
    if (!subscriptionDetails) {
      return (
        subscriptionLocal?.enable_email ||
        subscriptionLocal?.enable_sms ||
        subscriptionLocal?.enable_platform
      );
    }
    // when subscription is set, compare data for changes
    return (
      subscriptionLocal?.enable_email !== subscriptionDetails.enable_email ||
      (subscriptionLocal?.enable_email &&
        subscriptionLocal?.recipient_email !==
          subscriptionDetails.recipient_email) ||
      subscriptionLocal?.enable_sms !== subscriptionDetails.enable_sms ||
      (subscriptionLocal?.enable_sms &&
        subscriptionLocal?.mobile_number !==
          subscriptionDetails.mobile_number) ||
      subscriptionLocal?.enable_platform !== subscriptionDetails.enable_platform
    );
  };

  const isEmailSubscriptionInvalid =
    subscriptionLocal?.enable_email &&
    !(
      subscriptionLocal?.recipient_email &&
      isValidEmail(subscriptionLocal.recipient_email)
    );

  const isMobileSubscriptionInvalid =
    subscriptionLocal?.enable_sms &&
    !(
      subscriptionLocal?.mobile_number &&
      isPossiblePhoneNumber(subscriptionLocal.mobile_number)
    );

  const onSave = () => {
    const onRequestFinished = () => setShowAlert(true);

    if (isOptIn) {
      subscriptionLocal.requires_approval = true;
    }

    if (
      mode === MODES.UPDATE &&
      !subscriptionLocal.enable_email &&
      !subscriptionLocal.enable_sms &&
      !subscriptionLocal.enable_platform
    ) {
      //unsubscribe
      unsubscribe(subscribeeDetails, subscriptionLocal).then(onRequestFinished);
    } else {
      if (mode === MODES.SAVE) {
        if (context) {
          subscriptionLocal.context = context;
        }
        // save subscription
        subscribe(subscribeeDetails, subscriptionLocal).then(onRequestFinished);
      } else {
        if (context) {
          subscriptionLocal.context = context;
        }
        // update subscription
        updateSubscription(subscribeeDetails, subscriptionLocal).then(
          onRequestFinished,
        );
      }
    }
  };

  const onModalClose = () => {
    hide();
    setShowAlert(false);
    setShowSubscriptionRequestAlert(false);
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={() => {
        onModalClose();
      }}
      size={modalSize}
    >
      <Modal.Header
        // Prevent closing modal while updating
        closeButton={!isSubscriptionUpdating}
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={
          <div data-qa="title-modal">
            {t("fv-vin-details:Alert Me")}
            {displayedId ? ": " : null}
            {displayedId}
            {isSubscriptionLoading ? (
              <Icon src={faSpinner} spin style={{ marginLeft: ".5em" }} />
            ) : null}
          </div>
        }
      />
      <Modal.Body>
        <div
          css={{
            pointerEvents:
              isSubscriptionLoading || subscriptionRequestError
                ? "none"
                : "default",
            opacity:
              isSubscriptionLoading || subscriptionRequestError ? 0.5 : 1,
          }}
        >
          {bodyText ?? (
            <div>
              {isSubscriptionPending()
                ? t(
                    "fv-vin-details:By selecting this option, you will receive all major event updates and agree to receive this communication by email or text message. Please check your inbox to confirm the subscription.",
                  )
                : t(
                    "fv-vin-details:By selecting this option, you will receive all major event updates and agree to receive this communication by email or text message.",
                    {
                      service: t("fv-vin-details:event"),
                      // At this time, any AlertMe modal displayed "event" regardless of product.
                      // Leaving commented code so we can look into it later.
                      //
                      // service: id
                      //   ? t("fv-vin-details:event")
                      //   : t("fv-vin-details:milestone")
                    },
                  )}{" "}
              {/*https://react.i18next.com/latest/trans-component*/}
              <Trans t={t}>
                Set a default recipient email and phone number in the{" "}
                <Link to={{ type: "PROFILE" }} target="_blank">
                  My Profile
                </Link>{" "}
                page.
              </Trans>
            </div>
          )}

          <div
            css={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "1em",
              padding: "1em",
            }}
          >
            {/* Email */}
            <div>
              <Form.Check
                label={true}
                type="checkbox"
                checked={subscriptionLocal?.enable_email ?? false}
                onChange={() => {
                  const { enable_email, recipient_email } = subscriptionLocal;
                  setSubscriptionLocal({
                    ...subscriptionLocal,
                    enable_email: !enable_email,
                    recipient_email: isEmailSubscriptionInvalid
                      ? ""
                      : recipient_email,
                  });
                }}
                id="checkbox-vin-subscription-email"
                data-qa="checkbox-vin-subscription-email"
                css={{ display: "inline-block" }}
              />
              <Form.Label htmlFor="checkbox-vin-subscription-email">
                <Text size={FontSize.size18}>
                  {emailText ?? t("fv-vin-details:Email")}
                </Text>
              </Form.Label>
            </div>
            <Form.Group css={{ marginBottom: 0 }}>
              <Form.Control
                disabled={!subscriptionLocal?.enable_email ?? true}
                type="text"
                value={
                  subscriptionLocal?.enable_email
                    ? subscriptionLocal?.recipient_email
                    : ""
                }
                placeholder={t("fv-vin-details:Email")}
                onChange={(e) => {
                  setSubscriptionLocal({
                    ...subscriptionLocal,
                    recipient_email: e.target.value,
                  });
                }}
                data-qa="input-subscription-email"
              />
            </Form.Group>

            {/* Text Message */}
            <div>
              <Form.Check
                label={true}
                type="checkbox"
                checked={
                  subscriptionLocal ? subscriptionLocal.enable_sms : false
                }
                onChange={() => {
                  const { enable_sms, mobile_number } = subscriptionLocal;
                  setSubscriptionLocal({
                    ...subscriptionLocal,
                    enable_sms: !enable_sms,
                    mobile_number: isMobileSubscriptionInvalid
                      ? ""
                      : mobile_number,
                  });
                }}
                id="checkbox-vin-subscription-text-msg"
                data-qa="checkbox-vin-subscription-text-msg"
                css={{ display: "inline-block" }}
              />
              <Form.Label htmlFor="checkbox-vin-subscription-text-msg">
                <Text size={FontSize.size18}>
                  {textMessageText ?? t("fv-vin-details:Text Message")}
                </Text>
              </Form.Label>
            </div>

            <Form.Group css={{ marginBottom: 0 }}>
              <PhoneNumberInput
                placeholder={t("fv-vin-details:Mobile Number")}
                value={
                  subscriptionLocal?.enable_sms
                    ? subscriptionLocal?.mobile_number
                    : ""
                }
                onChange={(value) => {
                  setSubscriptionLocal({
                    ...subscriptionLocal,
                    mobile_number: value,
                  });
                }}
                disabled={!subscriptionLocal?.enable_sms ?? true}
                data-qa="input-subscription-text-msg"
                aria-describedby="mobile-number-help-text"
              />
              <Form.Text
                id="mobile-number-help-text"
                muted
                css={{ display: "block", fontSize: "80%" }}
              >
                {t(
                  "components:Choose country code from the drop-down or type the country code along with your mobile phone number.",
                )}
              </Form.Text>
            </Form.Group>

            {/* Platform */}
            <div css={{ gridColumn: "1 / -1" }}>
              <Form.Check
                label={true}
                type="checkbox"
                checked={
                  subscriptionLocal ? subscriptionLocal.enable_platform : false
                }
                onChange={() => {
                  setSubscriptionLocal({
                    ...subscriptionLocal,
                    enable_platform: !subscriptionLocal.enable_platform,
                  });
                }}
                id="checkbox-vin-subscription-notification"
                data-qa="checkbox-vin-subscription-notification"
                css={{ display: "inline-block" }}
              />
              <Form.Label
                htmlFor="checkbox-vin-subscription-notification"
                css={{ marginBottom: 0 }}
              >
                <Text size={FontSize.size18}>
                  {platformNotificationText ??
                    t("fv-vin-details:FreightVerify Platform Notification")}
                </Text>
              </Form.Label>
            </div>
          </div>
        </div>

        <Alert
          show={showSubscriptionRequestAlert && subscriptionRequestError}
          variant={AlertVariant.Danger}
          onClose={() => setShowSubscriptionRequestAlert(false)}
          dismissible
          css={{ margin: 0 }}
        >
          <Text>
            {t(
              "fv-vin-details:There was an error when fetching your alert preferences. Please try again later and contact FreightVerify.",
            )}
          </Text>
        </Alert>

        <Alert
          show={showAlert && subscriptionUpdateSuccess}
          variant={AlertVariant.Success}
          onClose={() => setShowAlert(false)}
          dismissible
          css={{ margin: 0 }}
        >
          <Text>
            {t("fv-vin-details:Your alert preferences has been updated.")}
          </Text>
        </Alert>

        <Alert
          show={showAlert && subscriptionUpdateError}
          variant={AlertVariant.Danger}
          onClose={() => setShowAlert(false)}
          dismissible
          css={{ margin: 0 }}
        >
          <Text>
            {t(
              "fv-vin-details:There was an error when updating your alert preferences. Please try again later and contact FreightVerify.",
            )}
          </Text>
        </Alert>
      </Modal.Body>
      <Modal.Footer css={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <Button
          variant="outline-secondary"
          css={{
            marginRight: "0.5em",
          }}
          disabled={isSubscriptionUpdating}
          onClick={() => {
            onModalClose();
          }}
          data-qa="button-cancel-vin-subscription-modal"
        >
          {t("fv-vin-details:Cancel")}
        </Button>
        <Button
          disabled={
            !hasVinSubscriptionChanged() ||
            isSubscriptionUpdating ||
            isEmailSubscriptionInvalid ||
            isMobileSubscriptionInvalid
          }
          variant="success"
          onClick={() => onSave()}
          data-qa="button-save-vin-subscription-modal"
        >
          {isSubscriptionUpdating ? (
            <Icon src={faSpinner} spin />
          ) : (
            t("fv-vin-details:Save")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AlertMeModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  modalSize: PropTypes.string,
  headerText: PropTypes.string,
  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  emailText: PropTypes.string,
  textMessageText: PropTypes.string,
  platformNotificationText: PropTypes.string,
  /** The object of the thing being subscribed to. */
  subscribeeDetails: PropTypes.object,
  /** The ID displayed in the modal tile. */
  displayedId: PropTypes.string,
  isSubscriptionLoading: PropTypes.bool,
  subscriptionDetails: PropTypes.object,
  subscriptionRequestError: PropTypes.bool,
  updateSubscription: PropTypes.func,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
  context: PropTypes.object,
  isSubscriptionUpdating: PropTypes.bool,
  subscriptionUpdateSuccess: PropTypes.bool,
  subscriptionUpdateError: PropTypes.bool,
};
