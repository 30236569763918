/**
 * Maps the keys of an input object to new keys based on a provided key mapping object,
 * handles nested objects and arrays, and filters out empty or null values.
 *
 * - Nested objects are recursively processed using the corresponding nested key mappings.
 * - Arrays are joined into comma-separated strings.
 * - Objects with a `values` property are processed by joining the `values` array into a string.
 * - Primitive values are directly mapped and included in the result.
 *
 * @param {Object} inputObject - The object whose keys need to be mapped and filtered.
 * @param {Object} keyMapper - An object defining the mapping of keys.
 *                             The keys in this object represent the original keys,
 *                             and the values represent the new keys or nested mappings.
 * @returns {Object} - A new object with the keys mapped, values joined as strings,
 *                     and empty or null values excluded.
 *
 * @example
 * const inputObject = {
 *   origin: { values: ["123"] },
 *   destination: { values: ["456"] },
 *   currentLocation: {
 *     positionTypes: ["AtLocation"],
 *     atLocation: { values: ["566900000-2"] },
 *   },
 * };
 * const keyMapper = {
 *   origin: "originId",
 *   destination: "destinationId",
 *   currentLocation: {
 *     positionTypes: "currentPositionTypes",
 *     atLocation: "currentPositionCodes",
 *   },
 * };
 * const result = mapAndFilterKeys(inputObject, keyMapper);
 * // result: {
 * //   originId: "123",
 * //   destinationId: "456",
 * //   currentPositionTypes: "AtLocation",
 * //   currentPositionCodes: "566900000-2",
 * // }
 *
 * @example
 * const inputObject = {
 *   filter: {
 *     orderType: { values: ["Customer"] },
 *   },
 *   pagination: {
 *     size: 20,
 *   },
 * };
 * const keyMapper = {
 *   filter: {
 *     orderType: "orderTypeKey",
 *   },
 *   pagination: {
 *     size: "pageSize",
 *   },
 * };
 * const result = mapAndFilterKeys(inputObject.filter, keyMapper.filter);
 * // result: {
 * //   orderTypeKey: "Customer",
 * // }
 */
export const mapAndFilterKeys = (inputObject, keyMapper) => {
  const result = {};

  for (const key in inputObject) {
    const value = inputObject[key];
    const mappedKey = keyMapper[key] || key;

    if (value && typeof value === "object" && !Array.isArray(value)) {
      // Handle nested objects
      if (value.values) {
        result[mappedKey] = value.values.join(","); // Join `values` array
      } else {
        const nestedResult = mapAndFilterKeys(value, keyMapper[key] || {});
        Object.assign(result, nestedResult); // Merge nested results
      }
    } else if (Array.isArray(value)) {
      // Handle arrays
      result[mappedKey] = value.join(",");
    } else if (value) {
      // Handle primitive values
      result[mappedKey] = value;
    }
  }

  return result;
};

/**
 * Maps the `batch_type` of a batch object to a new value based on a provided mapping object.
 * If no mapping is found, it defaults to the original `batch_type`.
 *
 * @param {Object} batchObject - The batch object containing the `batch_type` to be mapped.
 * @param {Object} batchTypeMapper - An object defining the mapping of batch types.
 *                                    The keys represent the original batch types,
 *                                    and the values represent the mapped batch types.
 * @returns {Object} - A new object with the mapped `batchType`.
 *
 * @example
 * const batchObject = { batch_type: "typeA" };
 * const batchTypeMapper = { typeA: "MappedTypeA", typeB: "MappedTypeB" };
 * const result = batchMapper(batchObject, batchTypeMapper);
 * // result: { batchType: "MappedTypeA" }
 *
 * @example
 * const batchObject = { batch_type: "typeC" };
 * const batchTypeMapper = { typeA: "MappedTypeA", typeB: "MappedTypeB" };
 * const result = batchMapper(batchObject, batchTypeMapper);
 * // result: { batchType: "typeC" } // Defaults to the original batch_type
 */
export const batchMapper = (batchObject, batchTypeMapper) => {
  const mappedBatchType =
    batchTypeMapper[batchObject.batch_type] || batchObject.batch_type;
  return {
    batchType: mappedBatchType,
  };
};

/**
 * Filters out keys with `null` or empty string (`""`) values from an object.
 *
 * @param {Object} obj - The object to be filtered.
 * @returns {Object} - A new object with only the keys that have non-null and non-empty values.
 *
 * @example
 * const input = {
 *   key1: "value1",
 *   key2: null,
 *   key3: "",
 *   key4: "value4",
 * };
 * const result = filterEmptyValues(input);
 * // result: {
 * //   key1: "value1",
 * //   key4: "value4",
 * // }
 */
export const filterEmptyValues = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== null && value !== ""),
  );
};

/**
 * A mapping object that defines how specific keys should be transformed.
 * The keys represent the original keys, and the values represent the new keys.
 *
 * @type {Object}
 *
 * @example
 * keyMapper.origin; // "originId"
 * keyMapper.destination; // "destinationId"
 */
export const keyMapper = {
  // Advanced Search
  origin: "originId",
  destination: "destinationId",
  carrier: "carrier",
  productTypeExact: "description",
  vinStatus: "lifeCycleState",
  vinExceptions: "exception",
  lastMilestoneExact: "lastMilestone",
  pickupDate: "pickupDate",
  deliveryDate: "deliveryDate",
  orderType: "ref:OrderType:0",
  soldTo: "ref:soldToDealer:0",
  finCode: "ref:FinCode:0",
  endUserFinCode: "ref:EndUserFinCode:0",
  partner: "partner",
  shipmentStatus: "shipmentStatuses",
  itssIdExact: "groupCategory:ITSS",
  spotBuyAuth: "groupCategory:SB",
  originCountry: "originCountry",
  originRegion: "origin_region",
  destinationCountry: "destinationCountry",
  destinationRegion: "destination_region",
  shippability: "shippability",
  dealerRegion: "dealerRegion",
  dealerZone: "dealerZone",
  dealerDistrict: "dealerDistrict",
  completedDate: "completedDate",
  currentLocation: {
    positionTypes: "currentPositionTypes",
    atLocation: "currentPositionCodes",
  },
  routeIdExact: "routeNumber:0",

  // Category Search
  vinNumber: "entityId",
  shipmentId: "shipmentId",
  orderNumber: "ref:OrderNumber:1",
  productType: "descriptionSearch",
  location: "location",
  lastMilestone: "lastMilestone:contains",
  itssId: "categorySearch",
  routeId: "routeNumber:1",

  // Batch Search
  vinNumberBatch: "vin",
  productTypeBatch: "description",
  last8OfVin: "last_8_of_vin",
  orderNumberBatch: "OrderNumber",
};
